import React from "react";
// import InnerCommunity, { InnerBtn, InnerContact, InnerHeading } from "./InnerSection";
import Inner4 from "../innerform/img/innerimg4.png";
import InnerCommunity, { InnerBtn, InnerContact, InnerHeading } from "../innerform/InnerSection";

const GrowingPage = () => {
    return (
        <>
            <div class="container">

                <div class="col-md-12">
                    <div class="row">
                     
                        <div class="col-md-5">
                            <div class="">
                                <InnerHeading Heading="Growing Your Page" />
                            </div>
                            <div class="mt-5 ps-lg-4">
                                <InnerContact Content1="#Content" Content2="#Content" Content3="#Content" Content4="#Content" Content5="#Content" Content6="#Content" Content7="#Content" Content8="#Content" />

                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="mt-5">
                                <InnerCommunity Img={Inner4} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="container mb-5">
                <div class="row float-start">
                    <div class="col-md-12 col-12 col-sm-12">
                        <InnerBtn Text= "BACK"/>
                    </div>
                </div>
            </div>
        </>
    );
}
export default GrowingPage;