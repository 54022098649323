import "../slider/OrbitSlider.css"
import orbit from "../slider/img/orbit.png";
import orbit1 from "../slider/img/orbit1.png";
import orbit2 from "../slider/img/orbit2.png";
import orbit3 from "../slider/img/orbit3.png";
import orbit4 from "../slider/img/orbit4.png";
import orbit5 from "../slider/img/orbit5.png";
import orbit6 from "../slider/img/orbit6.png";
import orbit7 from "../slider/img/orbit7.png";
import orbit8 from "../slider/img/orbit8.png";
import orbit9 from "../slider/img/orbit9.png";

const OrbitSlider = () => {
    return (
        <>
            <div id="circle-orbit-container">

                {/* <!-- Circles closest to the central point -->
                <!-- <div id="inner-orbit">
                    <div class="inner-orbit-cirlces"></div>
                </div> --> */}

                {/* <!-- Circles second closest to the central point --> */}
                <div id="middle-orbit">
                    <div class="middle-orbit-cirlces"> <img src={orbit} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="middle-orbit-cirlces1"><img src={orbit1} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="middle-orbit-cirlces2"><img src={orbit2} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="middle-orbit-cirlces3"><img src={orbit3} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="middle-orbit-cirlces4"><img src={orbit4} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="middle-orbit-cirlces5"> <img src={orbit1} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="middle-orbit-cirlces6"><img src={orbit3} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="middle-orbit-cirlces7"><img src={orbit2} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                </div>

                {/* <!-- Circles furthest away to the central point --> */}
                <div id="outer-orbit">
                    <div class="outer-orbit-cirlces"> <img src={orbit5} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="outer-orbit-cirlces1"></div>
                    <div class="outer-orbit-cirlces2"><img src={orbit6} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="outer-orbit-cirlces3"></div>
                    <div class="outer-orbit-cirlces4"><img src={orbit7} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="outer-orbit-cirlces9"></div>
                    <div class="outer-orbit-cirlces5"><img src={orbit9} style={{ width: "30px", height: "30px", rotate: "-20deg" }} alt="..." /></div>
                    <div class="outer-orbit-cirlces7"></div>
                    <div class="outer-orbit-cirlces6"><img src={orbit8} style={{ width: "20px", height: "20px", rotate: "-20deg" }} alt="..." /></div>
                    <div class="outer-orbit-cirlces8"> <img src={orbit5} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                </div>

            </div>
        </>
    );
}

export default OrbitSlider;