import React from "react";

const CarouselSlider = (props) => {
    return (
        <>
            {/* <div class="carousel-inner">

                <div class="carousel-item active">
                    <div class="row">
                        <div class="col-md-6 col-6"> */}

                            <img src={props.Img1} alt="Los Angeles" style={{width:"50%", padding:"5px"}}/>
                            <img src={props.Img2} alt="Los Angeles" style={{width:"50%", padding:"5px"}}/>
                        {/* </div>
                        <div class="col-md-6 col-6 ">

                            <img src={props.Img2}alt="Los Angeles" class="d-block" style={{width: "100%"}} />
                        </div>
                    </div>
                </div>
            </div> */}

        </>

    );
};
export default CarouselSlider;

