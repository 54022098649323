import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import Logo from './assets/img/logo.png';
import lang from './assets/img/lang.png';
import Lang from "./settingpage/lang";
import GoogleTranslate from "./settingpage/lang";
import { Icon } from '@iconify/react';


const IndexNav = () => {
    return (
        <>
            <div className="my_menu" style={{}}>
                <nav className="navbar navbar-expand-lg fixed-top">
                    <div className="container layout_container">
                        <NavLink className="navbar-brand" to="/Home">
                            <img src={Logo} style={{ width: "140px" }} alt="" />
                        </NavLink>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                            <ul className="navbar-nav ">
{/*                                
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/contact">Contact</NavLink>
                                </li> */}

                                <li className="nav-item ps-4">
                                    {/* <NavLink to="" className="nav-link d-flex justify-content-between p-0 m-0">
                                        <i class="fa fa-home text-dark pt-1" style={{fontSize:"25px"}} aria-hidden="true"></i>
                                    </NavLink> */}
                                        <NavLink to="/login" >
                                    <Icon icon="bxs:home" width="30" color="black" /> 
                                         </NavLink>
                                </li>

                            </ul>

                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default IndexNav;