import React from "react";

import MainMenu from "../MainMenu";

import "../../settingpage/components/assets/css/style.css"
import SavedImg from "../components/assets/img/posts.svg"
import SavedImg1 from "../components/assets/img/postvideo.svg"


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Saved = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>

                    </div>

                    <div className="col-md-9 col-12">
                        <h2 className="text-center" style={{ marginTop: "80px" }} >Saved</h2>
                        <div class="border p-3" style={{ marginBottom: "80px" }}>

                            {/* <button className="btn btn-primary float-end me-2">Create Group</button> */}
                            <Tabs defaultIndex={0} className="mt-2">
                                <TabList className="d-flex justify-content-between w-75 mx-auto">
                                    <Tab className="btn border-bottom-0" >Images</Tab>
                                    <Tab className="btn border-bottom-0" >Video</Tab>
                                </TabList>
                                <TabPanel>



                                    <div className="container-fluid">
                                        <div className="row p-3 ">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                        </div>
                                        <div className="row p-3">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                        </div>
                                        <div className="row p-3">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                        </div>
                                        <div className="row p-3">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg} alt="" />
                                            </div>
                                        </div>
                                    </div>









                                </TabPanel>
                                <TabPanel>
                                <div className="container-fluid">
                                        <div className="row p-3 ">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                        </div>
                                        <div className="row p-3">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                        </div>
                                        <div className="row p-3">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                        </div>
                                        <div className="row p-3">
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img className="img-fluid" width={200} src={SavedImg1} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </TabPanel>
                            </Tabs>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Saved;

