import React from "react";
const UserCardSlider1 = (prop) => {
    return (
        <>

            <div className="card bg-transparent mb-4" >
                <img src={prop.CardImg} class="card-img-top mx-auto d-block" style={{ width: "100px", transform: "translateY(40%)", zIndex: "1" }} alt="" />
                <div class="card border border-top-0 shadow pt-5" style={{ 'borderRadius': '20px' }}>
                    <img src={prop.CardImg2} class="mx-auto d-block img-fluid p-1" style={{ width: "250px" }} alt="" />
                    <div class="card-body">
                        <div class="px-3" >
                            <h5 className="text-start text p-1 fw-bold">{prop.AboutHeading}</h5>
                            <p class="text-justify p-1" style={{ fontSize: "13px" }}>{prop.CardContent}</p>
                            <center><button class="btn btn-outline-primary bg-white text-primary shadow btn-bg mx-auto justify-content-center" >{prop.CardBtn} <span><i class="fa fa-share-square-o float-end mt-1 me-1 mx-4" aria-hidden="true"></i></span> </button></center>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const UserViewer = (prop) => {
    return (
        <>
            {/* <div className="row d-flex justify-content-between">
                <div className="mt-4">
                    <img src={prop.CardImg2} class="mx-auto d-block shadow rounded-5" style={{ width: "200px" }} alt="" />
                    <button style={{ transform: "translateY(-130%) translateX(16%)" }} className="btn btn-outline-primary btn-sm bg-transparent text-primary shadow mx-auto" >{prop.UserId} <span><i class="fa fa-share-square-o float-end mt-1 me-1 mx-2" aria-hidden="true"></i></span> </button>
                </div>
            </div> */}

            <div className="card mt-4">
                <div className="card-body row">
                    <div className="card-item">
                        <img src={prop.CardImg2} class="Card-Img mx-auto d-block shadow rounded-5 img-fluid" style={{ width: "200px" }} alt="" />
                        <div className="col-md-12 col-12" style={{ position: "absolute", bottom: "22px", left: "0px" }}>
                            <center><button className="btn btn-outline-primary shadow btn-sm" style={{zIndex: "1"}}  >{prop.UserId}</button></center>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Card >
                <Card.Img variant="top" src={prop.CardImg2} />
                <Card.Body style={{ position: "absolute", bottom: "22px", left: "0px" }}>
                    <Button variant="primary">{prop.UserId}</Button>
                </Card.Body>
            </Card> */}
        </>
    );
}

const UserCardSlider2 = (prop) => {
    return (
        <>

            <div className="mx-auto justify-content-between" style={{ margin: "20px 0px" }}>
                <div className="col-md-12 mx-auto shadow bg-light rounded-circle h-100 " style={{ padding: "60px 50px", }}>
                    <div className=" " style={{ width: "130px", height: "50px" }}>
                        <img src={prop.CardImg} alt="" />
                    </div>
                    <div className="mt-5">
                        <center>
                            <span className="mx-auto fw-bold mt-4">{prop.CardContent}</span>
                        </center>
                    </div>
                </div>
            </div>

        </>
    );
}


export { UserViewer };
export { UserCardSlider1 };
export { UserCardSlider2 };
