import React from "react";
import { NavLink } from "react-router-dom";
import MainMenu from "../MainMenu";
import VisitedImg1 from "../components/assets/img/visited1.svg"
import VisitedImg2 from "../components/assets/img/visited2.svg"
import VisitedImg3 from "../components/assets/img/visited 3.svg"
import VisitedImg4 from "../components/assets/img/visited4.svg"
import { Icon } from "@iconify/react";

const ProfileActivityVisited = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Visited</h2>
                    <div class="border p-3">

                        <ul className="list-group ">


                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg1} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg2} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg3} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg4} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg1} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg1} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg1} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg2} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg3} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>
                            <li className="rounded list-group-item pb-3 border-0">
                                <img src={VisitedImg4} alt="avatar" width="90" class=" border rounded border-primary border-1" />
                                <Icon fontSize="25px" color="#0d6efd" icon="fa6-solid:arrow-up-right-from-square" className=" float-end mt-4 pointer" />
                                <p className="text-primary" style={{ marginLeft: "105px", fontSize: "18px", fontWeight: "500", marginTop: "-80px" }} > <span className="text-dark"> Visited 25-September 2022 7:33PM</span> <br />@Brand_Profile-id</p>
                            </li>




                        </ul>



                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileActivityVisited;