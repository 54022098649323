import React from "react";
import { NavLink } from "react-router-dom";
import MobileWithFront from "../../slider/MobileWithFront";
import FrontFrame2 from "../../slider/FrontFrame2";
import pen from "../business/img/pen.png"


const BusinessJoin = () => {
    return (
        <>
            <div className="container row mt-5">
                <div className="col-md-6 col-sm-12 mt-5 ps-5">
                    <div className="row mt-4">
                        {/* <div className="col-md-6 d-none d-md-block"> */}
                        <div className="col-md-6" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                            <MobileWithFront />
                        </div>

                        <div className="col-md-6 d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-115%)" }} >
                            <FrontFrame2 />
                        </div>

                    </div>
                    {/* <img style={{ width: "300px", height: "550px" }} src={mobileFrame} alt="Mobile Frame" /> */}
                </div>
                <div className="col-md-6 col-sm-12 mt-5 ">
                    <div className="col-md-12 col-sm-12">
                        <h4 className="text-uppercase">your success is the next door <img width={70} src={pen} style={{ marginTop: "-30px", marginLeft: "-20px" }} alt="" /></h4>
                        <h5 className="" style={{ fontWeight: "400" }}>Get your business connecting on
                            <span className="text" style={{ fontFamily: "Satisfy", }}> myttube</span> world.</h5>
                        <span className="mb-2" style={{ fontSize: "10px", marginTop: "2px" }}>Enter a few business details to get started</span>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="w-75">
                            <div class="card d-sm-flex d-block mb-4"
                                style={{ borderRadius: "4px" }}>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input class="form-control" placeholder="*Enter Your Business Name" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input class="form-control" placeholder="*Enter Your Name" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input class="form-control" placeholder="*Enter Your E-mail" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input class="form-control" placeholder="*Enter Your Mobile Number" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="row">
                                <div class="col-md-12">
                                    <div>
                                        <h6 class="float-start">Enter your 6 digit OTP</h6>
                                        <h6 class="float-end"><a href="">Send OTP</a></h6>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                    <div class="form-group">
                                        <input class="form-control"
                                            style={{ borderRadius: "20px" }} />
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                    <div class="form-group">
                                        <input class="form-control"
                                            style={{ borderRadius: "20px" }} />
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                    <div class="form-group">
                                        <input class="form-control"
                                            style={{ borderRadius: "20px" }} />
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                    <div class="form-group">
                                        <input class="form-control"
                                            style={{ borderRadius: "20px" }} />
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                    <div class="form-group">
                                        <input class="form-control"
                                            style={{ borderRadius: "20px" }} />
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                    <div class="form-group">
                                        <input class="form-control"
                                            style={{ borderRadius: "20px" }} />
                                    </div>
                                </div>
                            </div> */}
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Subject</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="col-md-12 col-lg-12 col-sm-2 col-12 shadow">
                                    <div class="form-group d-flexn shadow">
                                        <input class="form-control col-md-2 col-lg-2 col-sm-2 col-2"
                                            style={{ borderRadius: "4px", height: "40px" }} />
                                        <p>I’m not a robot</p>
                                    </div>
                                </div> */}
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <textarea class="form-control rounded-2" rows="4" placeholder="Message"></textarea>
                                        </div>
                                        <button type="button" data-toggle="modal" data-target="#myModal" class="btn btn-primary w-100">Submit</button>

                                        {/* ---model popup--- */}
                                        <div class="modal fade" id="myModal">
                                            <div class="modal-dialog">
                                                <div class="modal-content">


                                                    {/* <!-- Modal body --> */}
                                                    <div class="modal-body">
                                                        <div class="centre">
                                                            <div className="row d-flex justify-content-center" style={{ marginTop: "70px" }}>
                                                                <div class="text-center mb-4">
                                                                    <div class="col-md-12">
                                                                        <div className="tect">
                                                                            {/* <h4 class="">Enter your 6 digit OTP</h4> */}
                                                                            <h5>OTP has been sent to XXXX12345XXX</h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row col-md-8">
                                                                    <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                        <div class="form-group">
                                                                            <input class="form-control"
                                                                                style={{ borderRadius: "4px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                        <div class="form-group">
                                                                            <input class="form-control"
                                                                                style={{ borderRadius: "4px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                        <div class="form-group">
                                                                            <input class="form-control"
                                                                                style={{ borderRadius: "4px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                        <div class="form-group">
                                                                            <input class="form-control"
                                                                                style={{ borderRadius: "4px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                        <div class="form-group">
                                                                            <input class="form-control"
                                                                                style={{ borderRadius: "4px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                        <div class="form-group">
                                                                            <input class="form-control"
                                                                                style={{ borderRadius: "4px" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-8 col-lg-6 col-sm-4 col mt-4">
                                                                    <button class="close btn btn-primary text-light w-100"><NavLink className="text-light" to="/congratulationPage">Submit</NavLink></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <!-- Modal footer --> */}
                                                    <div class="text-center p-5">
                                                        {/* <p><span class="text-primary">Need Help ?</span> How To Scan, See This Video Links <a className="d-block" href="https://www.youtube.com/aquibrahmani" target="_blank" class="text-decoration-none">https://www.youtube.com/aquibrahmani</a></p> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* ---model popup--- */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BusinessJoin;