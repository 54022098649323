import React from "react";
import MainMenu from "../MainMenu";

import ImgUser from "../../landingpage/assets/img/Group 785.png";
import { Icon } from "@iconify/react";



const ReceivedGiftFromfollowers = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Received Gift from fans/followers</h2>
                    <div class="border p-3">

                        <div className="usercard img-fluid" style={{ height: "200px" }} >
                            <div className="p-2">
                                <h5 >@Profile_Username</h5>

                                <div className="d-flex justify-content-between w-25">
                                    <h5 className="ms-4 mt-5">GIFT <span className="text-primary">5</span></h5>
                                    <h5 className="me-4 mt-5"><Icon icon="tabler:currency-rupee" /><span >1825.00 </span> <br /> <button className="btn btn-primary mt-3">Withdraw</button></h5>
                                </div>
                               

                            </div>
                        </div>

                        <div className="">
                            <ul className="list-group profilelist">
                                <li className="rounded list-group-item ">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item">
                                    <div class="p-2 px-4">
                                        <img src={ImgUser} alt="avatar" width="80" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end d-flex justify-content-between mt-2 w-50">
                                            <div>
                                                <img src={ImgUser} alt="avatar" width="80" class="rounded-circle " />
                                                <span className="text-primary mt-3" style={{ fontSize: "18px", fontWeight: "500" }}> Blossom Flowers</span>
                                            </div>
                                            <span style={{ fontSize: "45px", color: "#000" }}><Icon fontSize="70px" color="#0d6efd" icon="tabler:currency-rupee" />150</span>

                                        </button>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "-25px", fontSize: "20px" }}>ajay singh-A</p>
                                        <p style={{ marginLeft: "90px", lineHeight: "0", marginTop: "25px" }}>25-November 2022  7:33PM</p>
                                    </div>
                                </li>


                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReceivedGiftFromfollowers;