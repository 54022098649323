import { Icon } from "@iconify/react";
import { lineHeight } from "@mui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";

const Security = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="sidebar1">
                            <div className="sd-header1">
                                <h4 className="mb-0">Security</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link " to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/language"><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/ads" ><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/security"><SideDrop IconClass="text-light" MenuClass="text-light m-0" ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/help"  ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile" ><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 col-12 col-lg-6 mx-auto mb-5" >
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>
                            <p className="mt-3 fw-bold " style={{ lineHeight: "0px" }}>Login Activity </p>
                            <div className="border">
                                <ul className="p-4">
                                    <p >Login History <span style={{ fontSize: "12px" }}>are you logged in ?</span> </p>
                                    <li className="list-group-item" style={{ fontSize: "14px" }}><Icon fontSize="32px" icon="carbon:location-company" />
                                        Apple iphone   <span className="text-primary">Active Online</span>
                                        <p style={{ marginLeft: "30px", fontSize: "11px", marginTop: "-4px", lineHeight: "0" }}>Mumbai, Maharashtra November 30 at 12:30 pm
                                            <span className="float-end" style={{ marginTop: "-10px" }}>
                                                <Icon fontSize="20px" icon="ph:dots-three-outline-vertical-fill" /></span> </p>
                                    </li>


                                    <li className="list-group-item" style={{ fontSize: "14px" }}><Icon fontSize="32px" icon="carbon:location-company" />
                                        Apple iphone   <span className="text-primary">Active Online</span>
                                        <p style={{ marginLeft: "30px", fontSize: "11px", marginTop: "-4px", lineHeight: "0" }}>Mumbai, Maharashtra November 30 at 12:30 pm
                                            <span className="float-end" style={{ marginTop: "-10px" }}>
                                                <Icon fontSize="20px" icon="ph:dots-three-outline-vertical-fill" /></span> </p>
                                    </li>
                                    <li className="list-group-item" style={{ fontSize: "14px" }}><Icon fontSize="32px" icon="carbon:location-company" />
                                        Apple iphone   <span className="text-primary">Active Online</span>
                                        <p style={{ marginLeft: "30px", fontSize: "11px", marginTop: "-4px", lineHeight: "0" }}>Mumbai, Maharashtra November 30 at 12:30 pm
                                            <span className="float-end" style={{ marginTop: "-10px" }}>
                                                <Icon fontSize="20px" icon="ph:dots-three-outline-vertical-fill" /></span> </p>
                                    </li>
                                    <li className="list-group-item" style={{ fontSize: "14px" }}><Icon fontSize="32px" icon="carbon:location-company" />
                                        Apple iphone   <span className="text-primary">Active Online</span>
                                        <p style={{ marginLeft: "30px", fontSize: "11px", marginTop: "-4px", lineHeight: "0" }}>Mumbai, Maharashtra November 30 at 12:30 pm
                                            <span className="float-end" style={{ marginTop: "-10px" }}>
                                                <Icon fontSize="20px" icon="ph:dots-three-outline-vertical-fill" /></span> </p>
                                    </li>
                                </ul>
                            </div>


                            <p className="mt-5 fw-bold " style={{ lineHeight: "0px" }}>Saved Password </p>
                            <div className="border p-3" >
                                <p className="ms-3">App and websites <span style={{ fontSize: "14px" }}> <br /> Apple iphone
                                    <span className="text-success"> Active</span>  Saved Password</span> <span style={{ fontSize: "11px" }}> <br /> Mumbai, Maharashtra, India</span> </p>
                                {/* <p className="text-primary ms-3" style={{ lineHeight: "0" }} >@profile.username</p> */}


                                <div className=" px-3">
                                    <div className="form-group" style={{ lineHeight: "1" }}>
                                        <label for="exampleInputEmail1" class="form-label text-primary">@profile.username</label>
                                        <input type="password" placeholder="Password..." class="form-control" />
                                        <p className="text-primary mt-1"  >Change Password</p>

                                    </div>
                                    <p >Other Multiple Devices Your Password Saved</p>
                                    <p >Windows <span className="text-success"> Active </span>  Saved Password <span style={{ fontSize: "14px" }}> <br /> Mumbai, Maharashtra, India 15 October 2022 at 10:30 am</span></p>
                                    <p >Macbook <span className="text-success"> Active </span>  Saved Password <span style={{ fontSize: "14px" }}> <br /> Mumbai, Maharashtra, India 15 October 2022 at 10:30 am</span></p>
                                    <p >Vivo11 <span className="text-success"> Active </span>  Saved Password <span style={{ fontSize: "14px" }}> <br /> Mumbai, Maharashtra, India 15 October 2022 at 10:30 am</span></p>
                                </div>
                            </div>
                            {/* =========================================================================================== */}



                            <p className="mt-5 fw-bold " style={{ lineHeight: "0px" }}>Password </p>
                            <div className="border  p-3" >
                                <center>
                                    <p className="ms-4">Last Change Password <span style={{ fontSize: "14px" }}>October 30 2022 at 08:30 pm</span> </p>
                                    <p style={{ fontSize: "20px" }}>Change Your Password <br /> Secure Your Profile </p>

                                </center>
                                <div className="form-group" >
                                    <label for="exampleInputEmail1" class="form-label text-primary ms-3">Current Password</label>
                                    <div className=" px-3">
                                        <input type="password" class="form-control" />
                                    </div>
                                </div>
                                <div className="form-group" >
                                    <label for="exampleInputEmail1" class="form-label text-primary ms-3">New Password</label>
                                    <div className=" px-3">
                                        <input type="password" class="form-control" />
                                    </div>
                                </div>
                                <div className="form-group" >
                                    <label for="exampleInputEmail1" class="form-label text-primary ms-3">New Confirm Password </label>
                                    <div className=" px-3">
                                        <input type="password" class="form-control" />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <button type="submit" className="btn btn-outline-primary ms-3" style={{ width: "200px" }}>Cancel</button>
                                    <button type="submit" className="btn btn-primary me-3" style={{ width: "200px" }}>Save Changes</button>
                                </div>

                            </div>
                            {/* ========================================================================================*/}



                            <p className="mt-5 fw-bold " style={{ lineHeight: "0px" }}>Mobile</p>
                            <div className="border  p-3">

                                <p >App and websites <span style={{ fontSize: "14px" }}> </span> <br /> <span style={{ fontSize: "11px" }}>October 30 2022 at 08:30 pm</span> </p>
                                {/* <p className="text-primary ms-3" style={{ lineHeight: "0" }} >@profile.username</p> */}


                                <div className="form-group" style={{ lineHeight: "1" }}>
                                    <label for="exampleInputEmail1" class="form-label">Your 10 digit Mobile number is verified</label>
                                    <input type="password" class="form-control" />

                                    <p className="mt-1" style={{ fontSize: "15px" }}  >This contact won't be shared anyone or anywhere</p>
                                    <p style={{ lineHeight: "25px" }}>Change your number anytime <span className="text-primary float-end" style={{ fontSize: "18px" }}>Change?</span></p>
                                    <p style={{ lineHeight: "25px" }}>Change your number anytime <span className="text-primary float-end" style={{ fontSize: "18px" }}>Verify</span></p>


                                </div>
                            </div>
                            {/* =====================================================================*/}


                            <p className="mt-5 fw-bold " style={{ lineHeight: "0px" }}>Email</p>
                            <div className="border p-3" style={{ marginBottom: "80px" }} >

                                <p>App and websites <span style={{ fontSize: "14px" }}> </span> <br /> <span style={{ fontSize: "11px" }}>October 30 2022 at 08:30 pm</span> </p>
                                {/* <p className="text-primary ms-3" style={{ lineHeight: "0" }} >@profile.username</p> */}


                                <div className="form-group" style={{ lineHeight: "1" }}>
                                    <label for="exampleInputEmail1" class="form-label">Your 10 digit Mobile number is verified</label>
                                        <input type="password" class="form-control" />
                                

                                    <p className=" mt-1 text-danger" style={{ fontSize: "13px" }}  >Your Email not verify</p>
                                    <p style={{ lineHeight: "25px" }}>Change your number anytime <span className="text-primary float-end" style={{ fontSize: "18px" }}>Verify</span></p>
                                    {/* <p className="ms-4"style={{lineHeight:"25px"}}>Change your number anytime <span className="text-primary float-end me-4"style={{fontSize:"18px"}}>Verify</span></p> */}


                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Security;