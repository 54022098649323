import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";


const TestApi = () => {
    const baseURL = "https://jsonplaceholder.typicode.com/posts";
    const [myData, setMyData] = useState([]);

    useEffect(() => {
        axios.get(baseURL)
            .then(response => {
                console.log(response);
                setMyData(response.data);
            })
            .catch(error => {
                console.log(error);
            });

        // const baseURL = "https://localhost:44328/api/Banner/get-all-banner?api_key=myttube123456";
        // const [myData, setMyData] = useState([]);

        // useEffect(() => {
        //     axios.get(baseURL)
        //         .then(response => {
        //             console.log(response);
        //             setMyData(response.data);
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });


    }, []);



    return (
        <>
            {myData.slice().map((post) => {
                const { id, title, body } = post;
                //const { banner_master_id, file_name } = post;
                return (
                    <>

                        <center>
                            <div className="col-md-4" style={{ paddingTop: "90px" }}>
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">Sr No : {id}</h5>
                                        <h6 class="card-subtitle mb-2 text-priary">Tittle : {title}</h6>
                                        <p class="card-text"><b>Discription :</b> {body}</p>
                                        {/* <img src={file_name} alt="" /> */}
                                    </div>
                                </div>

                            </div>
                        </center>
                    </>
                );
            })}

        </>
    );
}

export default TestApi;