import React from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";
import ImgUser from "../../landingpage/assets/img/Group 785.png";
import "../../settingpage/components/assets/css/style.css"


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Icon } from "@iconify/react";
const Permission = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>

                    </div>


                    <div className="col-md-3 col-12">
                        <div className="sidebar1">
                            <div className="sd-header1">
                                <h4 className="mb-0">Permission set </h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link" to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link" to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" MenuClass="text-light m-0" IconClass="text-light" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/language" ><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/ads"><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/help"  ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile"><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-5 col-12 col-lg-6 mx-auto mb-3" style={{ marginTop: "80px" }}>
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>
                            <Tabs defaultIndex={0} className="mt-2">
                                <TabList className="d-flex justify-content-between w-75 mx-auto">
                                    <Tab className="btn border-bottom-0" >Actived</Tab>
                                    <Tab className="btn border-bottom-0" >New Add</Tab>
                                </TabList>

                                <TabPanel>
                                    {/* <h2>Any content 1</h2>
                                <h2>Any content 1</h2>
                                <h2>Any content 1</h2>
                                <h2>Any content 1</h2>
                                <h2>Any content 1</h2> */}
                                    <ul className="list-group">
                                        <li className="rounded list-group-item border-0 mt-4" style={{ backgroundColor: "#e5e8ec" }}>

                                            <div class="p-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle mt-3 border border-primary border-1" />
                                                <span style={{ fontSize: "18px", fontWeight: "500" }}> Rajan Mistry-1  </span>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-15px" }}>@m.rajan02</p>
                                            </div>
                                            <p className="ps-3">this profile use admin access, post, all access your profile, like you</p>

                                            <div className=" px-3 d-flex justify-content-between">
                                                <h6 className="text-primary">Admin </h6>
                                                <h6 className="text-danger">Remove </h6>
                                            </div>



                                        </li>

                                        <li className="rounded list-group-item border-0 mt-4" style={{ backgroundColor: "#e5e8ec" }}>

                                            <div class="p-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle mt-3 border border-primary border-1" />
                                                <span style={{ fontSize: "18px", fontWeight: "500" }}> rajiv khanna-1  </span>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-15px" }}>@rajiv007</p>
                                            </div>
                                            <p className="ps-3 text-danger">this profile, post, video & reply message #Content</p>

                                            <div className=" px-3 d-flex justify-content-between">
                                                <h6 className="text-primary">Manager  </h6>
                                                <h6 className="text-danger">Remove </h6>
                                            </div>



                                        </li>

                                    </ul>


                                </TabPanel>
                                <TabPanel>
                                    <div class="input-group mt-4">
                                        <span class="input-group-text" id="basic-addon1"><Icon fontSize="25px" opacity=".4" icon="ic:baseline-search" /></span>
                                        <input type="text" class="form-control" placeholder="Search..." />



                                    </div>

                                    <ul className="list-group profilelist">
                                        <li className="rounded list-group-item border-0 mt-4">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span style={{ fontSize: "18px", fontWeight: "500" }}> ajay singh  </span>
                                                {/* <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-15px" }}>rajiv007</p> */}
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1 </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>

                                            </div>

                                        </li>
                                      
                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1 </span>
                                                <button className="btn btn-outline-primary float-end mt-2"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>

                                            </div>

                                        </li>
                                    </ul>

                                </TabPanel>
                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Permission;