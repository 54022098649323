import React from "react";

const InnerCommunity = (prop) => {
    return (
        <>
            <img class="img-fluid" src={prop.Img} alt="" />
        </>
    );
}

const InnerHeading = (prop) => {
    return (
        <h1 class="display-4 text mt-5 pt-5" style={{ fontWeight: "500" }}>{prop.Heading}</h1>
    );
}

const InnerContact = (prop) => {
    return (
        <>
            <ul style={{ listStyle: "none" }}>
                <li><a class="text-dark" href="#">{prop.Content1}</a></li>
                <li><a class="text-dark" href="#">{prop.Content2}</a></li>
                <li><a class="text-dark" href="#">{prop.Content3}</a></li>
                <li><a class="text-dark" href="#">{prop.Content4}</a></li>
                <li><a class="text-dark" href="#">{prop.Content5}</a></li>
                <li><a class="text-dark" href="#">{prop.Content6}</a></li>
                <li><a class="text-dark" href="#">{prop.Content7}</a></li>
                <li><a class="text-dark" href="#">{prop.Content8}</a></li>
            </ul>
        </>
    );
}
const InnerBtn = (prop) => {
    return (
        <>
        
            <button class="btn-1 btn-created btn btn-lg px-5" >{prop.Text}</button>
   
        </>
    );
}



export default InnerCommunity;
export { InnerHeading };
export { InnerBtn };
export { InnerContact };