import React from "react";
import '../landingpage/assets/css/main/main.css';
import SideBar, { SideDrop, SideSponsored } from "./components/SideListSection";

// importurl  ('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.8/slick-theme.min.css')
// import '../landingpage/assets/css/slick-theme.min.css';
import '../landingpage/assets/css/slick.css';

// import sidelist from '../landingpage/assets/img/sidelist.png';

import Icon1 from '../landingpage/assets/img/Icon1.png'
import Icon2 from '../landingpage/assets/img/About.png'
import Icon3 from '../landingpage/assets/img/Icon3.png'
import Icon4 from '../landingpage/assets/img/Discover.png'
import Icon5 from '../landingpage/assets/img/Create.png'
import Icon6 from '../landingpage/assets/img/Icon4.png'

import Company from '../landingpage/assets/img/Company.png'
import Groups from '../landingpage/assets/img/Groups.png'
import Earn from '../landingpage/assets/img/Earn.png'
import Investors from '../landingpage/assets/img/Investors.png'
import Partners from '../landingpage/assets/img/Partners.png'
import Contact from '../landingpage/assets/img/Contact.png'
import History from '../landingpage/assets/img/History.png'
import Career from '../landingpage/assets/img/Career.png'
import Services from '../landingpage/assets/img/Services.png'
import Privacy from '../landingpage/assets/img/Privacy.png'

import Sponsored1 from '../landingpage/assets/img/Sponsored1.png'
import Sponsored2 from '../landingpage/assets/img/Sponsored2.png'
import Sponsored3 from '../landingpage/assets/img/Sponsored3.png'
import Sponsored4 from '../landingpage/assets/img/Sponsored4.png'

import { useEffect } from 'react'
import $ from 'jquery';
import Story from "./components/Story";
import Carousel from "./components/Carousel";
import PostBar from "./components/PostBar";
import AdvertiseBar from "./components/AdvertiseBar";
import PostSection from "./components/PostSection"
import ImgUser from "../landingpage/assets/img/Group 785.png";
import ImgIcon from "../landingpage/assets/img/Group 759.png";
import ImgPost1 from "../landingpage/assets/img/image 444.png";
import ImgPost2 from "../landingpage/assets/img/tenor 1.png";





import Advertise from "./components/AdvertiseSection";
import ImgLogo1 from "../landingpage/assets/img/loreal.jpg";
import ImgBrand1 from "../landingpage/assets/img/image 367.png";
import ImgLogo2 from "../landingpage/assets/img/flipkart.jpg";
import ImgBrand2 from "../landingpage/assets/img/FlipkartAds.png";

import Popup from "./assets/css/popup";
import { NavLink } from "react-router-dom";

import { Icon } from '@iconify/react';
import Sidebars from "./components/Sidebar";



const LandingPage = () => {

    useEffect(() => {

        $(document).ready(function () {
            $("#myModal").modal('show');
        });

    }, [])


    return (
        <>
            {/* <!-- ================= Main ================= --> */}
            <div class="container-fluid">
                <div class="row justify-content-evenly">
                    {/* <!-- ================= Sidebar Start================= --> */}
                    <div class="col-12 col-md-3 col-lg-3">
                        <div class="d-none d-xl-block h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <ul class="navbar-nav mt-4 ms-3 d-flex flex-column pb-2 mb-2" style={{ paddingTop: "56px" }}>
                                <Popup />
                                {/* <!-- top --> */}

                                <li class="d-flex justify-content-between ">
                                    <p style={{ fontSize: "12px" }}>most poplular, you can </p>
                                    <span class="me-4 edit-button">see all</span>
                                </li>

                                {/* <!-- Follow List Start --> */}
                                <Sidebars />
                                {/* <!-- Follow List End --> */}

                                <hr class="m-0" />

                                {/* Login Button Start*/}
                                {/* <li data-bs-toggle="modal" data-bs-target="#newChat">
                                    <label class="btn btn-primary mx-auto rounded-pill" style={{ width: "95%" }}>Login</label>
                                </li> */}
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/profilepage" ><SideDrop ItemIcon="uiw:setting-o" MenuName="Setting" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/profileactivity" ><SideDrop ItemIcon="gg:edit-highlight" rotate="1" MenuName="Profile Activity" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/digitalcollections" ><SideDrop ItemIcon="fluent:gift-card-16-regular" MenuName="Digital Collections" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/history" ><SideDrop ItemIcon="fluent:history-24-filled" MenuName="History" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/archive" ><SideDrop ItemIcon="ion:archive" MenuName="Archive" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/saved" ><SideDrop ItemIcon="bi:bookmark-star" MenuName="Saved" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/favoritesprofile" ><SideDrop ItemIcon="emojione:sparkling-heart" MenuName="Favorites Profile" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/friend" ><SideDrop ItemIcon="carbon:friendship" MenuName="Friends" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="/Home/qrcode" ><SideDrop ItemIcon="ic:round-qr-code-scanner" MenuName="QR Code" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="#" ><SideDrop ItemIcon="fluent:people-add-20-regular" MenuName="Discover" /></NavLink>
                                </li>
                                <li className="dropdown-item p-1 rounded">
                                    <NavLink to="#" ><SideDrop ItemIcon="fluent:share-48-filled" MenuName="Share" /></NavLink>
                                </li>



                                {/* ================================================ */}

                                {/* <li class="p-1" type="button">
                                    <div id="accordionFlushExample">
                                        <div> */}

                                            {/* <!-- see more button --> */}
                                            {/* <div class="d-flex align-items-center" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                aria-expanded="false" aria-controls="flush-collapseOne">
                                                <div class="p-2">
                                                    <i class="fa fa-chevron-down rounded-circle p-2"
                                                        style={{ backgroundColor: "#d5d5d5" }}></i>
                                                </div>
                                                <div>
                                                    <p class="m-0">See More</p>
                                                </div>
                                            </div> */}
                                            {/* <!-- content --> */}
                                            {/* <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                                                data-bs-parent="#accordionFlushExample">
                                                <div> */}
                                                    {/* <!-- item 1 --> */}
                                                    {/* <div class="d-flex align-items-center dropdown-item p-0 m-0">
                                                        <SideDrop SideIcon={Company} MenuName="Company" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}





                                {/* ================================================ */}

                                {/* <hr class="m-0" /> */}
                            </ul>
                        </div>
                    </div>
                    {/* <!-- ================= Sidebar End ============== --> */}


                    <div class="col-12 col-lg-6 col-md-12 pb-5">
                        <div class="d-flex flex-column justify-content-center w-100 mx-auto pt-5">

                            <div class="d-flex justify-content-between mt-4 pt-3">
                                <p style={{ fontSize: "15px", fontWeight: "500" }}>Top Celebrity You Can Follow</p>
                                <span style={{ fontWeight: "500" }}>see all</span>
                            </div>

                        </div>

                        <div class="shadow rounded p-2 mx-auto" style={{ maxWidth: "100%" }}>
                            <Story />
                        </div>

                        <div class="bg-white p-1 mt-3 rounded border shadow">
                            <Carousel />
                        </div>

                        {/* **************PostStart********* */}
                        <PostSection UserImg={ImgUser} UserName="sony.mahta" IconImg={ImgIcon} UserId="@sonymahta07" PostContent="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quae fuga incidunt consequatur tenetur doloremque officia corrupti provident tempore vitae labore?"
                            PostImg={ImgPost1} LikeContent="Aquib, Phu, Tuan, and 3 others" Comment="#content #content #content @arunmishra @aquibrahmani..."
                            CommentUser1="John" CommentContent1="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg1={ImgUser}
                            CommentUser2="Jerry" CommentContent2="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg2={ImgUser} CommentImg3={ImgUser} />
                        {/* ******************PostEND*********** */}

                        {/* ************Advertise******** */}

                        <Advertise BrandLogo={ImgLogo1} BrandName1="loreal" BrandImg={ImgBrand1} />




                        {/* **************PostStart********* */}
                        <PostSection UserImg={ImgUser} UserName="janvi.agarwal" IconImg={ImgIcon} UserId="@janviagarwal07" PostContent="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quae fuga incidunt consequatur tenetur doloremque officia corrupti provident tempore vitae labore?"
                            PostImg={ImgPost2} LikeContent="Aquib, Phu, Tuan, and 3 others" Comment="#content #content #content @arunmishra @aquibrahmani..."
                            CommentUser1="John" CommentContent1="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg1={ImgUser}
                            CommentUser2="Jerry" CommentContent2="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg2={ImgUser} CommentImg3={ImgUser} />
                        {/* ******************PostEND*********** */}

                        {/* ************Advertise******** */}

                        <Advertise BrandLogo={ImgLogo2} BrandName="flipkart_sellerhub" BrandImg={ImgBrand2} />




                    </div>




                    {/* <%------------------------Sponsored Start-----%> */}
                    <div class="col-12 col-md-3 col-lg-3">
                        <div class="d-none d-xl-block h-100 fixed-top end-0 overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4", paddingTop: "56px", left: "initial" }}>
                            <div class="p-3 mt-3">
                                <h5 style={{fontSize: "15px"}}>Sponsored</h5>
                                <SideSponsored SponsoredImg={Sponsored1} />
                                <div className="mt-2">
                                    <SideSponsored SponsoredImg={Sponsored2} />
                                </div>
                                <div className="mt-2">
                                    <SideSponsored SponsoredImg={Sponsored3} />
                                </div>
                                <div className="mt-2">
                                    <SideSponsored SponsoredImg={Sponsored4} />
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <%------------------------Sponsored End-----------%> */}


                </div>
            </div>






        </>
    )
}

export default LandingPage;