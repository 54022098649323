import React from "react";
import { CardGroup, Carousel, Form } from "react-bootstrap";
import BlogSection1, { BlogBtn, BlogCommingSoon, BlogImg, LeftHeading, RightHeading } from "./BlogSection1";
import BlogSectionImg from "../blog/img/BlogSectionImg.png";
import CommingSoon from "../blog/img/CommingSoon.png";
import BlogCard from "./BlogCard";
import CardImg1 from "../blog/img/blogcard1.png"
import CardImg2 from "../blog/img/blogcard2.png"
import CardImg3 from "../blog/img/blogcard3.png"
import CardImg4 from "../blog/img/blogcard4.png"
import CardImg5 from "../blog/img/blogcard5.png"
import CardImg6 from "../blog/img/blogcard6.png"
import CardImg7 from "../blog/img/blogcard7.png"
import CardImg8 from "../blog/img/blogcard8.png"
import CardImg9 from "../blog/img/blogcard9.png"
import BlogSectionLast from "./BlogSectionLast";
import SponseredSlider from "../../slider/SponseredSlider";
import Footer from "../../../Footer";
import { CareerViewer } from "../Career/CareerSection";

import card1 from '../Career/img/cc-1.png';
import card2 from '../Career/img/cc-2.png';
import card3 from '../Career/img/cc-3.png';
import card4 from '../Career/img/cc-4.png';
import card5 from '../Career/img/cc-5.png';
import card6 from '../Career/img/cc-6.png';
import slider1 from "../blog/img/slider1.png"
import slider2 from "../blog/img/slider2.png"
import slider3 from "../blog/img/slider3.png"
import slider4 from "../blog/img/slider4.png"
import slider5 from "../blog/img/slider5.png"
import slider6 from "../blog/img/slider6.png"
import slider7 from "../blog/img/slider7.png"
import slider8 from "../blog/img/slider8.png"
import viewer1 from "../Career/img/viewer1.png";
import viewer2 from "../Career/img/viewer2.png";
import viewer3 from "../Career/img/viewer3.png";
import viewer4 from "../Career/img/viewer3.png";
import viewer5 from "../Career/img/viewer3.png";
import viewer6 from "../Career/img/viewer3.png";
import { BrandSlider } from "../business/BusinessSection";

import LoginScreen from "../about/img/loginScreen.png";
import flag from "../about/img/flag.png";
import vLine from "../../../assets/img/verticalLine.svg";
import slider1Image from "../blog/img/blogBg.png"


const Blog = () => {
    return (
        <>


            <section style={{marginTop:"-40px"}}>
                {/* <div class="container mb-5">
                    <div class="row">
                        <h1 className="text-center text">About all stories</h1>
                        <h1 className="text-center" style={{ marginTop: "25rem", marginBottom: "6rem", fontWeight: "400" }}>Upcoming Big Events</h1>

                    </div>
                </div> */}
                <Carousel>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid" src={slider1Image} alt="First slide" />
                        <Carousel.Caption className="mb-4">
                            <h1 className="text-center text">About all stories</h1>
                            <center style={{ marginTop: "-20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                            <h1 className="text-center text-dark" style={{ marginTop: "20rem", marginBottom: "6rem", fontWeight: "400" }}>Upcoming Big Events</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid" src={slider1Image} alt="First slide" />
                        <Carousel.Caption className="mb-4">
                            <h1 className="text-center text">About all stories</h1>
                            <center style={{ marginTop: "-20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                            <h1 className="text-center text-dark" style={{ marginTop: "20rem", marginBottom: "6rem", fontWeight: "400" }}>Upcoming Big Events</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid" src={slider1Image} alt="First slide" />
                        <Carousel.Caption className="mb-4">
                            <h1 className="text-center text">About all stories</h1>
                            <center style={{ marginTop: "-20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                            <h1 className="text-center text-dark" style={{ marginTop: "20rem", marginBottom: "6rem", fontWeight: "400" }}>Upcoming Big Events</h1>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </section>
            <section className="img1" style={{ display: "flex", transform: "translateY(-30%)" }}>

                <div className="container">
                    <div className="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <BlogCard Img={CardImg7} title="BLOG/Tittle" content="Taglines are usually a short one-line summary of what your blog is all about. They usually appear just below your blog name/title. No wonder, ..." />

                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <BlogCard Img={CardImg9} title="BLOG/Tittle" content="Taglines are usually a short one-line summary of what your blog is all about. They usually appear just below your blog name/title. No wonder, ..." />
                        </div>
                    </div>
                </div>
            </section>

            <h2 className="text-center text" style={{ marginTop: "-5%" }}>More stories</h2>
            <center style={{ marginTop: "-20px", marginBottom: "20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
            <section className="img1">
                <div className="container pb-4">

                    <Carousel>
                        <Carousel.Item>

                            <CardGroup>
                                <BrandSlider CardImg={slider1} />
                                <BrandSlider CardImg={slider2} />
                                <BrandSlider CardImg={slider3} />
                                <BrandSlider CardImg={slider4} />
                            </CardGroup>
                            <CardGroup>
                                <BrandSlider CardImg={slider1} />
                                <BrandSlider CardImg={slider2} />
                                <BrandSlider CardImg={slider3} />
                                <BrandSlider CardImg={slider4} />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>

                            <CardGroup>
                                <BrandSlider CardImg={slider1} />
                                <BrandSlider CardImg={slider2} />
                                <BrandSlider CardImg={slider3} />
                                <BrandSlider CardImg={slider4} />
                            </CardGroup>
                            <CardGroup>
                                <BrandSlider CardImg={slider1} />
                                <BrandSlider CardImg={slider2} />
                                <BrandSlider CardImg={slider3} />
                                <BrandSlider CardImg={slider4} />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>

                            <CardGroup>
                                <BrandSlider CardImg={slider1} />
                                <BrandSlider CardImg={slider2} />
                                <BrandSlider CardImg={slider3} />
                                <BrandSlider CardImg={slider4} />
                            </CardGroup>
                            <CardGroup>
                                <BrandSlider CardImg={slider1} />
                                <BrandSlider CardImg={slider2} />
                                <BrandSlider CardImg={slider3} />
                                <BrandSlider CardImg={slider4} />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>

                </div>
            </section>



            <h5 className="text-center text mt-4">More stories</h5>
            <center style={{ marginTop: "-10px", }}><img src={vLine} alt="" /></center>
            <section className="">
                <div className="container mt-3" >
                    <Carousel>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>

                    <Carousel>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>


            <div className="container mb-5">

                <div class="row mt-5">

                    <div class="col-md-8 mt-5 pt-5">
                        <div class="input-group mb-5">
                            <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                            <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button class="btn btn-primary px-4 rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                        </div>

                        <h1 style={{ fontFamily: "Poppins" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                        <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world <span className="text-danger">(CONTENT)</span></p>
                    </div>

                    <div class="col-md-4 ">

                        <img src={LoginScreen} width={350} className="float-md-end img-fluid" alt="loginScreen" />
                        <h6 className="text-center text-primary">USE THE APP NOW</h6>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <Footer />

        </>
    );
}

export default Blog;