import React from "react";
import { NavLink } from "react-router-dom";
import MainMenu from "../MainMenu";
import ImgUser from "../../landingpage/assets/img/Group 785.png";
import VisitedImg1 from "../components/assets/img/visited1.svg"
import VisitedImg2 from "../components/assets/img/visited2.svg"
import VisitedImg3 from "../components/assets/img/visited 3.svg"
import VisitedImg4 from "../components/assets/img/visited4.svg"
import { Icon } from "@iconify/react";

const ProfileActivityGroup = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>

                    </div>

                    <div className="col-md-9 col-12">
                        <h2 className="text-center" style={{ marginTop: "80px" }} >Group</h2>
                        <div class="border p-3" style={{ marginBottom: "80px" }}>

                            <button className="btn btn-primary float-end me-2">Create Group</button>

                            <ul className="list-group profilelist mt-5">


                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>
                                <li className="rounded list-group-item border-0 ">
                                    <div class="p-2">
                                        <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-3" />
                                        <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                        <button className="btn btn-outline-primary float-end mt-3"><Icon fontSize="25px" color="#0d6efd" icon="ic:sharp-send" /> </button>
                                        <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                    </div>
                                </li>


                            </ul>

                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default ProfileActivityGroup;