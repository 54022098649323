import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import Logo from './assets/img/logo.png';
import lang from './assets/img/lang.png';
import Lang from "./settingpage/lang";
import GoogleTranslate from "./settingpage/lang";


const Navbar = () => {

    return (

        <>
            <div className="my_menu" style={{ }}>
                <nav className="navbar navbar-expand-lg Nav-bg fixed-top">
                    <div className="container layout_container">
                        <NavLink className="navbar-brand" to="/Home">
                            <img src={Logo} style={{ width: "140px" }} alt="" />
                        </NavLink>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
                            <ul className="navbar-nav ">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/login" ><i className="fa fa-home homeicon" aria-hidden="true">&nbsp;</i> Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/about">About Us</NavLink>
                                </li>
                                {/* <li className="nav-item">
                                    <NavLink className="nav-link " to="/blog">Blog/News</NavLink>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/company">Company</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/investor">Investor</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/creator">Creators</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/career">Career</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/business">For Business</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link " to="/contact">Contact</NavLink>
                                </li>

                                <li className="nav-item pt-3 ps-4">
                                    <NavLink to="" className="nav-link d-flex justify-content-between p-0 m-0">
                                        <i class="fa fa-globe text-dark pt-1 fs-5" aria-hidden="true"></i><GoogleTranslate />
                                    </NavLink>

                                </li>
                            </ul>

                        </div>
                    </div>
                </nav>
            </div>


            <Outlet />
        </>

    );
}
export default Navbar;