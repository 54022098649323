import React from "react";

import sidelist from '../../landingpage/assets/img/sidelist.png';
import SideBar from "./SideListSection";

const Sidebars = () => {
    return(
       <>
        <SideBar Img={sidelist} Name="Don Quixote" UserId="@aquibrahmani143" Follow="follow" />
        <SideBar Img={sidelist} Name="Don Quixote" UserId="@aquibrahmani143" Follow="follow" />
        <SideBar Img={sidelist} Name="Don Quixote" UserId="@aquibrahmani143" Follow="follow" />
        <SideBar Img={sidelist} Name="Don Quixote" UserId="@aquibrahmani143" Follow="follow" />
        <SideBar Img={sidelist} Name="Don Quixote" UserId="@aquibrahmani143" Follow="follow" />
        <SideBar Img={sidelist} Name="Don Quixote" UserId="@aquibrahmani143" Follow="follow" />


      
       </>
    );
}

export default Sidebars;