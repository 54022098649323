import React from "react";
import { NavLink } from "react-router-dom";
import MainMenu from "../MainMenu";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Icon } from "@iconify/react";
import ImgUser from "../../landingpage/assets/img/Group 785.png";
import Card from "../components/assets/img/card.png";




const MyttubeRewards = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Rewards from myttube’s Sponsered partners</h2>
                    <div class="border" style={{marginBottom:"40px"}}>
                        {/* <NavLink to="/Home/rewardspoints" > <p>Scratch this card
                            Win to Surprise Gift
                            #Content</p></NavLink> */}
                        <Tabs defaultIndex={0} className="mt-2">
                            <TabList className="d-flex justify-content-between w-75 mx-auto">
                                <Tab className="btn shadow" >Rewards</Tab>
                                <Tab className="btn shadow" >Expired</Tab>
                            </TabList>

                            <TabPanel>
                                <ul className="list-group mt-5 mb-5">


                                    <NavLink to="/Home/rewardspoints" >
                                        <li className="rounded list-group-item border-0">
                                            <div className="border border-primary border-2 rounded-3">
                                                <img src={Card} width="350" className="img-fluid" alt="" />

                                                <span className="float-lg-end p-3 w-25" style={{ fontSize: "25px" }}> <span><Icon fontSize={60} className="text-primary" icon="arcticons:rewards" /></span>
                                                    <br /> Scratch this card Win to Surprise Gift #Content</span>
                                            </div>
                                        </li>
                                    </NavLink>
                                    <NavLink to="/Home/rewardspoints" >
                                        <li className="rounded list-group-item border-0">
                                            <div className="border border-primary border-2 rounded-3">
                                                <img src={Card} width="350" className="img-fluid" alt="" />

                                                <span className="float-lg-end p-3 w-25" style={{ fontSize: "25px" }}> <span><Icon fontSize={60} className="text-primary" icon="arcticons:rewards" /></span>
                                                    <br /> Scratch this card Win to Surprise Gift #Content</span>
                                            </div>
                                        </li>
                                    </NavLink>
                                    <NavLink to="/Home/rewardspoints" >
                                        <li className="rounded list-group-item border-0">
                                            <div className="border border-primary border-2 rounded-3">
                                                <img src={Card} width="350" className="img-fluid" alt="" />

                                                <span className="float-lg-end p-3 w-25" style={{ fontSize: "25px" }}> <span><Icon fontSize={60} className="text-primary" icon="arcticons:rewards" /></span>
                                                    <br /> Scratch this card Win to Surprise Gift #Content</span>
                                            </div>
                                        </li>
                                    </NavLink>
                                    <NavLink to="/Home/rewardspoints" >
                                        <li className="rounded list-group-item border-0">
                                            <div className="border border-primary border-2 rounded-3">
                                                <img src={Card} width="350" className="img-fluid" alt="" />

                                                <span className="float-lg-end p-3 w-25" style={{ fontSize: "25px" }}> <span><Icon fontSize={60} className="text-primary" icon="arcticons:rewards" /></span>
                                                    <br /> Scratch this card Win to Surprise Gift #Content</span>
                                            </div>
                                        </li>
                                    </NavLink>
                                    <NavLink to="/Home/rewardspoints" >
                                        <li className="rounded list-group-item border-0">
                                            <div className="border border-primary border-2 rounded-3">
                                                <img src={Card} width="350" className="img-fluid" alt="" />

                                                <span className="float-lg-end p-3 w-25" style={{ fontSize: "25px" }}> <span><Icon fontSize={60} className="text-primary" icon="arcticons:rewards" /></span>
                                                    <br /> Scratch this card Win to Surprise Gift #Content</span>
                                            </div>
                                        </li>
                                    </NavLink>




                                </ul>

                            </TabPanel>

                            <TabPanel>
                                <ul className="list-group mt-5 mb-5  ">

                                    <li style={{ color: "#7E7E7E" }} className="rounded list-group-item border-primary w-50 mx-auto ">

                                        <div className="d-flex justify-content-between">
                                            <p style={{ fontSize: "25px", color: "#7E7E7E" }}>₹ 300 Flat OFF</p>
                                            <Icon fontSize={35} icon="arcticons:rewards" />
                                        </div>
                                        <h5> UBER2021FIRS... <span style={{ fontSize: "15px" }}>This Card is scratched First RIDE From UBER Win to Surprise Gift</span></h5>
                                        <div className="d-flex justify-content-between">

                                            <span>  <Icon icon="bi:exclamation-circle-fill" rotate={2} />  expired December 2022</span>
                                            <span className="text-danger float-end fw-bold">Expired</span>
                                        </div>
                                    </li>

                                </ul>
                                <ul className="list-group mt-5 mb-5  ">

                                    <li style={{ color: "#7E7E7E" }} className="rounded list-group-item border-primary w-50 mx-auto ">

                                        <div className="d-flex justify-content-between">
                                            <p style={{ fontSize: "25px", color: "#7E7E7E" }}>₹ 300 Flat OFF</p>
                                            <Icon fontSize={35} icon="arcticons:rewards" />
                                        </div>
                                        <h5> UBER2021FIRS... <span style={{ fontSize: "15px" }}>This Card is scratched First RIDE From UBER Win to Surprise Gift</span></h5>
                                        <div className="d-flex justify-content-between">

                                            <span>  <Icon icon="bi:exclamation-circle-fill" rotate={2} />  expired December 2022</span>
                                            <span className="text-danger float-end fw-bold">Expired</span>
                                        </div>
                                    </li>

                                </ul>
                                <ul className="list-group mt-5 mb-5  ">

                                    <li style={{ color: "#7E7E7E" }} className="rounded list-group-item border-primary w-50 mx-auto ">

                                        <div className="d-flex justify-content-between">
                                            <p style={{ fontSize: "25px", color: "#7E7E7E" }}>₹ 300 Flat OFF</p>
                                            <Icon fontSize={35} icon="arcticons:rewards" />
                                        </div>
                                        <h5> UBER2021FIRS... <span style={{ fontSize: "15px" }}>This Card is scratched First RIDE From UBER Win to Surprise Gift</span></h5>
                                        <div className="d-flex justify-content-between">

                                            <span>  <Icon icon="bi:exclamation-circle-fill" rotate={2} />  expired December 2022</span>
                                            <span className="text-danger float-end fw-bold">Expired</span>
                                        </div>
                                    </li>

                                </ul>
                                <ul className="list-group mt-5 mb-5  ">

                                    <li style={{ color: "#7E7E7E" }} className="rounded list-group-item border-primary w-50 mx-auto ">

                                        <div className="d-flex justify-content-between">
                                            <p style={{ fontSize: "25px", color: "#7E7E7E" }}>₹ 300 Flat OFF</p>
                                            <Icon fontSize={35} icon="arcticons:rewards" />
                                        </div>
                                        <h5> UBER2021FIRS... <span style={{ fontSize: "15px" }}>This Card is scratched First RIDE From UBER Win to Surprise Gift</span></h5>
                                        <div className="d-flex justify-content-between">

                                            <span>  <Icon icon="bi:exclamation-circle-fill" rotate={2} />  expired December 2022</span>
                                            <span className="text-danger float-end fw-bold">Expired</span>
                                        </div>
                                    </li>

                                </ul>
                                <ul className="list-group mt-5 mb-5  ">

                                    <li style={{ color: "#7E7E7E" }} className="rounded list-group-item border-primary w-50 mx-auto ">

                                        <div className="d-flex justify-content-between">
                                            <p style={{ fontSize: "25px", color: "#7E7E7E" }}>₹ 300 Flat OFF</p>
                                            <Icon fontSize={35} icon="arcticons:rewards" />
                                        </div>
                                        <h5> UBER2021FIRS... <span style={{ fontSize: "15px" }}>This Card is scratched First RIDE From UBER Win to Surprise Gift</span></h5>
                                        <div className="d-flex justify-content-between">

                                            <span>  <Icon icon="bi:exclamation-circle-fill" rotate={2} />  expired December 2022</span>
                                            <span className="text-danger float-end fw-bold">Expired</span>
                                        </div>
                                    </li>

                                </ul>



                                      








                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyttubeRewards;