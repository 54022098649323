import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Mobileframe from '../slider/img/mobileframe.png';
import Carousel1 from '../slider/img/image 552.png';
import Carousel2 from '../slider/img/image 540.png';
import MiniCarousel from '../slider/img/MiniCarousel.png';
import MiniCarousel2 from '../slider/img/MiniCarousel2.png';
import MiniCarousel3 from '../slider/img/MiniCarousel3.png';

import axios from "axios";

const FrontFrame2 = () => {

    return (

        <>
            <div className="InnerSlider2">

                {/* <img className="mobile "
                    src={Mobileframe} alt="mobile" /> */}
                <div className="mobile2 border border-2 border-primary rounded-5"></div>
                <Carousel className="FrameS2">
                    {/* <img className="mobile"
                src={Mobileframe} alt="mobile" /> */}


                    <Carousel.Item className="carousel-item" >
                        <img
                            className="d-block w-100 rounded-5"
                            src={MiniCarousel}
                            alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className="carousel-item" >
                        <img
                            className="d-block w-100 rounded-5"
                            src={MiniCarousel3}
                            alt="Second slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item className="carousel-item">
                        <img
                            className="d-block w-100 rounded-5"
                            src={MiniCarousel2}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </div>

        </>


    );
}


export default FrontFrame2;