import React from "react";
import Footer from "../../../Footer";
import MobileFrame from "../../slider/MobileFrame";

import Carousel from 'react-bootstrap/Carousel';
import CardGroup from 'react-bootstrap/CardGroup';
import "../about/About.css"

// import AboutSection1, { AbooutCardSlider, AboutHeading, AboutPara } from "./AboutSection1";
import LoginScreen from "../about/img/loginScreen.png";
import flag from "../about/img/flag.png";
import indiatoday from "../../../assets/img/brand/indiatoday.png";
import timesofindia from "../../../assets/img/brand/timesofindia.png";
import ndtv from "../../../assets/img/brand/ndtv.png";


import OrbitSlider from "../../slider/OrbitSlider";
import { AbooutCardSlider, AbooutCardSlider2 } from "../about/AboutSection1";
// import {  } from "./AbooutCardSlider2";
import { CompanyCardSlider1, CompanyCardSlider2 } from "./CompanySection1";
// import { CompanyCardSlider2 } from "./CompanySection2";




import bharatPay from '../company/img/bharatPay.png';
import myntra from '../company/img/myntra.png';
import oneplush from '../company/img/oneplush.png';
import boat from '../company/img/boat.png';
import card1 from '../company/img/c-1.png';
import card2 from '../company/img/c-2.png';
import card3 from '../company/img/c-3.png';
import card4 from '../company/img/c-4.png';
import influencer1 from '../company/img/influencer1.png';
import influencer2 from '../company/img/influencer2.png';
import influencer3 from '../company/img/influencer3.png';
import vLine from "../../../assets/img/verticalLine.svg";
import { NavLink } from "react-router-dom";


const Company = () => {

    return (
        <>
            <div class="container mt-4">

                <div class="row mt_container" >

                    <div className="col-md-8 col-12 col-sm-12">
                        <div className="row mt-4 w-75">
                            <div id="myDiv">
                                <OrbitSlider />
                            </div>
                            <div className="col-md-6" style={{ marginTop: "50px", transform: "translateY(-60%)" }} >
                                <MobileFrame />
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-12 col-sm-12">
                        <div className="mt-3 pb-2">
                            <h4 style={{ fontFamily: "Poppins", fontWeight: "400" }}> Incredible Power Of  <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> community</h4>
                            <p style={{ textAlign: "justify" }}><span className="text-danger" style={{ fontFamily: "satisfy" }}>Content <span className="text">myttube </span> </span>  communities can be incredibly powerful. They can bring people together
                                from all over the world with similar interests, provide a platform for creators to share their content and ideas, and even inspire social change. The ability to share, comment, and interact with others on myttube  can create
                                a sense of belonging and connection that is unique to the platform. Additionally, the community can also play a role in the growth and success of individual creators by promoting their content and providing feedback.

                            </p>
                            <p style={{ textAlign: "justify" }}><span className="text-danger" style={{ fontFamily: "satisfy" }}>Content <span className="text">myttube </span> </span>  Content- myttube is a powerful community-driven
                                platform that allows users to share and discover videos. The community aspect of the platform allows users to interact with each other through comments, likes, and subscriptions, and also enables
                                content creators to build a following and monetize their content. Additionally, the platform's algorithm is designed to recommend videos to users based on their viewing history and engagement,
                                which helps to promote discovery and engagement. This creates a powerful feedback loop that can drive the success of content creators and help to create viral phenomena.
                            </p>
                        </div>

                        <div className="col-md-12 d-flex justify-content-between">
                            <NavLink className="btn btn-primary shadow col-md-4 rounded" to="/login">Login</NavLink>

                            <NavLink className="btn btn-primary shadow col-md-4 rounded" to="/registration">Create</NavLink>
                        </div>
                    </div>
                </div>

                {/* carasoul - 1  */}
                <div className="container" style={{ backgroundImage: 'url("https://aakaarinfotech.com/download/companyBg.png")' }}>
                    <div className="mt-4" style={{ marginTop: "50px" }}>
                        <h2 class="text text-center mt-4">Success story</h2>
                        {/* <center style={{ marginTop: "-20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center> */}
                    </div>
                    {/* <img src={carasoulBg} class="img-fluid" alt="..." /> */}
                    <Carousel>
                        <Carousel.Item>
                            <CardGroup>
                                <CompanyCardSlider1 CardImg={bharatPay} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={myntra} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={boat} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={oneplush} CardContent="Success story" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CompanyCardSlider1 CardImg={bharatPay} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={myntra} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={boat} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={oneplush} CardContent="Success story" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CompanyCardSlider1 CardImg={bharatPay} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={myntra} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={boat} CardContent="Success story" />
                                <CompanyCardSlider1 CardImg={oneplush} CardContent="Success story" />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </div>

                {/* carasoul - 2  */}

                <div className="mt-2">
                    <p className="fs-5 fw-bold text-center text mt-5 mb-5">News/Blog/Media </p>
                    <center style={{ marginTop: "-50px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                    <Carousel>
                        <Carousel.Item >
                            <CardGroup>
                                <AbooutCardSlider CardImg={indiatoday} CardBtn=" More " CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                                <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                                <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                                <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                                <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                                <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                                <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </div>

                {/* carasoul - 3  */}

                <div className="container " style={{ backgroundImage: 'url("https://aakaarinfotech.com/download/companyBg.png")' }}>
                    <div className="mt-4" style={{ marginTop: "20px" }}>
                        <h2 class="text mt-4">Success Viewers  story</h2>
                    </div>
                    <Carousel>
                        <Carousel.Item>
                            <CardGroup>
                                <CompanyCardSlider2 CardImg={card1} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card2} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card3} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card4} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CompanyCardSlider2 CardImg={card1} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card2} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card3} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card4} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CompanyCardSlider2 CardImg={card1} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card2} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card3} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                                <CompanyCardSlider2 CardImg={card4} influencerName="Gaurav" CardContent="Success story" influencerId="@gaurav_11" />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </div>

                {/* carasoul - 4  */}

                <div className="mt-2">
                    <p className="fs-5 fw-bold text-center text mt-5">India's top influencer </p>
                    <center style={{ marginTop: "-20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                    <Carousel>
                        <Carousel.Item >
                            <CardGroup>
                                <AbooutCardSlider2 CardImg={influencer1} AboutHeading="Gourav youtuber " CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer2} AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer3} AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <AbooutCardSlider2 CardImg={influencer1} AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer2} AboutHeading="content builder" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer3} AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <AbooutCardSlider2 CardImg={influencer1} AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer2} AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer3} AboutHeading="buisness influencer" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </div>




                <div className="container">

                    <div class="row mt-5">

                        <div class="col-md-8 mt-5 pt-5">
                            <div class="input-group mb-5">
                                <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                                <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button class="btn btn-primary px-4 rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                            </div>

                            <h1 style={{ fontFamily: "Poppins" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                            <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world <span className="text-danger">(CONTENT)</span></p>
                        </div>

                        <div class="col-md-4 ">

                            <img src={LoginScreen} width={350} className="float-md-end img-fluid" alt="loginScreen" />
                            <h6 className="text-center text-primary">USE THE APP NOW</h6>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>



            </div>
            <Footer />

        </>
    );
}

export default Company;