import React from "react";
import { NavLink } from "react-router-dom";
import MainMenu from "../MainMenu";
import DeleteImg1 from "../components/assets/img/posts.svg"
import DeleteImg2 from "../components/assets/img/poststory.svg"
import DeleteImg3 from "../components/assets/img/postvideo.svg"
import DeleteImg4 from "../components/assets/img/postlive.svg"
import { Icon } from "@iconify/react";

const ProfileActivityDelete = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Delete</h2>
                    <div class="border p-3" style={{ marginBottom: "40px" }}>
                        <div className="container-fluid">
                            <div className="row p-3 ">
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg1} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg2} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg3} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg4} alt="" />
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg1} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg2} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg3} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg4} alt="" />
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg1} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg2} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg3} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg4} alt="" />
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg1} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg2} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg3} alt="" />
                                </div>
                                <div className="col-md-3 col-6">
                                    <img className="img-fluid" width={200} src={DeleteImg4} alt="" />
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}

export default ProfileActivityDelete;