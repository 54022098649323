import React from "react";
import ckeckImg from "../../../assets/img/ckeck.svg"

const RegMessage = () =>{
    return(
           <>
           <div className="container" style={{marginTop:"140px"}}>
            <center>

           <img src={ckeckImg} alt="" />
           <p className="text-success mt-3" style={{marginBottom:"8px"}}>Congratulations your <br /> Profile is successfully  </p>
           <h4 className="text-primary">Created</h4>
           <h4 className="text">enjoy the <span style={{fontFamily: "satisfy"}}>myttube</span>  world</h4>
            </center>
           </div>
           </>
    );
}

export default RegMessage;