import React from "react";
import InvestorHeading, { InvestorBtn, InvestorPara } from "./InvestorSection";
import Investor2 from "../investor/img/Investor2.png"
import ContactForm from "../contact/ContactForm";
import Footer from "../../../Footer";
// import ContactSectionText from "./ContactSection";
import pen from "../business/img/pen.png";
import handShake from "../contact/img/handShake.svg";
import contactFrame from "../contact/img/contactframe.svg";
import congratulationPage from "../business/CongratulationPage";
import { NavLink } from "react-router-dom";
import OrbitSlider from "../../slider/OrbitSlider";
import investerChess from "../investor/img/invester1.svg"

const Investor = () => {

    return (
        <>
            <div className="mb-5">
            <div class="row mt_container" >
                    <div className="col-md-12">
                        <div className="col-md-4 mx-auto">
                            {/* <img src={handShake} alt="" width="400px" className="img-fluid" /> */}
                        </div>
                    </div>
                    {/* -------left content----- */}
                    <div className="col-md-7 d-none d-md-block">
                        <img src={handShake} alt="" width="500px" className="img-fluid float-end" />
                        <div id="myDiv" >
                            <OrbitSlider className="mt-4" />
                            <h3 className="" style={{ marginTop: "-40px", marginLeft: "100px", fontSize: "26px" }}>The social media is a greatest <br /> community at all time</h3>
                            <img src={investerChess} alt="invester chess bg" width="660px" className="mt-3" />
                        </div>
                    </div>

                    {/* -------- right form------- */}

                    <div className="col-md-5 col-sm-12">
                        <div className="mt-5">
                            <div className="col-md-12 col-sm-12 px-2">
                                <h5 className="">let’s Build <span className="text-danger"> our</span> Community on<span className="text" style={{ fontFamily: "Satisfy", }}> myttube</span> <img width={70} src={pen} style={{ marginTop: "-30px", marginLeft: "-20px" }} alt="" /></h5>
                                <h5>For Investor Queries and Grievance Redressal<br /><span className="fw-normal fs-6">Company Secretary and Chief financial Officer</span></h5>

                                <p className="" style={{ fontSize: "12px", fontWeight: "600" }}>Email: finance@myttube.com<br />
                                    officer Name: agarwal<br />
                                    Contact: +91-800***666**</p>
                                <span className="mb-2" >Get your business connecting on <span className="text" style={{ fontFamily: "Satisfy", }}> myttube</span> world.</span><br />
                                <span className="mb-2" style={{ fontSize: "10px", marginTop: "2px" }}>Enter a few business details to get started</span>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <div className="w-100">
                                    <div class="card d-sm-flex d-block mb-4"
                                        style={{ borderRadius: "4px" }}>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <button className="btn btn-primary bg-transparent col-12 text-primary">For Business</button>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <input class="form-control" placeholder="*Enter Your Name" style={{ borderRadius: "4px" }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <input class="form-control" placeholder="*Enter Your E-mail" style={{ borderRadius: "4px" }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <input class="form-control" placeholder="*Enter Your Mobile Number" style={{ borderRadius: "4px" }} />
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <select class="form-select" aria-label="Default select example">
                                                        <option selected>Business Partner</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <textarea class="form-control rounded-2" rows="4" placeholder="Message"></textarea>
                                                </div>
                                                <button type="button" data-toggle="modal" data-target="#myModal" class="btn btn-primary w-100">Submit</button>

                                                {/* ---model popup--- */}
                                                <div class="modal fade" id="myModal">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">


                                                            {/* <!-- Modal body --> */}
                                                            <div class="modal-body">
                                                                <div class="centre">
                                                                    <div className="row d-flex justify-content-center" style={{ marginTop: "70px" }}>
                                                                        <div class="text-center mb-4">
                                                                            <div class="col-md-12">
                                                                                <div className="tect">
                                                                                    {/* <h4 class="">Enter your 6 digit OTP</h4> */}
                                                                                    <h5>OTP has been sent to XXXX12345XXX</h5>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row col-md-8">
                                                                            <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                                <div class="form-group">
                                                                                    <input class="form-control"
                                                                                        style={{ borderRadius: "4px" }} />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                                <div class="form-group">
                                                                                    <input class="form-control"
                                                                                        style={{ borderRadius: "4px" }} />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                                <div class="form-group">
                                                                                    <input class="form-control"
                                                                                        style={{ borderRadius: "4px" }} />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                                <div class="form-group">
                                                                                    <input class="form-control"
                                                                                        style={{ borderRadius: "4px" }} />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                                <div class="form-group">
                                                                                    <input class="form-control"
                                                                                        style={{ borderRadius: "4px" }} />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                                <div class="form-group">
                                                                                    <input class="form-control"
                                                                                        style={{ borderRadius: "4px" }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-8 col-lg-6 col-sm-4 col mt-4">
                                                                            <button class="close btn btn-primary text-light w-100"><NavLink className="text-light" to="/congratulationPage">Submit</NavLink></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <!-- Modal footer --> */}
                                                            <div class="text-center p-5">
                                                                {/* <p><span class="text-primary">Need Help ?</span> How To Scan, See This Video Links <a className="d-block" href="https://www.youtube.com/aquibrahmani" target="_blank" class="text-decoration-none">https://www.youtube.com/aquibrahmani</a></p> */}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ---model popup--- */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>


    );

}

export default Investor;