import React from "react";
import { NavLink } from "react-router-dom";
import MainMenu from "../MainMenu";
import ScratchCard from 'react-scratchcard';
import ScratchCardImg from "../components/assets/img/Scratch_card.jpg";
// import { ProgressBar, Step } from "react-step-progress-bar";
// import "react-step-progress-bar/styles.css";

import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";


const settings = {

    width: 350,
    height: 350,
    image: ScratchCardImg,
    finishPercent: 50,
    onComplete: () => console.log('The card is now clear!'),
    

};

    



const RewardsPoints = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Rewards Points</h2>
                    <div class="border">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="p-5">
                                    <ScratchCard  {...settings}>
                                        ₹ 300 Flat OFF
                                    </ScratchCard>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="p-5">
                                    <h5 className="mb-5">How does it work?</h5>


                                    {/* <ProgressBar
                                    
                                        transition
                                        percent={100}
                                        filledBackground="linear-gradient(to right, #a4c5f0, #0087FF)"

                                    >


                                        <Step transition="scale" >
                                            {({ accomplished, index }) => (
                                                <div style={{ padding: "20px", fontSize: "25px", backgroundColor: "#a4c5f0" }}
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}
                                                </div>
                                            )}
                                        </Step>
                                        <Step transition="scale" >
                                            {({ accomplished, index }) => (
                                                <div style={{ padding: "20px", fontSize: "25px", backgroundColor: "#348bfd" }}
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}
                                                </div>
                                            )}
                                        </Step>
                                        <Step transition="scale" >
                                            {({ accomplished, index }) => (
                                                <div style={{ padding: "20px", fontSize: "25px" }}
                                                    className={`indexedStep ${accomplished ? "accomplished" : null}`}
                                                >
                                                    {index + 1}
                                                </div>
                                            )}
                                        </Step>
                                    </ProgressBar> */}

                                    {/* ======================progressbar========================== */}

                                    <Steps direction="vertical" current={3} status="error">
                                        <Step 
                                            title="Scratch this card Win to Surprise Gift"
                                           
                                            // description="This is the first step on the process of whatever action you're doing."
                                        />
                                        <Step
                                            title="#Content#Scratch this card Win to Surprise Gift"
                                            // description="Second step on this potentially long running task... please be patient"
                                        />
                                        <Step title="#Content#Scratch this card Win to Surprise Gift" 
                                        // description="Processing your data..." 
                                        />
                                        {/* <Step title="Save file" description="Saving your file" /> */}
                                    </Steps>

                                    <p style={{fontSize:"20px"}}>#Content #Content #Content# #Content #Invite your friends #Content #Content #Content #Content #Content #Content</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default RewardsPoints;