import { Icon } from "@iconify/react";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MainMenu from "../MainMenu";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import Site from '../components/assets/img/Site.png'
import Site2 from '../components/assets/img/Site2.png'
import Site3 from '../components/assets/img/Site3.png'
import Site4 from '../components/assets/img/Site4.png'

import { ResponsiveContainer, LineChart, Line, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';

// import CanvasJSReact from '../../assets/js/chart/canvasjs.react';

// ---------------For Graph-------------

// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
// const options = {
//     // title: {
//     //     text: "Basic Column Chart"
//     // },
//     animationEnabled: true,
//     data: [
//     {
//         // Change type to "doughnut", "line", "splineArea", etc.
//         type: "column",
//         width:30,
//         dataPoints: [
//             { label: "Sun",  y: 35  },
//             { label: "Mon",  y: 25  },
//             { label: "Tue",  y: 30  },
//             { label: "Wed",  y: 35  },
//             { label: "Thu",  y: 48  },
//             { label: "Fri",  y: 10  },
//             { label: "Sat",  y: 30  },
//         ]
//     }
//     ]
// }
// ---------------For Graph-------------

const data = [
    { label: 'Sun', Activity: 21, fill: "#C2E2FF" },
    { label: 'Mon', Activity: 35, fill: "#A7D6FF" },
    { label: 'Tue', Activity: 75, fill: "#80C3FF" },
    { label: 'Wed', Activity: 51, fill: "#0087FF" },
    { label: 'Thu', Activity: 41, fill: "#41A5FE" },
    { label: 'Fri', Activity: 12, fill: "#037F26" },
    { label: 'Sat', Activity: 47, fill: "#289AFF" }
];

const ProfileActivities = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Profile Activity</h2>

                    <div class="border">
                        <div className="border m-3 p-2">
                            <ul className="list-group" >
                                <NavLink to="/Home/post"> <li className="list-group-item border-0 fw-bold" style={{ fontSize: "20px" }}>Post <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>

                            </ul>
                        </div>

                        <div className="border m-3 p-2">
                            <h6>Time <span className="text-success">Friday</span> <span style={{ fontSize: "13px" }}>22-January-2022</span></h6>
                            {/* <CanvasJSChart options = {options}></CanvasJSChart> */}
                            <ResponsiveContainer width="100%" className="img-fluid" height={650}>
                                <BarChart data={data} >
                                    <XAxis dataKey="label" />
                                    {/* <YAxis /> */}
                                    {/* <CartesianGrid stroke="#ccc" strokeDasharray="5 5" /> */}

                                    <Tooltip wrapperStyle={{}} />
                                    <Legend wrapperStyle={{ display: "none" }} />
                                    <Bar dataKey="Activity" barSize={30} radius={20} />
                                    {/* <Bar dataKey="leads" fill="#17A8F5" /> */}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div className="border m-3 p-2">
                            <ul className="list-group mb-3" >
                                <NavLink to="/Home/profileactivityvisited"> <li className="list-group-item border-0 fw-bold" style={{ fontSize: "20px" }}>Visited Site <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                <p className="ms-3">recently visited site</p>
                            </ul>

                            <Swiper modules={[Navigation]} className="mySwiper" slidesPerView={6}
                                breakpoints={{
                                    318: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    640: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },

                                    657: {
                                        slidesPerView: 6,
                                        spaceBetween: 0,
                                    }


                                }}

                            >
                                <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img width={200} src={Site2} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site3} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site4} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img width={200} src={Site2} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site3} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site4} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img width={200} src={Site2} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site3} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site4} alt="" />
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                                </SwiperSlide> */}

                            </Swiper>
                        </div>

                        {/* --------------Group--------------- */}
                        <div className="border m-3 p-2">
                            <ul className="list-group mb-3" >
                                <NavLink to="/Home/profileactivitygroup"> <li className="list-group-item border-0 fw-bold" style={{ fontSize: "20px" }}>Groups<span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                            </ul>

                            <Swiper modules={[Navigation]} className="mySwiper" slidesPerView={6}
                                breakpoints={{
                                    318: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    640: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },

                                    657: {
                                        slidesPerView: 6,
                                        spaceBetween: 0,
                                    }


                                }}

                            >
                                <SwiperSlide>
                                    <div className="text-center">
                                        <Icon className="p-2 rounded-circle border border-primary text-primary" fontSize={150} icon="heroicons:user-group" />
                                        <p className="mt-2">Aquib Rahmani</p>
                                    </div>
                                </SwiperSlide>




                                <SwiperSlide>
                                    <div className="text-center">
                                        <Icon className="p-2 rounded-circle border border-primary text-primary" fontSize={150} icon="heroicons:user-group" />
                                        <p className="mt-2">Family</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="text-center">
                                        <Icon className="p-2 rounded-circle border border-primary text-primary" fontSize={150} icon="heroicons:user-group" />
                                        <p className="mt-2">Friends</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="text-center">
                                        <Icon className="p-2 rounded-circle border border-primary text-primary" fontSize={150} icon="heroicons:user-group" />
                                        <p className="mt-2">Public-B</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="text-center">
                                        <Icon className="p-2 rounded-circle border border-primary text-primary" fontSize={150} icon="heroicons:user-group" />
                                        <p className="mt-2">Public-C</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="text-center">
                                        <Icon className="p-2 rounded-circle border border-primary text-primary" fontSize={150} icon="heroicons:user-group" />
                                        <p className="mt-2">Public-D</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="text-center">
                                        <Icon className="p-2 rounded-circle border border-primary text-primary" fontSize={150} icon="heroicons:user-group" />
                                        <p className="mt-2">Aquib</p>
                                    </div>
                                </SwiperSlide>


                            </Swiper>
                        </div>

                        {/* ----------------------Delete-------------------- */}

                        <div className="border m-3 p-2">
                            <ul className="list-group mb-3" >
                                <NavLink to="/Home/profileactivitydelete"> <li className="list-group-item border-0 fw-bold" style={{ fontSize: "20px" }}>Delete <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                <p className="ms-3">recently deleted</p>
                            </ul>

                            <Swiper modules={[Navigation]} className="mySwiper" slidesPerView={6}
                                breakpoints={{
                                    318: {
                                        slidesPerView: 2,
                                        spaceBetween: 0,
                                    },
                                    640: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },

                                    657: {
                                        slidesPerView: 6,
                                        spaceBetween: 0,
                                    }


                                }}

                            >
                                <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img width={200} src={Site2} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site3} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site4} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img width={200} src={Site2} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site3} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site4} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>

                                <SwiperSlide>
                                    <img width={200} src={Site2} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site3} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img width={200} src={Site4} alt="" />
                                </SwiperSlide>
                                {/* <SwiperSlide>
                                    <img width={200} src={Site} alt="" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                                </SwiperSlide> */}

                            </Swiper>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );

}

export default ProfileActivities