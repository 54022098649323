import React from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";

import "../../settingpage/components/assets/css/style.css";
import Logo from "../../landingpage/assets/img/logoblue.png";

import QrcodeImg from "../components/assets/img/Qrcode.png"





import { Icon } from "@iconify/react";
const QrCode = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>

                    </div>

                    <div className="col-md-9 col-12">
                        <h2 className="text-center" style={{ marginTop: "80px" }} >QR Code</h2>
                        <div class="border p-3" style={{ marginBottom: "80px" }}>


                            <div className="p-5 pt-2">
                                <div className="d-flex justify-content-between">

                                    <img width="150" src={Logo} alt="logo" />
                                    <button className="btn btn-primary" >Follow</button>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div className="qrcode mt-3">
                                        <div className="ms-3">
                                            <img width="300" className="m-5 p-3 mb-0 border border-light" src={QrcodeImg} alt="" /> <br />
                                            <h1 className="ms-5 pb-5 text-light">@Nanncyjain23</h1>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <p className="text-center" style={{ fontSize: "25px" }} >LOGIN: https://myttube.com</p>
                                        <div className="d-flex justify-content-between">
                                            <p className="text-center"><Icon fontSize={25} className="text-primary" icon="bi:download" /> <br /> Download QR code</p>
                                            <p className="text-center"><Icon fontSize={25} className="text-primary" icon="fluent:share-48-filled" /> <br /> Share QR code</p>
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default QrCode;

