import { Icon } from "@iconify/react";
import { useState } from "react";
import React from "react";
import { RWebShare } from "react-web-share";

const Post = (prop) => {

    const [like, setLike] = useState(145),
        [isLike, setIsLike] = useState(false),

        onLikeButtonClick = () => {
            setLike(like + (isLike ? -1 : 1));
            setIsLike(!isLike);
        };


    return (
        <>
            {/* <!-- Post --> */}
            <div class="bg-white p-4 rounded shadow mt-3">
                {/* <!-- author --> */}
                <div class="d-flex justify-content-between">
                    {/* <!-- avatar --> */}
                    <div class="d-flex">
                        <img src={prop.UserImg} alt="avatar" class="rounded-circle me-2"
                            style={{ width: "38px", height: "38px", objectFit: " cover" }} />
                        <div>
                            <p class="m-0" style={{ fontSize: "25px", fontWeight: "500" }}>{prop.UserName} <span>
                                <img src={prop.IconImg} alt="Alternate Text" /></span> <span>
                                    <lable class="text-black fw-lighter" style={{ fontSize: "20px" }}>{prop.UserId}</lable>
                                </span>
                            </p>
                        </div>
                    </div>
                    {/* <!-- edit --> */}
                    <i class="fas fa-ellipsis-v" type="button" id="post1Menu" data-bs-toggle="dropdown"
                        aria-expanded="false"></i>
                    {/* <!-- edit menu --> */}
                    <ul class="dropdown-menu border-0 shadow" aria-labelledby="post1Menu">
                        <li class="d-flex align-items-center">
                            <a class=" dropdown-item d-flex justify-content-around align-items-center fs-7 "
                                href="#">Edit Post</a>
                        </li>
                        <li class="d-flex align-items-center">
                            <a class=" dropdown-item d-flex justify-content-around align-items-center fs-7 "
                                href="#">Delete Post</a>
                        </li>
                    </ul>
                </div>
                {/* <!-- post content --> */}
                <div class="mt-3">
                    {/* <!-- content --> */}
                    <div>
                        <p>{prop.PostContent}</p>
                        <img src={prop.PostImg} alt="post image" class="img-fluid rounded w-100" />
                    </div>
                    {/* <!-- likes & comments --> */}
                    <div class="post__comment mt-3 position-relative">
                        {/* <!-- likes --> */}

                        {/* <div class="d-flex align-items-center top-0 start-0 position-absolute"
                        style={{ height: "50px", zIndex: "5" }}>
                            <p class="m-0 text-muted fs-7 d-none d-sm-block"><Icon fontSize={20} className="text-primary" icon="fluent:thumb-like-24-filled" /><span >{like}</span></p>
                        </div> */}

                        {/* <!-- comments start--> */}
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0">
                                {/* <!-- comment collapse --> */}
                                {/* <h2 class="accordion-header" id="headingTwo">
                                    <div class=" accordion-button collapsed pointer d-flex justify-content-end "
                                        data-bs-toggle="collapse" data-bs-target="#collapsePost1" aria-expanded="false"
                                        aria-controls="collapsePost1">
                                        <p class="m-0">2 Comments</p>
                                    </div>
                                </h2> */}
                                {/* <lable>{prop.Comment}</lable> */}
                                {/* <hr /> */}

                                {/* <!-- comment & like bar --> */}
                                <div class="d-flex justify-content-around">
                                    <div class="pointer text-muted p-1 text-center">
                                        <Icon fontSize={35} style={{ color: "gray" }} onClick={onLikeButtonClick} className={"" + (isLike ? "text-primary ball" : "")} icon="fluent:thumb-like-24-filled" />
                                        <p className="mt-3">Like <br />{like}</p>

                                    </div>

                                    <div class="pointer text-muted p-1 text-center"
                                        data-bs-toggle="collapse" data-bs-target="#collapsePost1" aria-expanded="false"
                                        aria-controls="collapsePost1">
                                        <Icon fontSize={35} style={{ color: "gray" }} icon="fluent:comment-edit-20-regular" />
                                        <p className="mt-3">Comment <br />255</p>
                                    </div>

                                    <div class="pointer text-muted p-1 text-center">
                                        <RWebShare data={{ title: "Share everywhere" }}>
                                            <Icon fontSize={35} style={{ color: "gray" }} icon="fluent:share-20-filled" />
                                        </RWebShare>
                                        <p className="mt-3">Share <br />97</p>
                                    </div>

                                    <div class="pointer text-muted p-1 text-center">
                                        <Icon fontSize={35} style={{ color: "gray" }} icon="emojione-monotone:wrapped-gift" />
                                        <p className="mt-3">Gift <br />43</p>
                                    </div>

                                    <div class="pointer text-muted p-1 text-center">
                                        <p style={{ color: "gray", fontSiz: "40px" }}>500k+ Views</p>

                                    </div>
                                    <div class="pointer text-muted p-1 text-center">
                                        <Icon fontSize={35} style={{ color: "gray" }} icon="bi:bookmark-star" />
                                        <p className="mt-3">Save</p>
                                    </div>
                                </div>

                                {/* <!-- comment expand --> */}
                                <div id="collapsePost1" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample">
                                    <hr />
                                    <div class="accordion-body">
                                        {/* <!-- comment 1 --> */}
                                        <div class="d-flex align-items-center my-1">
                                            {/* <!-- avatar --> */}
                                            <img src={prop.CommentImg1} alt="avatar"
                                                class="rounded-circle me-2" style={{ width: "38px", height: "38px", objectFit: "cover" }} />
                                            {/* <!-- comment text --> */}
                                            <div class="p-3 rounded comment__input w-100">
                                                {/* <!-- comment menu of author --> */}
                                                <div class="d-flex justify-content-end">
                                                    {/* <!-- icon --> */}
                                                    <i class="fas fa-ellipsis-h text-blue pointer" id="post1CommentMenuButton"
                                                        data-bs-toggle="dropdown" aria-expanded="false"></i>
                                                    {/* <!-- menu --> */}
                                                    <ul class="dropdown-menu border-0 shadow" aria-labelledby="post1CommentMenuButton">
                                                        <li class="d-flex align-items-center">
                                                            <a class=" dropdown-item d-flex justify-content-around align-items-center fs-7"

                                                                href="#">Edit Comment</a>
                                                        </li>
                                                        <li class="d-flex align-items-center">
                                                            <a class=" dropdown-item d-flex justify-content-around align-items-center fs-7 "
                                                                href="#">Delete Comment</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <p class="fw-bold m-0">{prop.CommentUser1}</p>
                                                <p class="m-0 fs-7 bg-gray p-2 rounded">
                                                    {prop.CommentContent1}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <!-- comment 2 --> */}
                                        <div class="d-flex align-items-center my-1">
                                            {/* <!-- avatar --> */}
                                            <img src={prop.CommentImg2} alt="avatar" class="rounded-circle me-2"
                                                style={{ width: "38px", height: "38px", objectFit: "cover" }} />
                                            {/* <!-- comment text --> */}
                                            <div class="p-3 rounded comment__input w-100">
                                                <p class="fw-bold m-0">{prop.CommentUser2}</p>
                                                <p class="m-0 fs-7 bg-gray p-2 rounded">
                                                    {prop.CommentContent2}
                                                </p>
                                            </div>
                                        </div>
                                        {/* <!-- create comment --> */}
                                        <form class="d-flex my-1">
                                            {/* <!-- avatar --> */}
                                            <div>
                                                <img src={prop.CommentImg3} alt="avatar"
                                                    class="rounded-circle me-2" style={{ width: "38px", height: "38px", objectFit: "cover" }} />
                                            </div>
                                            {/* <!-- input --> */}
                                            <input type="text" class="form-control border-0 rounded-pill bg-gray"
                                                placeholder="Write a comment" />
                                        </form>
                                        {/* <!-- end --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end --> */}
                    </div>
                </div>
            </div>
            {/* <!-- Post End--> */}
        </>
    );
};

export default Post; 