import { Icon } from "@iconify/react";
import React from "react";
import { NavLink } from "react-router-dom";
import ImgUser from "../../landingpage/assets/img/Group 785.png";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";

const BlockFollowers = () => {
    return (
        <>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="sidebar1">

                            <div className="sd-header1">
                                <h4 className="mb-0">Privacy</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link " to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/language"><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/ads" ><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/privacy" ><SideDrop IconClass="text-light" MenuClass="text-light m-0" ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-5 col-12 mx-auto col-lg-6 mb-3" style={{ marginTop: "80px" }}>
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>

                            <div class="input-group mt-4">
                                <span class="input-group-text" id="basic-addon1"><Icon  fontSize="25px" opacity=".4" icon="ic:baseline-search" /></span>
                                <input type="text" class="form-control" placeholder="e.g. Profile Name" />

                            </div>

                            <ul className="list-group profilelist" style={{ marginBottom: "80px" }}>
                                       

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1 </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>

                                            </div>

                                        </li>
                                      
                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                            </div>

                                        </li>

                                        <li className="rounded list-group-item border-0 ">

                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1 </span>
                                                 <button className="btn btn-primary bg-primary float-end mt-2 btn-lg" style={{width:"150px"}}>Block</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>

                                            </div>

                                        </li>
                                    </ul>


                        </div>
                    </div>
                    {/* ===================================Tag End=========================================================== */}



                </div>
            </div>

        </>

    );

}

export default BlockFollowers;
