import React from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";
import ImgUser from "../../landingpage/assets/img/Group 785.png";
import "../../settingpage/components/assets/css/style.css"


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Icon } from "@iconify/react";
const Friend = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>

                    </div>

                    <div className="col-md-9 col-12">
                        <h2 className="text-center" style={{ marginTop: "80px" }} >Friends</h2>
                        <div class="border p-3" style={{ marginBottom: "80px" }}>

                            {/* <button className="btn btn-primary float-end me-2">Create Group</button> */}
                            <Tabs defaultIndex={0} className="mt-2">
                                <TabList className="d-flex justify-content-between w-75 mx-auto">
                                    <Tab className="btn border-bottom-0" >Friends</Tab>
                                    <Tab className="btn border-bottom-0" >Search</Tab>
                                </TabList>
                                <TabPanel>




                                    <ul className="list-group profilelist ">
                                        {/* <div className="d-flex justify-content-between px-4">
                                            <p>Recent viewed</p>
                                            <p className="text-primary"><Icon color="#333333" fontSize={25} icon="ic:baseline-delete" />Clear all</p>
                                        </div> */}



                                        <li className="rounded list-group-item border-0 ">
                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button type="submit" className="btn btn-primary bg-primary float-end mt-3 " style={{ width: "100px" }}>Remove</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0 ">
                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button type="submit" className="btn btn-primary bg-primary float-end mt-3 " style={{ width: "100px" }}>Remove</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0 ">
                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button type="submit" className="btn btn-primary bg-primary float-end mt-3 " style={{ width: "100px" }}>Remove</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0 ">
                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button type="submit" className="btn btn-primary bg-primary float-end mt-3 " style={{ width: "100px" }}>Remove</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0 ">
                                            <div class="p-2">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <button type="submit" className="btn btn-primary bg-primary float-end mt-3 " style={{ width: "100px" }}>Remove</button>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                    </ul>








                                </TabPanel>
                                <TabPanel>



                                    <ul className="list-group ">

                                        <div className="d-flex justify-content-between">
                                            <div class="input-group mt-4">
                                                <span class="input-group-text" id="basic-addon1"><Icon fontSize="25px" opacity=".4" icon="ic:baseline-search" /></span>
                                                <input type="text" class="form-control" placeholder="Search..." />
                                            </div>
                                            <div>
                                                <button className="btn btn-primary mt-4 ms-2">Done</button>
                                            </div>
                                        </div>

                                        <li className="rounded list-group-item border-0">
                                            <div class="p-2 px-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <input class="form-check-input float-end mt-3 rounded-circle border-primary" style={{ fontSize: "25px" }} id="customCheck6" type="checkbox"></input>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0">
                                            <div class="p-2 px-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <input class="form-check-input float-end mt-3 rounded-circle border-primary" style={{ fontSize: "25px" }} id="customCheck6" type="checkbox"></input>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0">
                                            <div class="p-2 px-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <input class="form-check-input float-end mt-3 rounded-circle border-primary" style={{ fontSize: "25px" }} id="customCheck6" type="checkbox"></input>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0">
                                            <div class="p-2 px-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <input class="form-check-input float-end mt-3 rounded-circle border-primary" style={{ fontSize: "25px" }} id="customCheck6" type="checkbox"></input>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0">
                                            <div class="p-2 px-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <input class="form-check-input float-end mt-3 rounded-circle border-primary" style={{ fontSize: "25px" }} id="customCheck6" type="checkbox"></input>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0">
                                            <div class="p-2 px-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <input class="form-check-input float-end mt-3 rounded-circle border-primary" style={{ fontSize: "25px" }} id="customCheck6" type="checkbox"></input>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>
                                        <li className="rounded list-group-item border-0">
                                            <div class="p-2 px-3">
                                                <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1 mt-2" />
                                                <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                                <input class="form-check-input float-end mt-3 rounded-circle border-primary" style={{ fontSize: "25px" }} id="customCheck6" type="checkbox"></input>
                                                <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>profile name</p>
                                            </div>
                                        </li>

                                    </ul>






                                </TabPanel>
                            </Tabs>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Friend;

