import CongratulationImg from "../business/img/congrats.svg"

const CongratulationPage = () => {
    return (
        <div className="container mt-5">
            <div className="text-center mt-5 justify-content-center">
                <img src={CongratulationImg} alt="" />
                <h3 className="text-success py-4">Congratulations,</h3>
                <h4 >Your Request is <span className="text-primary">Successfully Submitted</span></h4>
                <h4>We will connect shortly </h4>
            </div>
        </div>
    )
}
export default CongratulationPage;