import React from "react";
import { NavLink } from "react-router-dom";
import MainMenu from "../MainMenu";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Icon } from "@iconify/react";
import ImgUser from "../../landingpage/assets/img/Group 785.png";
import DigitalImg from "../components/assets/img/Group.png";

const DG = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Digital Collections</h2>
                    <div class="border p-3" >

                        <Tabs defaultIndex={0} className="mt-2">
                            <TabList className="d-flex justify-content-between w-75 mx-auto">
                                <Tab className="btn shadow" >Received</Tab>
                                <Tab className="btn shadow" >Sent</Tab>
                            </TabList>

                            <TabPanel>
                                <ul className="list-group profilelist mt-5 mb-5">
                                    

                                    <NavLink to="/Home/rewards" >
                                        <li className="rounded list-group-item border-0 ">
                                            <Icon fontSize="45px" className="text-primary me-3" icon="arcticons:rewards" />Rewards from <span style={{ fontFamily: "Satisfy", color: "#0d6efd" }}> myttube’s </span>Sponshored partners <Icon fontSize="45px " color="#0d6efd" className="float-end" icon="material-symbols:arrow-right-rounded" />
                                        </li>
                                    </NavLink>

                                    <NavLink to="/Home/receivedgift" >
                                        <li className="rounded list-group-item border-0 ">
                                            <Icon fontSize="45px" className="text-primary me-3" icon="emojione-monotone:wrapped-gift" />Received Gift from Fans/Followers<Icon fontSize="45px " color="#0d6efd" className="float-end" icon="material-symbols:arrow-right-rounded" />
                                        </li>
                                    </NavLink>


                                    <NavLink to="/Home/collectpoints" >
                                    <li className="rounded list-group-item border-0 ">
                                        <Icon fontSize="45px" className="text-primary me-3" icon="fluent:gift-card-16-regular" />Collect Points from myttube<span style={{ fontFamily: "Satisfy", color: "#0d6efd" }}> myttube’s </span> <Icon fontSize="45px " color="#0d6efd" className="float-end" icon="material-symbols:arrow-right-rounded" />

                                    </li>
                                    </NavLink>
                                </ul>



                                <div className="px-4 mt-5 mb-5">
                                    <p>Invite your friends Win surprise gift
                                        <button className="btn btn-outline-primary float-end btn-lg shadow"><Icon fontSize="25px" icon="carbon:friendship" /> Invite</button>  </p>
                                    <center>
                                        <img width="550" src={DigitalImg} className="img-fluid" alt="" />
                                    </center>
                                </div>




                            </TabPanel>
                            <TabPanel>


                            </TabPanel>
                        </Tabs>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default DG;