import { Icon } from "@iconify/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";

const Help = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="sidebar1">
                            <div className="sd-header1">
                                <h4 className="mb-0">Help</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link " to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/language"><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/ads" ><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/security"><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/help" ><SideDrop IconClass="text-light" MenuClass="text-light m-0" ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile" ><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 col-12 col-lg-6 mx-auto mb-3" style={{ marginTop: "80px" }}>
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>

                            <p className="mt-3 fw-bold " style={{ lineHeight: "0px" }}>Help </p>
                            <div className="border p-3">
                               

                                <aside id="info-block">
                                    <section class="file-marker">
                                        <div>
                                            <div class="box-title">
                                            connect with us
                                            </div>
                                            <div className="row p-3">

                                                <p>help@myttube.com <button className="btn btn-outline-primary float-end " style={{ width: "150px" }} >Email</button> </p>
                                                <p>+91-8000080000 <button className="btn btn-outline-primary float-end " style={{ width: "150px" }} >Call</button> </p>
                                            </div>

                                            {/* <ul className="list-group" >
                                                        <NavLink className="pb-2" to="/Home/blockpeoplecomment"> <li className="list-group-item border-0">People <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                                        <NavLink className="pb-2" to="/Home/blockfollowerscomment"> <li className="list-group-item border-0">Followers <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                                        <NavLink className="pb-2" to="/Home/blockfollowingcomment"> <li className="list-group-item border-0">Following <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                                    </ul> */}
                                        </div>
                                    </section>
                                </aside>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Help;