
import "../slider/OrbitSingleSlider.css"
import orbit from "../slider/img/orbit.png";
import orbit1 from "../slider/img/orbit1.png";
import orbit2 from "../slider/img/orbit2.png";
import orbit3 from "../slider/img/orbit3.png";
import orbit4 from "../slider/img/orbit4.png";
import orbit5 from "../slider/img/orbit5.png";
import orbit6 from "../slider/img/orbit6.png";
import orbit7 from "../slider/img/orbit7.png";
import orbit8 from "../slider/img/orbit8.png";
import orbit9 from "../slider/img/orbit9.png";

const OrbitSingleSlider = () => {
    return (
        <>
            <div id="circle-Singleorbit-container">
                <div id="outer-Singleorbit">
                    <div class="outer-Singleorbit-cirlces"> <img src={orbit5} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="outer-Singleorbit-cirlces1"></div>
                    <div class="outer-Singleorbit-cirlces2"><img src={orbit6} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="outer-Singleorbit-cirlces3"></div>
                    <div class="outer-Singleorbit-cirlces4"><img src={orbit7} style={{ width: "30px", height: "30px", rotate: "180deg" }} alt="..." /></div>
                    <div class="outer-Singleorbit-cirlces6"><img src={orbit8} style={{ width: "20px", height: "20px", rotate: "-20deg" }} alt="..." /></div>
                    <div class="outer-Singleorbit-cirlces5"><img src={orbit9} style={{ width: "30px", height: "30px", rotate: "-20deg" }} alt="..." /></div>
                </div>
            </div>
        </>
    )
}

export default OrbitSingleSlider;