import React from "react";

const BlogCard = (prop) => {
    return (
        <>


            <div class="card" style={{ borderRadius: "20" }}>
                <img src={prop.Img} class="img-fluid" /* style={{ opacity: "0.8" }}  */ alt="" />
                <div class="card-body">
                    <p class="card-title text-light" style={{ position: "absolute", top: "8px", left: "16px", fontSize: "50px", }}>{prop.title}</p>
                    <div className="container" >
                        <div className="row" >
                            <div className="col-md-6 col-12 w-50" style={{ position: "absolute", bottom: "58px", left: "16px" }}>
                                <p class="card-text text-light text-justify" >{prop.content}</p>
                            </div>
                            <div className="col-md-6 col-12" >
                                <button className="btn btn-sm btn-outline-light" style={{ position: "absolute", bottom: "58px", right: "36px" }}> Read More</button>
                            </div>
                        </div>
                        <p class="card-text text-center text-justify" >{prop.Date}</p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default BlogCard;
