import React from "react";
import MainMenu from "../MainMenu";

import ImgUser from "../../landingpage/assets/img/Group 785.png";
import { Icon } from "@iconify/react";


const CollectPointsFromfollowers = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Collect Points from <span style={{ fontFamily: "Satisfy", color: "#0087FF" }}>myttube</span></h2>
                    <div class="border p-3">
                        <p style={{ fontSize: "25px" }}>Hey @profile username  <br />
                            <span style={{ fontSize: "16px" }}>Your reward points details is below</span></p>

                        <div className="pointscard img-fluid " style={{ height: "300px" }} >
                            
                            <div className="p-2">
                                <p style={{ marginTop: "-10px", marginLeft: "150px" }}>Today 26 January 2022 <span style={{ marginLeft: "100px", fontSize: "25px" }}>January</span></p>
                                <p style={{ marginLeft: "150px", fontSize: "25px" }}>YOUR RANK <span className="fw-bold">LEVEL-0</span></p>

                                <div className="d-flex justify-content-between ms-3" style={{ width: "35%", marginTop: "25px", fontSize: "25px" }}>
                                    <p>VI-1</p>
                                    <p>LK-2</p>
                                    <p>SR-3</p>
                                    <p>VISIT</p>
                                </div>
                                <div className="d-flex justify-content-between ms-3 fw-bold" style={{ width: "35%", fontSize: "25px", lineHeight: "1" }}>
                                    <p>00013</p>
                                    <p>0022</p>
                                    <p>0013</p>
                                    <p>0003</p>
                                </div>
                                <h5 className="ps-3">@Profile_Username</h5>
                                <div className="d-flex justify-content-between ms-3" style={{ width: "35%", marginTop: "15px" }}>

                                    <p style={{ fontSize: "13px", fontWeight: "450", marginTop: "15px" }}>*NOTE: you not collect yet, any gift from myttube</p>
                                    <p style={{ fontSize: "25px", fontWeight: "450" }} className="float-end">See More</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default CollectPointsFromfollowers;


