import React from "react";
import profile from "../creator/img/profile.png";

const CreatorCard = (prop) => {
  return (
    <>
      <div class="card shadow" style={{ width: "18rem" }}>
        <img src={prop.cardImg} class="card-img-top" alt="" />
        <div class="card-body">
          {/* <h5 class="card-title">Card title</h5> */}
          <p class="card-text fs-7">{prop.hashtag}</p>

          <div className="d-flex justify-content-between mb-0">
            <p className="fs-7"> <img src={profile} alt="profileImg" width={30} />  @aquibrahmani143 </p>
            <button className="btn btn-sm btn-primary h-25 ">Follow me</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreatorCard;
