import zIndex from "@mui/material/styles/zIndex";
import React from "react";


const CareerHeading = (prop) => {

    return (
        <>
            <h6 class="text-center mt-5 pt-5 pb-2 text-light" style={{ fontWeight: "400", fontSize: "45px" }}> {prop.Heading}</h6>

        </>

    );

}


const CareerHeadingSecond = (prop) => {

    return (
        <>

            <h6 class="display-4 text-center mt-5 pb-2 text-light">{prop.HeadingSecond}</h6>
        </>

    );

}

const CareerHeadingThird = (prop) => {

    return (
        <>
            <h1 class="m-sm-5 "><a href="">{prop.ContentLink}
                <br />
                {prop.Content}</a></h1>

        </>

    );

}

const CareerViewer = (prop) => {
    return (
        <>

            <div className="card">
                <img src={prop.CardImg2} class="col-sm-12 mx-auto d-block shadow rounded-5 img-fluid" style={{ width: "350px" }} alt="" />
                <div className="card-body">
                    <div className="container" >
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <p className="card-title text-light w-50" style={{ position: "absolute", bottom: "80px", left: "16px", fontSize: "30px" }}>{prop.title}</p>
                            </div>
                            <div className="col-md-6 col-12 " style={{ position: "absolute", bottom: "60px", left: "210px" }}>
                                <button className="btn btn-outline-light shadow btn-sm px-4" >{prop.UserId}Read More </button>
                            </div>
                        </div>

                        <p className="card-text text-center" style={{ fontSize: "10px" }}>{prop.date}</p>
                    </div>
                </div>
            </div>
        </>
    );
}



const CareerCardSlider1 = (prop) => {
    return (
        <>

            <div className="card bg-transparent mb-4 pb-2" >
                <img src={prop.CardImg} class="card-img-top mx-auto d-block" style={{ width: "100px", transform: "translateY(40%)", zIndex: "1" }} alt="" />
                <div class="card border border-top-0 shadow pt-5" style={{ 'borderRadius': '20px' }}>
                    <img src={prop.CardImg2} class="mx-auto d-block img-flud" style={{ width: "250px" }} alt="prize" />
                    <div class="card-body">
                        <div class="px-3" >
                            <h5 className="text-start text p-1 ">{prop.AboutHeading}</h5>
                            <p class="text-justify p-1" style={{ fontSize: "13px" }}>{prop.CardContent}</p>
                            <center><button class="btn btn-outline-primary bg-white text-primary shadow" >{prop.CardBtn} <span><i class="fa fa-share-square-o float-end mt-1 me-1 mx-4" aria-hidden="true"></i></span> </button></center>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const AbooutCardSlider = (prop) => {
    return (
        <>
            <div class="card border border-top-0 shadow-sm m-2 mt-2" style={{ 'borderRadius': '20px' }}>
                <img src={prop.CardImg} class="card-img-top mx-auto d-block my-4" style={{ width: "100px" }} alt="" />
                <center><hr style={{ border: "2px solid black", width: "80%" }} /></center>
                <div class="card-body">
                    <div class="mt-3" >
                        <h5 className="text-start p-1 fw-bold">{prop.AboutHeading}</h5>
                        <p class="text-justify px-3" style={{ fontSize: "13px" }}>{prop.CardContent}</p>
                        <button class="btn btn-outline-primary bg-white text-primary shadow btn-sm float-end" >{prop.CardBtn} <span><i class="fa fa-share-square-o float-start mt-1 me-1" aria-hidden="true"></i>  </span> </button>

                    </div>
                </div>
            </div>
        </>
    );
}

const BlogBrandSlider = (prop) => {
    return (
        <>
            <img src={prop.CardImg} class="card-img-top mx-auto d-block" style={{ width: "300px" }} alt="..." />
        </>
    );
}


export default CareerHeading;
export { CareerHeadingSecond };
export { CareerHeadingThird };
export { CareerViewer };
export { CareerCardSlider1 };
export { AbooutCardSlider };
export { BlogBrandSlider };
