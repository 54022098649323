import React from "react";

const Advertise = (prop) => {
    return(
        <div class="bg-white p-4 rounded shadow mt-3">
        <div class="d-flex justify-content-between">
            {/* <!-- avatar --> */}
            <div class="d-flex mb-2">
                <img src={prop.BrandLogo} alt="avatar" class="rounded-circle me-2"
                    style={{width: "38px", height: "38px", objectFit: "cover"}} />
                <div>
                    
                    <p class="m-0 " style={{fontSize: "25px", fontWeight: "500"}}><span className="text-primary">{prop.BrandName}</span> <span className="text-danger">{prop.BrandName1}</span> <span class="text-muted fw-lighter;" style={{fontSize: "20px"}}>sponsered</span></p>
                    {/* <p class="m-0 " style={{fontSize: "25px", fontWeight: "500"}}><span className="text-danger ">{prop.BrandName1}</span> </p> */}
                </div>
            </div>
            {/* <!-- edit --> */}
            <i class="fas fa-ellipsis-v" type="button" id="post1Menu" data-bs-toggle="dropdown"
                aria-expanded="false"></i>
            {/* <!-- edit menu --> */}
            <ul class="dropdown-menu border-0 shadow" aria-labelledby="post1Menu">
                <li class="d-flex align-items-center">
                    <a class=" dropdown-item d-flex justify-content-around align-items-center fs-7"
                        href="#">Edit Post</a>
                </li>
                <li class="d-flex align-items-center">
                    <a class="dropdown-item d-flex justify-content-around align-items-center fs-7"
                        href="#">Delete Post</a>
                </li>
            </ul>
        </div>
        <img src={prop.BrandImg} class="img-fluid" alt="Alternate Text" />
    </div>
    );
};
export default Advertise;