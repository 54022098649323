import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react'

import CarouselSlider from "./CarouselSection";
import Slider1 from "../../landingpage/assets/img/Sponsored1.png"
import Slider2 from "../../landingpage/assets/img/Sponsored2.png"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";



// import required modules
import { Pagination, Navigation } from "swiper";


const Carousel = () => {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper mySwiper1"
            >


                <SwiperSlide>
                    <CarouselSlider Img1={Slider1} Img2={Slider2} />
                </SwiperSlide>

                <SwiperSlide>
                    <CarouselSlider Img1={Slider1} Img2={Slider2} />
                </SwiperSlide>

                <SwiperSlide>
                    <CarouselSlider Img1={Slider1} Img2={Slider2} />
                </SwiperSlide>

            </Swiper>

        </>
    );
};
export default Carousel;