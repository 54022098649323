import React from "react";

const ViewerHeading = (prop) => {
    return (
        <>
            <h1 class="text mt-5 pt-5 pb-2" style={{ fontWeight: "400", fontSize: "45px" }} >{prop.Heading}
                <br />
                {prop.HeadingSecond}
            </h1>
        </>
    );


}
const ViewerHeadingsecond = (prop) => {
    return (
        <>

            <h1 class="text">{prop.HeadindThird}<br />
                {prop.HeadingForth}</h1>

        </>
    );
}

const ViewerPara = (prop) => {
    return (
        <>
            <p style={{ fontSize: "20px" }} > {prop.Para} </p>
        </>
    );
}

const ViewerBtn = (prop) => {
    return (
        <>
            <button type="button" class="btn btn-outline-primary px-5 float-end shadow" style={{ borderRadius: "30px", height: "50px" }} >{prop.More}</button>

        </>

    );

}
const ViewerCard = (prop) => {
    return (
        <>

            <div class="card">
                <img src={prop.CardImg} class="card-img-top" alt="..." />
                <div class="card-body viewercardbody">
                    <button class="btn btn-outline-primary w-100 bg-white text-primary shadow" style={{ borderRadius: "30px" }}>{prop.CardBtn} <span><i class="fa fa-arrow-right float-right mt-1" aria-hidden="true"></i></span></button>
                    <div class="mt-3" style={{ border: "2px solid black", borderRadius: "10px" }}>
                        <p class="text-justify p-1" style={{ fontSize: "13px" }}>{prop.CardContent}</p>
                    </div>
                </div>
            </div>


        </>
    );
}

const ViewerImg =(prop) => {
    return ( 
        <>
         <img class="img-fluid" src={prop.Img} alt="Image"></img>
        </>
    );
}

const ViewerParaSecond=(prop)=> {
    return(
        <>
          <p class="text-justify p-3">
                  {prop.ParaSecond}
                </p>
        </>
    )
}
const ViewerBtnSecond=(prop)=> {
    return(
        <>
        <button class="btn btn-outline-primary p-2 pe-4 ps-4 mx-3" style={{borderRadius:"30px"}}>{prop.MoreSecond}</button>
        </>
    )
}


export default ViewerHeading;
export { ViewerPara };
export { ViewerBtn };
export { ViewerHeadingsecond };
export { ViewerCard };
export {ViewerImg};
export {ViewerParaSecond};
export {ViewerBtnSecond};