import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";


import StoryNew from "./StoryNew";
import img1 from '../assets/img/Group 785.png'

const Story = () => {



    return (
        <>


            <Swiper navigation={true} modules={[Navigation]} className="mySwiper" slidesPerView={5}
                breakpoints={{
                    318: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                    },

                    657: {
                        slidesPerView: 5,
                        spaceBetween: 0,
                    }


                }}>


                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>
                <SwiperSlide>
                    <StoryNew data={{ imgSrc: img1, price: 'Aquib Rahmani', title: 'Website Devloper' }} />
                </SwiperSlide>



            </Swiper>

        </>





    );

};

export default Story;