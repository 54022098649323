import { Icon } from "@iconify/react";
import React from "react";



const SideBar = (prop) => {
    return (
        <>
            <li class="dropdown-item p-1 rounded">
                <a href="#" class="
    d-flex
    align-items-center
    text-decoration-none text-dark">
                    <div class="p-1">
                        <img src={prop.Img} alt="avatar"
                            class="rounded-circle me-2 border border-primary border-1" style={{ width: "38px", height: "38px", objectFit: "cover" }} />
                        <span style={{ fontSize: "12px", fontWeight: "500" }}> {prop.Name} </span>
                    </div>
                    <span style={{ fontSize: "10px", fontWeight: "200" }}>{prop.UserId}</span>
                    <div>
                        <span class="ms-5 text-primary"><small>{prop.Follow}</small></span>
                        {/* <%-- <p class="m-0">John </p>--%> */}
                    </div>
                </a>
            </li>
        </>
    );
}

const SideDrop = (prop) => {
    return (
        <>
            <li class="dropdown-item p-1 rounded">
                <a class="d-flex align-items-center text-decoration-none text-dark">
                    <div class="p-2">
                        <Icon width="20" className={prop.IconClass} icon={prop.ItemIcon} rotate={prop.rotate} />

                        {/* <img src={prop.SideIcon} width="20" alt="" /> */}
                    </div>
                    <div class="pt-1 mx-2">
                        <p style={{ margin: "0" }} className={prop.MenuClass}>{prop.MenuName}</p>
                    </div>
                </a>
            </li>
        </>

    );
}

const SideSponsored = (prop) => {

    const mystyle = {
        width: "300px",
        height: "200px",
        padding: "0px",
        overflow: "auto"
    };

    return (
        <>
            <div class="sponsored-img" style={mystyle}>
                <img src={prop.SponsoredImg} alt="ads"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }} class="rounded" />
            </div>

        </>
    );
}



export default SideBar;
export { SideDrop };
export { SideSponsored };