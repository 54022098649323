import React from "react";

const NoPage = () => {
    return (
        <>
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h3 className="text">Oops! Page not found</h3>
                        <h1><span>4</span><span>0</span><span>4</span></h1>
                    </div>
                    <h2 className="text">we are sorry, but the page you requested was not found</h2>
                        <button class="btn-1 btn-created btn btn-lg px-5 float-right" > <a href="login" class="text-light">HOMEPAGE</a></button>
                </div>
            </div>

          
        </>

    );
}

export default NoPage;