// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Navbar';
import Home from './page/Home';
import './assets/css/Style.css';

import './assets/css/Navbar.css';
import './page/slider/MobileFrame.css';
import './page/form/Login.css';
import '../src/page/slider/Sponsered.css';
import '../src/page/form/blog/Blog.css';
import '../src/page/form/company/Company.css';
import './assets/css/Footer.css';
import '../src/page/form/creator/Creator.css';
import "../src/page/form/contact/Contact.css"
import "../src/page/form/investor/Investor.css"
import "../src/page/form/Career/Career.css";
import "../src/page/form/business/Business.css";
import "../src/page/form/404Page/NoPage.css";
import "../src/page/form/viewers/Viewers.css";
import "../src/page/Home.css";
import "../src/page/Landing.css";
import "../src/page/slider/LandingFrame.css";

import AboutUs from './page/form/about/AboutUs.jsx';
import Blog from './page/form/blog/Blog.jsx';
import Creator from './page/form/creator/Creator.jsx';
import Company from './page/form/company/Company.jsx';
import Contact from './page/form/contact/Contact.jsx';

import Investor from './page/form/investor/Investor.jsx';
import Career from './page/form/Career/Career.jsx';
import Business from './page/form/business/Business.jsx';
import NoPage from './page/form/404Page/NoPage.jsx';
import Viewers from './page/form/viewers/Viewers.jsx';

import BuildCommunity from './page/form/innerform/BuildCommunity.jsx';
import ProgramContent from './page/form/innerform/ProgramContent.jsx';
import MakeMoney from './page/form/innerform/MakeMoney.jsx';
import GrowingPage from './page/form/innerform/GrowingPage.jsx';
import PoliciesGuidelines from './page/form/innerform/PoliciesGuidelines.jsx';
import HowToGetStart from './page/form/innerform/HowToGetStart.jsx';

import Appbar from './landingpage/Appbar.jsx';
import LandingPage from './landingpage/LandingPage.jsx';

import Reg from './page/form/registration/Registration.jsx';

import Notification from './settingpage/components/Notification.jsx';
import Monetize from './settingpage/components/Monetize.jsx';
import Language from './settingpage/components/Language.jsx';
import ChatBox from './settingpage/components/ChatBox.jsx';
import Ads from './settingpage/components/Ads.jsx';
import Verification from './settingpage/components/Verification.jsx';
import Privacy from './settingpage/components/Privacy.jsx';
import Security from './settingpage/components/Security.jsx';
import About from './settingpage/components/About.jsx';
import Help from './settingpage/components/Help.jsx';
import SwitchProfile from './settingpage/components/SwitchProfile.jsx';
import ProfilePage from './settingpage/components/Profile.jsx';
import Permission from './settingpage/components/Permission.jsx';
import RegMessage from './page/form/registration/Regmassage.jsx';

import BlockPeople from './settingpage/components/BlockPeople.jsx';
import BlockFollowers from './settingpage/components/BlockFollowers.jsx';
import ProfileActivities from './settingpage/components/ProfileActivity.jsx';
import Post from './landingpage/components/PostSection.jsx';
import AllPosts from './settingpage/components/AllPost.jsx';
import DG from './settingpage/components/DigitalCollections.jsx';
import MyttubeRewards from './settingpage/components/Rewards.jsx';
import RewardsPoints from './settingpage/components/RewardsPoints.jsx';
import ProfileActivityVisited from './settingpage/components/ProfileActivityVisited.jsx';
import ProfileActivityDelete from './settingpage/components/ProfileActivityDelete.jsx';
import ProfileActivityGroup from './settingpage/components/ProfileActivityGroup.jsx';
import ReceivedGiftFromfollowers from './settingpage/components/ReceivedGift.jsx';
import CollectPointsFromfollowers from './settingpage/components/CollectPoints.jsx';
import Archive from './settingpage/components/Archive.jsx';
import FavoritesProfile from './settingpage/components/FavoritesProfile.jsx';
import Friend from './settingpage/components/Friend.jsx';
import QrCode from './settingpage/components/QrCode.jsx';
import History from './settingpage/components/History.jsx';
import Saved from './settingpage/components/Saved.jsx';
import ProfileDetails from './profilepage/Profile.jsx';
import TestApi from './TestApi.jsx';
import BusinessJoin from './page/form/business/BusinessJoin.jsx';
import CongratulationPage from './page/form/business/CongratulationPage.jsx';
import Group from './page/form/group/Group.jsx';
import User from './page/form/user/User.jsx';
import ScrollToTop from './ScrollTop.jsx';
import Landing from './page/Landing.jsx';


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
          <Route index element={<Landing />} />
        <Route path="/" element={<Navbar />}>
          <Route path="/landing" element={<Landing />} />
          <Route path="/login" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/company" element={<Company />} />
          <Route path="/creator" element={<Creator />} />
          <Route path="/career" element={<Career />} />
          <Route path="/investor" element={<Investor />} />
          <Route path="/business" element={<Business />} />
          <Route path="/businessjoin" element={<BusinessJoin />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/viewers" element={<Viewers />} />
          <Route path="/group" element={<Group />} />
          <Route path="/congratulationPage" element={<CongratulationPage />} />

          <Route path="/buildcommunity" element={<BuildCommunity />} />
          <Route path="/programcontent" element={<ProgramContent />} />
          <Route path="/makemoney" element={<MakeMoney />} />
          <Route path="/growingpage" element={<GrowingPage />} />
          <Route path="/policiesguidelines" element={<PoliciesGuidelines />} />
          <Route path="/howtogetstart" element={<HowToGetStart />} />

          <Route path="/registration" element={<Reg />} />
          <Route path="/regmsg" element={<RegMessage />} />
          <Route path="/test" element={<TestApi />} />

          <Route path="/user" element={<User />} />

          <Route path="/*" element={<NoPage />} />
        </Route>

        <Route path="/Home" element={<Appbar />}>
          <Route index element={<LandingPage />} />
          <Route path="/Home/profilepage" element={<ProfilePage />} />
          <Route path="/Home/notification" element={<Notification />} />
          <Route path="/Home/permission" element={<Permission />} />
          <Route path="/Home/monetize" element={<Monetize />} />
          <Route path="/Home/language" element={<Language />} />
          <Route path="/Home/chatbox" element={<ChatBox />} />
          <Route path="/Home/ads" element={<Ads />} />
          <Route path="/Home/verification" element={<Verification />} />
          <Route path="/Home/privacy" element={<Privacy />} />
          <Route path="/Home/security" element={<Security />} />
          <Route path="/Home/about" element={<About />} />
          <Route path="/Home/help" element={<Help />} />
          <Route path="/Home/switchprofile" element={<SwitchProfile />} />
          <Route path="/Home/blockpeoplecomment" element={<BlockPeople />} />
          <Route path="/Home/blockfollowerscomment" element={<BlockFollowers />} />
          <Route path="/Home/profileactivity" element={<ProfileActivities />} />
          <Route path="/Home/post" element={<AllPosts />} />
          <Route path="/Home/digitalcollections" element={<DG />} />
          <Route path="/Home/rewards" element={<MyttubeRewards />} />
          <Route path="/Home/rewardspoints" element={<RewardsPoints />} />
          <Route path="/Home/profileactivityvisited" element={<ProfileActivityVisited />} />
          <Route path="/Home/profileactivitydelete" element={<ProfileActivityDelete />} />
          <Route path="/Home/profileactivitygroup" element={<ProfileActivityGroup />} />
          <Route path="/Home/receivedgift" element={<ReceivedGiftFromfollowers />} />
          <Route path="/Home/collectpoints" element={<CollectPointsFromfollowers />} />
          <Route path="/Home/archive" element={<Archive />} />
          <Route path="/Home/history" element={<History />} />
          <Route path="/Home/favoritesprofile" element={<FavoritesProfile />} />
          <Route path="/Home/friend" element={<Friend />} />
          <Route path="/Home/qrcode" element={<QrCode />} />
          <Route path="/Home/saved" element={<Saved />} />



          <Route path="/Home/profile" element={<ProfileDetails />} />

          {/* <Route path="/Home/settings" element={<SettingPage />} /> */}

        </Route>




      </Routes>
    </BrowserRouter>
  );
}

export default App;
