import React from "react";


const AboutSection1 = (prop) => {

  return (
    <>
      <img className="img-fluid" src={prop.AboutImg} alt="" />
    </>

  );
}

const AboutHeading = (prop) => {

  return (
    <>
      <div className="">
        <h1 className="text" style={{ fontWeight: "400", fontSize: "40px" }}>{prop.AboutHeading} <br />{prop.AboutSecondHeading}</h1>
      </div>
    </>

  );
}

const AboutPara = (prop) => {

  return (
    <>

      <h5><p style={{ textAlign: "justify" }}>{prop.Para}</p></h5>

    </>

  );
}

// ========New============
const AbooutCardSlider = (prop) => {
  return (
    <>

      <div class="card border border-top-0 shadow-sm m-2 mt-5" style={{ 'borderRadius': '20px' }}>
        <img src={prop.CardImg} class="card-img-top mx-auto d-block" style={{ width: "100px", height: "50px" }} alt="..." />
        <center><hr style={{ border: "2px solid black", width: "80%" }} /></center>
        <div class="card-body">
          <div class="mt-3" >
            <h5 className="text-start p-1 fw-bold">{prop.AboutHeading}</h5>
            <p class="text-justify px-3" style={{ fontSize: "13px" }}>{prop.CardContent}</p>
            <button class="btn btn-outline-primary bg-white text-primary shadow btn-sm float-end" >{prop.CardBtn} <span><i class="fa fa-share-square-o float-start mt-1 me-1" aria-hidden="true"></i>  </span> </button>

          </div>
        </div>
      </div>


    </>
  );
}
const AbooutCardSlider2 = (prop) => {
  return (
    <>

      <div class="card border border-top-0 shadow m-4 py-4" style={{ 'borderRadius': '20px' }}>
        <img src={prop.CardImg} class="card-img-top mx-auto d-block" style={{ width: "100px" }} alt="..." />
        <div class="card-body">
          <div class="mt-3 px-3" >
            <h5 className="text-start text p-1 fw-bold">{prop.AboutHeading}</h5>
            <p class="text-justify p-1" style={{ fontSize: "13px" }}>{prop.CardContent}</p>
          </div>
        </div>
      </div>


    </>
  );
}




export default AboutSection1;
export { AboutHeading };
export { AboutPara };
export { AbooutCardSlider };
export { AbooutCardSlider2 };