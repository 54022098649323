import React from "react";
import CareerHeading, { CareerCardSlider1, CareerHeadingSecond, CareerHeadingThird, CareerImg } from "./CareerSection";
import Footer from "../../../Footer";
import Sponsered from "../../slider/Sponsered";
import SponseredSlider from "../../slider/SponseredSlider";
// import AboutSection1, { AbooutCardSlider, AboutHeading, AboutPara } from "./AboutSection1";
import LoginScreen from "../about/img/loginScreen.png";
import flag from "../about/img/flag.png";
import indiatoday from "../../../assets/img/brand/indiatoday.png";
import timesofindia from "../../../assets/img/brand/timesofindia.png";
import ndtv from "../../../assets/img/brand/ndtv.png";
import MobileFrame from "../../slider/MobileFrame";
import Carousel from 'react-bootstrap/Carousel';
import CardGroup from 'react-bootstrap/CardGroup';
import "../about/About.css"
import { CompanyCardSlider1, CompanyCardSlider2, } from "../company/CompanySection1";
import { AbooutCardSlider, AbooutCardSlider2, AbooutCardSlider3, CareerViewer } from "../Career/CareerSection";


import cardImg1 from "../Career/img/c-11.png";
import cardImg2 from "../Career/img/c-22.png";
import cardImg3 from "../Career/img/c-33.png";

import viewer1 from "../Career/img/viewer1.png";
import viewer2 from "../Career/img/viewer2.png";
import viewer3 from "../Career/img/viewer3.png";
import viewer4 from "../Career/img/viewer3.png";
import viewer5 from "../Career/img/viewer3.png";
import viewer6 from "../Career/img/viewer3.png";

import bharatPay from '../company/img/bharatPay.png';
import myntra from '../company/img/myntra.png';
import oneplush from '../company/img/oneplush.png';
import boat from '../company/img/boat.png';
import card1 from '../Career/img/cc-1.png';
import card2 from '../Career/img/cc-2.png';
import card3 from '../Career/img/cc-3.png';
import card4 from '../Career/img/cc-4.png';
import card5 from '../Career/img/cc-5.png';
import card6 from '../Career/img/cc-6.png';
import influencer1 from '../company/img/influencer1.png';
import influencer2 from '../company/img/influencer2.png';
import influencer3 from '../company/img/influencer3.png';

import CarasolImg1 from '../Career/img/carasolImg1.png';
import CarasolImg2 from '../Career/img/carasolImg2.png';
import CarasolImg3 from '../Career/img/carasolImg3.png';

import LinkdIn from '../Career/img/LinkdIn.png';
import vLine from "../../../assets/img/verticalLine.svg";
import { BlogBrandSlider } from "../Career/CareerSection";


const Career = () => {
    return (
        <>
            <Carousel className="mt-5">
                <Carousel.Item>
                    <img className="d-block w-100 img-fluid" src={CarasolImg1} alt="First slide" />
                    <Carousel.Caption className="mb-4">
                        <h1 style={{ fontFamily: "Imprima" }}>Good thoughts | Strong vision</h1>
                        <button className="btn btn-primary px-5 py-2">Candidate Login</button>
                    </Carousel.Caption>

                    <div style={{ position: "absolute", top: "40px", left: "400px" }}>
                        <div className="">
                            <div className=" content__container__text mt-3">
                                <h4 ><span style={{ fontFamily: "Satisfy" }} className="text" >myttube </span> careers</h4>
                            </div>
                            <div class=" content__container d-none d-md-block mb-4" style={{ padding: '0 80px' }}>
                                <p class="content__container__text fw-bold" style={{ fontSize: "35px" }}>
                                    Build Your <span className="text-primary ">Career</span>
                                </p>

                                <ul class="content__container__list ">
                                    <li class="content__container__list__item text-success">World</li>
                                    <li class="content__container__list__item text-danger">Movies</li>
                                    <li class="content__container__list__item text-info">News</li>
                                    <li class="content__container__list__item text-warning">Celebrities</li>
                                    {/* <li class="content__container__list__item text-warning">Earn And Fun</li> */}
                                </ul>
                            </div>
                        </div>

                        <section>
                            <div className="d-flex justify-content-center mt-5 d-block">
                                <div className="col-md-4">
                                    <h5 className="text ">I’m looking for a job in </h5>
                                </div>
                                <div className="col-md-3 form-group ">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Subject</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-md-1 text-center mt-1">
                                    <h5 className="text-light">In</h5>
                                </div>
                                <div className="col-md-3 form-group ">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Subject</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-md-1 text-center">
                                    <button className="btn btn-primary m-0"><i class="fa fa-search text-center" aria-hidden="true"></i></button>
                                    {/* <i class="fa-solid fa-magnifying-glass btn btn-orimary"></i></span> */}
                                    {/* <i class="fa-solid fa-magnifying-glass fa-beat-fade"></i> */}
                                </div>
                            </div>
                        </section>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100 img-fluid" src={CarasolImg1} alt="First slide" />
                    <Carousel.Caption className="mb-4">
                        <h1 style={{ fontFamily: "Imprima" }}>Good thoughts | Strong vision</h1>
                        <button className="btn btn-primary px-5 py-2">Candidate Login</button>
                    </Carousel.Caption>

                    <div style={{ position: "absolute", top: "40px", left: "400px" }}>
                        <div className="">
                            <div className=" content__container__text mt-3">
                                <h4 ><span style={{ fontFamily: "Satisfy" }} className="text" >myttube </span> careers</h4>
                            </div>
                            <div class=" content__container d-none d-md-block mb-4" style={{ padding: '0 80px' }}>
                                <p class="content__container__text fw-bold" style={{ fontSize: "35px" }}>
                                    Build Your <span className="text-primary ">Career</span>
                                </p>

                                <ul class="content__container__list ">
                                    <li class="content__container__list__item text-success">World</li>
                                    <li class="content__container__list__item text-danger">Movies</li>
                                    <li class="content__container__list__item text-info">News</li>
                                    <li class="content__container__list__item text-warning">Celebrities</li>
                                    {/* <li class="content__container__list__item text-warning">Earn And Fun</li> */}
                                </ul>
                            </div>
                        </div>

                        <section>
                            <div className="d-flex justify-content-center mt-5 d-block">
                                <div className="col-md-4">
                                    <h5 className="text ">I’m looking for a job in </h5>
                                </div>
                                <div className="col-md-3 form-group ">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Subject</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-md-1 text-center mt-1">
                                    <h5 className="text-light">In</h5>
                                </div>
                                <div className="col-md-3 form-group ">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Subject</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-md-1 text-center">
                                    <button className="btn btn-primary m-0"><i class="fa fa-search text-center" aria-hidden="true"></i></button>
                                    {/* <i class="fa-solid fa-magnifying-glass btn btn-orimary"></i></span> */}
                                    {/* <i class="fa-solid fa-magnifying-glass fa-beat-fade"></i> */}
                                </div>
                            </div>
                        </section>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <img className="d-block w-100 img-fluid" src={CarasolImg1} alt="First slide" />
                    <Carousel.Caption className="mb-4">
                        <h1 style={{ fontFamily: "Imprima" }}>Good thoughts | Strong vision</h1>
                        <button className="btn btn-primary px-5 py-2">Candidate Login</button>
                    </Carousel.Caption>

                    <div style={{ position: "absolute", top: "40px", left: "400px" }}>
                        <div className="">
                            <div className=" content__container__text mt-3">
                                <h4 ><span style={{ fontFamily: "Satisfy" }} className="text" >myttube </span> careers</h4>
                            </div>
                            <div class=" content__container d-none d-md-block mb-4" style={{ padding: '0 80px' }}>
                                <p class="content__container__text fw-bold" style={{ fontSize: "35px" }}>
                                    Build Your <span className="text-primary ">Career</span>
                                </p>

                                <ul class="content__container__list ">
                                    <li class="content__container__list__item text-success">World</li>
                                    <li class="content__container__list__item text-danger">Movies</li>
                                    <li class="content__container__list__item text-info">News</li>
                                    <li class="content__container__list__item text-warning">Celebrities</li>
                                    {/* <li class="content__container__list__item text-warning">Earn And Fun</li> */}
                                </ul>
                            </div>
                        </div>

                        <section>
                            <div className="d-flex justify-content-center mt-5 d-block">
                                <div className="col-md-4">
                                    <h5 className="text ">I’m looking for a job in </h5>
                                </div>
                                <div className="col-md-3 form-group ">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Subject</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-md-1 text-center mt-1">
                                    <h5 className="text-light">In</h5>
                                </div>
                                <div className="col-md-3 form-group ">
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Subject</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                <div className="col-md-1 text-center">
                                    <button className="btn btn-primary m-0"><i class="fa fa-search text-center" aria-hidden="true"></i></button>
                                    {/* <i class="fa-solid fa-magnifying-glass btn btn-orimary"></i></span> */}
                                    {/* <i class="fa-solid fa-magnifying-glass fa-beat-fade"></i> */}
                                </div>
                            </div>
                        </section>
                    </div>
                </Carousel.Item>
            </Carousel>


            <div className="mt-5 img1">
                <div className="container">
                    <div className="container">
                        <h3 className="fw-normal">Current Opening Job role -content</h3>
                        <div className="mt-4">
                            <Carousel>
                                <Carousel.Item >
                                    <CardGroup>
                                        <CareerCardSlider1 CardImg2={viewer1} AboutHeading="Software Engineer " CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        <CareerCardSlider1 CardImg2={viewer2} AboutHeading="Business developer" CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        <CareerCardSlider1 CardImg2={viewer3} AboutHeading="Marketing " CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                    </CardGroup>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <CardGroup>
                                        <CareerCardSlider1 CardImg2={viewer1} AboutHeading="Business developer" CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        <CareerCardSlider1 CardImg2={viewer3} AboutHeading="Software Engineer" CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        <CareerCardSlider1 CardImg2={viewer2} AboutHeading="Marketing " CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                    </CardGroup>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <CardGroup>
                                        <CareerCardSlider1 CardImg2={viewer2} AboutHeading="Marketing " CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        <CareerCardSlider1 CardImg2={viewer3} AboutHeading="Business developer" CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        <CareerCardSlider1 CardImg2={viewer1} AboutHeading="Software Engineer" CardBtn="Apply" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                    </CardGroup>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className="text-center text mt-4">Team Success story</h3>
            <center style={{ marginTop: "-10px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
            <section className="img1">
                <div className="container mt-3" >
                    <Carousel>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>

                    <Carousel>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <CareerViewer CardImg2={card3} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card4} title="Blog/article headline" date="25-Nov-2023" />
                                <CareerViewer CardImg2={card5} title="Blog/article headline" date="25-Nov-2023" />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section>


            <div className="container mt-2">
                <h3 className="text-center text mt-5">News/Blog/Media </h3>
                <center style={{ marginTop: "-10px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                <Carousel>
                    <Carousel.Item >
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn=" More " CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                            <AbooutCardSlider CardImg={LinkdIn} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                            <AbooutCardSlider CardImg={LinkdIn} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                            <AbooutCardSlider CardImg={LinkdIn} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content," />
                        </CardGroup>
                    </Carousel.Item>
                </Carousel>
            </div>

            <div className="mb-2">
                <div className="container">
                    <div class="row mt-5">
                        <div class="col-md-8 mt-5 pt-5">
                            <div class="input-group mb-5">
                                <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                                <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button class="btn btn-primary px-4 rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                            </div>

                            <h1 style={{ fontFamily: "Poppins" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                            <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world <span className="text-danger">(CONTENT)</span></p>
                        </div>

                        <div class="col-md-4 ">

                            <img src={LoginScreen} width={350} className="float-md-end img-fluid" alt="loginScreen" />
                            <h6 className="text-center text-primary">USE THE APP NOW</h6>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
}

export default Career;