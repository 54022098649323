import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Mobileframe from '../slider/img/mobileframe.png';
import Carousel1 from '../slider/img/image 552.png';
import Carousel2 from '../slider/img/image 540.png';

import axios from "axios";



// const MobileFrame = () => {

//     const baseURL = "https://api.myttube.com/api/Banner/get-all-banner";
//     const [sliderImg, setsliderImg] = useState([]);

//     useEffect(() => {
//         axios.get(baseURL)
//             .then(response => {
//                 console.log(response.data);
//                 setsliderImg(response.data);
//             })
//             .catch(error => {
//                 console.log(error);
//             });


//     }, []);



//     return (

//         <>


//             <div className="InnerSlider">

//                 <img className="mobile "
//                     src={Mobileframe} alt="mobile" />
//                 <Carousel className="FrameS">

//                     {sliderImg.slice().map((post) => {
//                         //const { id, title, body } = post;
//                         const { banner_master_id, file_name } = post;
//                         return (
//                             <Carousel.Item className="carousel-item">


//                                 <img
//                                     className="d-block w-100"
//                                     src={file_name}
//                                     alt={banner_master_id}
//                                 />
//                             </Carousel.Item>
//                         );
//                     })}

//                 </Carousel>
//             </div>



//         </>


//     );
// }

const MobileFrame = () => {

    return (
        
       <>
<div className="InnerSlider">

        <img className="mobile "
                src={Mobileframe} alt="mobile" />
        <Carousel className="FrameS">
            {/* <img className="mobile"
                src={Mobileframe} alt="mobile" /> */}
          
                  
            <Carousel.Item className="carousel-item">
                <img
                    className="d-block w-100"
                    src={Carousel1}
                    alt="First slide"
                />
            </Carousel.Item>

            <Carousel.Item className="carousel-item">
                <img
                    className="d-block w-100"
                    src={Carousel2}
                    alt="Second slide"
                />
            </Carousel.Item>

            <Carousel.Item className="carousel-item">
                <img
                    className="d-block w-100"
                    src={Carousel1}
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
</div>
       
       </>


    );
}


export default MobileFrame;