import React from "react";
import AboutSection1, { AbooutCardSlider, AboutHeading, AboutPara } from "./AboutSection1";
import LoginScreen from "../about/img/loginScreen.png";
import flag from "../about/img/flag.png";
import indiatoday from "../../../assets/img/brand/indiatoday.png";
import timesofindia from "../../../assets/img/brand/timesofindia.png";
import ndtv from "../../../assets/img/brand/ndtv.png";
import Captures3 from "../about/img/Captures3.png";
import Capturesh from "../about/img/Capturesh.png";
import Capturesshh from "../about/img/Capturesshh.png";
import Capturess1hh1 from "../about/img/Capturess1hh1.png";
import SponseredSlider from "../../slider/SponseredSlider";
import Footer from "../../../Footer";
import MobileFrame from "../../slider/MobileFrame";

import Carousel from 'react-bootstrap/Carousel';
import CardGroup from 'react-bootstrap/CardGroup';
import "../about/About.css"
import OrbitSingleSlider from "../../slider/OrbitSingleSlider";
import vLine from "../../../assets/img/verticalLine.svg";
import { NavLink } from "react-router-dom";


const AboutUs = () => {
    return (
        <>
            <div class="container mt-4">

            <div class="row mt_container" >
                    <div class="col-md-6">
                        <div className="mt-3 pb-2">
                            <h1 style={{ fontSize: "50px" }}> <span className="text" style={{ fontFamily: "Stick No Bills", fontSize: "60px" }}>ABOUT</span> <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span></h1>
                            <h2 className="text" style={{ fontFamily: "Josefin Sans", fontWeight: "bold" }}>We create beautiful experiences <br />We creating beautiful experiences. </h2>
                            <h6 style={{ fontSize: "20px" }}>Connecting world</h6>
                            <p style={{ textAlign: "justify" }}>Myttube is a platform for craetors that bring unique content together, helps bild audience
                                and boost the result of all content impact. We have worked together with myttube team.,
                                helping them enter a new era of product : A fresh visual communication and many new
                                features.

                                In a old design myttube find their straigthforward and clean look, but for this new approach
                                was essential to combine to that more approachable and welcoming communication for
                                future content creator. To that, we use picture to represent creators in their very own unique
                                creative process. The picture style where inspired by scrapbook, (cut/out/overlapped) that
                                is also very personal canvas in this process of creation.  In a old design myttube find their s </p>
                        </div>
                        <NavLink className="btn btn-primary shadow w-50 btn-lg py-3 rounded" to="/login">Login</NavLink>
                    </div>

                    <div className="col-md-6 col-12 col-sm-12">
                        <div className="row mt-4 ">
                            <div className="col-md-6" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                <MobileFrame />
                            </div>
                            <div className="col-md-6 d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-25%)" }} >
                                <MobileFrame />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid  bg-gray mt-5">
                <div className="container p-5">
                    <h4 style={{ fontFamily: "Josefin Sans", fontWeight: "bold", lineHeight: "25px" }} className="text" >milestone
                        <br /> <span className="fs-2 text">Unstoppable Entertainment </span></h4>

                    <div className="row mt-4 mx-auto"/*  style={{ lineHeight: "1px" }} */>
                        <div className="col-md-3 col-6">
                            <h3 className="fw-bold text-primary">10B+</h3>
                            <p className="text-danger">Number Changing automatically </p>
                            <h4>Views</h4>
                        </div>
                        <div className="col-md-3 col-6">
                            <h3 className="fw-bold text-primary">1B+</h3>
                            <p className="text-danger">Number Changing </p>
                            <h4>Users</h4>
                        </div>
                        <div className="col-md-3 col-6">
                            <h3 className="fw-bold text-primary">100M+</h3>
                            <p className="text-danger">Number Changing automatically </p>
                            <h4>Creators</h4>
                        </div>
                        <div className="col-md-3 col-6">
                            <h3 className="fw-bold text-primary">34M+</h3>
                            <p className="text-danger">Number Changing automatically </p>
                            <h4>Brands</h4>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container">

                <div class="row mt-5">

                    <div class="col-md-8 mt-5 pt-5">
                        <div class="input-group mb-5">
                            <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                            <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button class="btn btn-primary px-4 rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                        </div>

                        <h1 style={{ fontFamily: "Poppins" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                        <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world <span className="text-danger">(CONTENT)</span></p>
                    </div>

                    <div class="col-md-4 ">

                        <img src={LoginScreen} width={350} className="float-md-end img-fluid" alt="loginScreen" />
                        <h6 className="text-center text-primary">USE THE APP NOW</h6>
                    </div>
                    <div>

                    </div>
                </div>
            </div>


            <div className="container">
                <div class="row">
                    <div class="col-md-8 mt-5 pt-5 ">
                        <div className="container">
                            {/* 
                            <div >
                            </div> */}

                            <OrbitSingleSlider />
                            <div className="row mt-4" style={{ transform: "translateY(-110%)", marginBottom: "-90%" }}>
                                <div className="col-md-5 pt-4" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                    <MobileFrame />
                                </div>
                                <div className="col-md-5 pt-4 d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-25%)" }} >
                                    <MobileFrame />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <h4 class="fs-5 mt-5 pt-5 mb-4" style={{ fontWeight: "500", }}>A place for connecting to the people of world </h4>
                        <p style={{ textAlign: "justify" }}>Myttube is a platform for craetors that bring unique content together, helps bild audience
                            and boost the result of all content impact. We have worked together with myttube team.,
                            helping them enter a new era of product : A fresh visual communication and many new
                            features.   <br /> <br />

                            In a old design myttube find their straigthforward and clean look, but for this new approach
                            was essential to combine to that more approachable and welcoming communication for
                            future content creator. To that, we use picture to represent creators in their very own unique
                            creative process. The picture style where inspired by scrapbook, (cut/out/overlapped) that
                            is also very personal canvas in this process of creation.  In a old design myttube find their straigthforward and clean look, but for this new approach
                            was essential to combine to that more approachable and welcoming communication for
                            future content creator. To that, we use picture to represent creators in their very own unique
                            creative process. The picture style where inspired by scrapbook, (cut/out/overlapped) that
                            is also very personal canvas in this process of creation.  </p>
                    </div>
                </div>

                <p className="fs-5 fw-bold text-center text mt-5">News/Blog/Media</p>
                <center style={{ marginTop: "-10px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                <Carousel>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn=" More " CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                </Carousel>
            </div >

            <div className="container">

                <div class="row mt-5">
                    <div class="col-md-8 mt-5 pt-5">


                        <h4 style={{ fontFamily: "Poppins" }} className="text" >Content #build community-groups</h4>
                        <h6 style={{ fontFamily: "Poppins" }}>Helpful Platform. For everyone</h6>

                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed border-2 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h6 className="text" >Choose a  platform </h6>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <h6 className="text" >Identify your audience</h6>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <h6 className="text" >Grow following on social network</h6>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        <h6 className="text" >Design the content plan</h6>
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom ">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        <h6 className="text" >Grow your local business</h6>
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 ">

                        <div className="row mt-4 ">

                            <div className="col-md-9 pt-4 d-none d-md-block" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                <MobileFrame />
                            </div>
                            {/* <div className="col-md-5 pt-4 d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-25%)" }} >
                                <MobileFrame />
                            </div> */}
                        </div>

                    </div>

                </div>
            </div>






            <Footer />
        </>
    );
}

export default AboutUs;