import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../landingpage/components/SideListSection";
import "../settingpage/components/assets/css/sidebar.css";


const MainMenu = () => {

    const [isOpen, setIsopen] = useState(false);

    const SettingPage = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }

    return (
        <div className="container-fluid mt-3"  >

            <div className="btn btn-primary toggles" onClick={SettingPage} >
                <i className="fa fa-bars"></i>
            </div>

            <div className={`sidebar ${isOpen == false ? 'active' : ''}`}>
                <div className="sd-header">
                    {/* <h4 className="mb-0">Setting</h4> */}
                    <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div>
                </div>
                <div className="sd-body">
                    <ul>
                        <li>
                            <NavLink className="sd-link" to="/Home/profilepage" ><SideDrop ItemIcon="uiw:setting-o" MenuName="Setting" /></NavLink>
                        </li>
                        <li>
                            <NavLink className="sd-link" to="/Home/profileactivity" ><SideDrop ItemIcon="gg:edit-highlight" rotate={1} MenuName="Profile Activity" /></NavLink>
                        </li>


                        <li>

                            <NavLink className="sd-link" to="/Home/digitalcollections" ><SideDrop ItemIcon="fluent:gift-card-16-regular" MenuName="Digital Collections" /> </NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="/Home/history" ><SideDrop ItemIcon="fluent:history-24-filled" MenuName="History" /></NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="/Home/archive" ><SideDrop ItemIcon="ion:archive" MenuName="Archive" /></NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="/Home/saved" ><SideDrop ItemIcon="bi:bookmark-star" MenuName="Saved" /></NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="/Home/favoritesprofile" ><SideDrop ItemIcon="emojione:sparkling-heart" MenuName="Favorites Profile" /></NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="/Home/friend" ><SideDrop ItemIcon="carbon:friendship" MenuName="Friends" /></NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="/Home/qrcode" ><SideDrop ItemIcon="ic:round-qr-code-scanner" MenuName="QR Code" /></NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="#" ><SideDrop ItemIcon="fluent:people-add-20-regular" MenuName="Discover" /></NavLink>
                        </li>
                        <li>

                            <NavLink className="sd-link" to="#" ><SideDrop ItemIcon="fluent:share-48-filled" MenuName="Share" /></NavLink>
                        </li>
                        <li>

                            {/* <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch" MenuName="Company" /></NavLink> */}
                        </li>
                        {/* <li>

                            <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                        </li> */}
                    </ul>
                </div>
            </div>


        </div>
    );
}

export default MainMenu;