import React from "react";
import BusinessHeading, { BrandSlider, BusinessBtn, BusinessPara, BusinessTextbox } from "./BusinessSection";
import Partner from "../business/img/Partner.png";
import Partner1 from "../business/img/Partner1.png";
import Partnerbanner from "../business/img/partnerbanner.jpg";
import ContactForm from "../contact/ContactForm";
import SponseredSlider from "../../slider/SponseredSlider";
import Footer from "../../../Footer";
import MobileFrame from "../../slider/MobileFrame";
import businesslogo1 from '../business/img/businesslogo1.svg';
import businesslogo2 from '../business/img/businesslogo2.svg';
import businesslogo3 from '../business/img/businesslogo3.svg';
import businesslogo4 from '../business/img/businesslogo4.svg';
import mobileFrame from '../business/img/mobileFrame.svg';
import businessText from '../business/img/businessText.png';


import Carousel from 'react-bootstrap/Carousel';
import CardGroup from 'react-bootstrap/CardGroup';
import "../about/About.css"

import indiatoday from "../../../assets/img/brand/indiatoday.png";
import timesofindia from "../../../assets/img/brand/timesofindia.png";
import ndtv from "../../../assets/img/brand/ndtv.png";
import AboutSection1, { AbooutCardSlider, AboutHeading, AboutPara } from "../about/AboutSection1";
import LoginScreen from "../about/img/loginScreen.png";
import flag from "../about/img/flag.png";

import BrandImg from "../../../assets/img/brand/Group 5150.png"
import { NavLink } from "react-router-dom";
import vLine from "../../../assets/img/verticalLine.svg";



const Business = () => {

    return (
        <>
            <div className="container mt-5">
                <div className="row mt_container">

                    <div className="col-md-9 col-12">
                        <div class="content__container d-none d-md-block" style={{ padding: '0 50px' }}>
                            <p class="content__container__text Billions">
                                {/* See <span className="text-primary">Entertainment</span> */}
                                <span className="text-primary fw-bold" style={{ fontSize: "58px", }}>Billions</span>
                            </p>

                            <ul class="content__container__list mt-1" style={{ paddingLeft: "220px", fontSize: "30px" }}>
                                <li class="content__container__list__item text">Viewability</li>
                                <li class="content__container__list__item text">Brand Impress Boosting</li>
                                <li class="content__container__list__item text">Opinions</li>
                                <li class="content__container__list__item text">Brand Trust</li>
                                {/* <li class="content__container__list__item text-warning">Earn And Fun</li> */}
                            </ul>
                        </div>
                        {/* <div class="content ">
                        </div> */}

                        <img className="d-none d-md-block" style={{ padding: '120px 50px' }} src={businessText} width={750} alt="" />
                        <h4 className="mb-5">Join with <span className="text-primary">billions</span> of people in the <span className="text-primary">world</span>  </h4>
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-lg btn-primary w-25"><NavLink className="nav-link text-light" to="/businessJoin">Join Us</NavLink></button>
                            <button className="btn btn-lg btn-primary w-25">Create ads</button>
                            <button className="btn btn-lg btn-light shadow-lg w-25 ">Login</button>
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="row mt-4 ">
                            <div className="col-md-12 col-sm-12 float-end" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                <MobileFrame />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div>
                    <p className="text h5 mt-5 mb-3" style={{ fontFamily: "Josefin Sans" }}>This Platform Helpful, For everyone</p>
                </div>



                <center>
                    <div className="row ">
                        <div className="col-md-3 col-6">
                            <img className="" src={businesslogo1} alt="business logo" />
                            <h6>Create a Business<br />Profile at 0 cost</h6>
                        </div>
                        <div className="col-md-3 col-6">
                            <img className="" src={businesslogo2} alt="business logo" />
                            <h6>Easy Access<br />anytime anywhere </h6>
                        </div>
                        <div className="col-md-3 col-6">
                            <img className="" src={businesslogo3} alt="business logo" />
                            <h6>Connected world<br />Billions of trusted People </h6>
                        </div>
                        <div className="col-md-3 col-6">
                            <img className="" src={businesslogo4} alt="business logo" />
                            <h6>Promote Your Brand<br />Choose ads</h6>
                        </div>
                    </div>
                </center>

            </div>

            <div className="container">

                <div class="row mt-5">
                    <div class="col-md-4 ">

                        <div className="row mt-4 ">

                            <div className="col-md-9 pt-4 d-none d-md-block" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                <h1 className="text">Billions</h1>
                                <p>Daily Active Users on <span style={{ fontFamily: "satisfy" }} className="text">myttube</span></p>
                                <MobileFrame />
                            </div>

                        </div>

                    </div>
                    <div class="col-md-8 mt-5 pt-5">


                        <h4 style={{ fontFamily: "Josefin Sans" }} className="text fw-bold ms-3" >Unstoppable Boosting Your Brand/Product </h4>
                        <h6 style={{ fontFamily: "Josefin Sans", fontWeight: "400" }} className="ms-3">Helpful Platform. For everyone</h6>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed border-2 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h6 className="text" >Choose a  platform </h6>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <h6 className="text" >Design the content plan</h6>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <h6 className="text" >Grow your local business</h6>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        <h6 className="text" >Design the content plan</h6>
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            {/* <div class="accordion-item border-0 border-bottom ">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        <h6 className="text" >Grow your local business</h6>
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div> */}

                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-md-4 col-6">
                                    <button className="btn btn-lg btn-primary w-100">Login</button>
                                </div>
                                <div className="col-md-4 col-6">
                                    <button className="btn btn-lg btn-outline-primary shadow-sm w-100">Join Us</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>


            <div className="container">
                <h5 className="mb-5" style={{ fontFamily: "Josefin Sans" }}>Millions+ Trusted Brand joined with Us</h5>
                <Carousel>
                    <Carousel.Item>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                        <CardGroup>
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                            <BrandSlider CardImg={BrandImg} />
                        </CardGroup>
                    </Carousel.Item>
                </Carousel>
            </div>


            <div className="container">

                <div class="row mt-5">
                    <div class="col-md-4 ">

                        <div className="row mt-4 ">

                            <div className="col-md-9 pt-4 d-none d-md-block" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                {/* <h1 className="text">Billions</h1>
                                <p>Daily Active Users on <span style={{ fontFamily: "satisfy" }} className="text">myttube</span></p> */}
                                <MobileFrame />
                            </div>

                        </div>

                    </div>
                    <div class="col-md-8 mt-5 pt-5">


                        <h2 style={{ fontFamily: "Josefin Sans" }} className="text ms-3" >Build Community/Groups on <span style={{ fontFamily: "satisfy" }} > myttube</span>  </h2>
                        <h4 style={{ fontFamily: "Josefin Sans", fontWeight: "400" }} className=" ms-3" >Helpful Platform. For everyone</h4>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed border-2 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <h6 className="text" >Choose a  platform </h6>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <h6 className="text" >Identify your audience</h6>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <h6 className="text" >Grow a following on social network</h6>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom">
                                <h2 class="accordion-header" id="headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        <h6 className="text" >Design the content plan</h6>
                                    </button>
                                </h2>
                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item border-0 border-bottom ">
                                <h2 class="accordion-header" id="headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        <h6 className="text" >Grow your local business</h6>
                                    </button>
                                </h2>
                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="container mt-5">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <button className="btn btn-lg btn-primary w-100">Login</button>
                                </div>
                                <div className="col-md-6 col-6">
                                    <button className="btn btn-lg btn-outline-primary shadow w-100">Join Us</button>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

            <div className="container">
                <p className="fs-5 fw-bold text-center text mt-5 ">News/Blog/Media</p>
                <center style={{ marginTop: "-20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>

                {/* <center><hr style={{ border: "2px solid black", width: "100px" }} /></center> */}
                <Carousel>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn=" More " CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <AbooutCardSlider CardImg={indiatoday} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={timesofindia} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <AbooutCardSlider CardImg={ndtv} CardBtn="  More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                </Carousel>
            </div>


            <div className="container mb-5">

                <div class="row mt-5">

                    <div class="col-md-8 mt-5 pt-5">
                        <div class="input-group mb-5">
                            <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                            <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button class="btn btn-primary px-4 rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                        </div>

                        <h1 style={{ fontFamily: "Poppins" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                        <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world <span className="text-danger">(CONTENT)</span></p>
                    </div>

                    <div class="col-md-4 ">

                        <img src={LoginScreen} width={350} className="float-md-end img-fluid" alt="loginScreen" />
                        <h6 className="text-center text-primary">USE THE APP NOW</h6>
                    </div>
                    <div>

                    </div>
                </div>
            </div>

            <Footer />
        </>
    );

}

export default Business;