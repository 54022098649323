import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Card } from "react-bootstrap";
import "../../landingpage/assets/css/style.css"

const StoryNew = props => {
  let { imgSrc, price, title } = props.data;
  return (
   
    <>
    

      <div className="position-relative m-2 story-section">
        <div className="position-relative mx-auto story-img-section">
          <img src={imgSrc} alt="..." className="img-fluid rounded-circle story-img" />
        </div>
        <div className="p- 0 text-center story-name">{price} </div>
        <div className="p- 0 text-center story-title">{title} </div>
        <div className="p- 0 text-center story-btn"><a href="#">Follow</a></div>
      </div>

    </>
  );
};

export default StoryNew;