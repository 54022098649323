import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Mobileframe from '../slider/img/mobileframe.png';
import slide1 from '../slider/img/slider_1.png';
import slide2 from '../slider/img/slider_4.png';
import slide3 from '../slider/img/slider_3.png';

const LandingFrame = (prop) => {

    return (

        <>

   


            <section className="slider" id="landingframe">
                <Carousel>
                    <Carousel.Item className='slide'>
                        <img
                            className="d-block"
                            src={slide3}
                            alt="First slide"
                            
                        />
                    </Carousel.Item>
                    <Carousel.Item className='slide'>
                        <img
                            className="d-block"
                            src={slide2}
                            alt="Second slide"
                        
                        />
                    </Carousel.Item>
                    <Carousel.Item className='slide'>
                        <img
                            className="d-block"
                            src={slide1}
                            alt="Third slide"
                            
                        />
                    </Carousel.Item>
                </Carousel>
            </section>

        </>


    );
}


export default LandingFrame;