import React from "react";
import CareerHeading, { CareerHeadingSecond, CareerHeadingThird, CareerImg } from "../Career/CareerSection";
import Footer from "../../../Footer";
import Sponsered from "../../slider/Sponsered";
import SponseredSlider from "../../slider/SponseredSlider";
// import AboutSection1, { AbooutCardSlider, AboutHeading, AboutPara } from "./AboutSection1";
import LoginScreen from "../about/img/loginScreen.png";
import flag from "../about/img/flag.png";
import indiatoday from "../../../assets/img/brand/indiatoday.png";
import timesofindia from "../../../assets/img/brand/timesofindia.png";
import ndtv from "../../../assets/img/brand/ndtv.png";
import MobileFrame from "../../slider/MobileFrame";
import Carousel from 'react-bootstrap/Carousel';
import CardGroup from 'react-bootstrap/CardGroup';
import "../about/About.css"
import { CompanyCardSlider2, } from "../company/CompanySection1";
import { AbooutCardSlider, AbooutCardSlider2, AbooutCardSlider3 } from "../Career/CareerSection";
import { UserViewer, UserCardSlider1, UserCardSlider2 } from "../user/UserSection";


import cardImg1 from "../Career/img/c-11.png";
import cardImg2 from "../Career/img/c-22.png";
import cardImg3 from "../Career/img/c-33.png";

import Prize1 from "../Career/img/prizeCar.png";
import Prize2 from "../Career/img/prizeScuty.png";
import Prize3 from "../Career/img/prizeIphone.png";

import bharatPay from '../company/img/bharatPay.png';
import myntra from '../company/img/myntra.png';
import oneplush from '../company/img/oneplush.png';
import boat from '../company/img/boat.png';
import card1 from '../Career/img/c-1.png';
import card2 from '../Career/img/c-2.png';
import card3 from '../Career/img/c-3.png';
import card4 from '../Career/img/c-4.png';
import card5 from '../Career/img/c-5.png';
import card6 from '../Career/img/c-6.png';
import influencer1 from '../company/img/influencer1.png';
import influencer2 from '../company/img/influencer2.png';
import influencer3 from '../company/img/influencer3.png';

import vLine from "../../../assets/img/verticalLine.svg"




const User = () => {
    return (
        <>

            <div class="container mt-4">
                <div class="row justify-content-between">
                    <div class="col-md-7">
                        <div className="mt-3 pb-2">
                            <h4 className="fw-b"> <span className="text" style={{}}>History Viewers Content<br /> </span> <span>Incredible Power Of </span> <span className="text" style={{ fontFamily: "satisfy" }}> myttube </span><br />community</h4>
                            <p className="mt-4" style={{ textAlign: "justify" }}> <span className="text-danger" style={{ fontFamily: "satisfy" }}>Content-</span> <span className="text" style={{ fontFamily: "satisfy" }}>myttube </span> communities can be incredibly powerful.
                                They can bring people together from all over the world with similar interests, provide a platform for creators to share their content and ideas, and even inspire social change. The ability to share,
                                comment, and interact with others on myttube  can create a sense of belonging and connection that is unique to the platform. Additionally, the community can also play a role in the growth and success
                                of individual creators by promoting their content and providing feedback.

                            </p>
                            <p className="mt-4" style={{ textAlign: "justify" }}> <span className="text-danger" style={{ fontFamily: "satisfy" }}>Content-</span> <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> is a powerful community-driven
                                platform that allows users to share and discover videos. The community aspect of the platform allows users to interact with each other through comments, likes, and subscriptions, and also enables content
                                creators to build a following and monetize their content. Additionally, the platform's algorithm is designed to recommend videos to users based on their viewing history and engagement, which helps to
                                promote discovery and engagement. This creates a powerful feedback loop that can drive the success of content creators and help to create viral phenomena.
                            </p>
                        </div>
                        <div className="col-md-12 d-flex justify-content-start mt-5">
                            <button className="btn btn-primary shadow col-md-4 rounded">Login</button>
                            <button className="btn btn-primary shadow col-md-4 rounded bg-transparent text-primary mx-5">Sign Up</button>
                        </div>
                    </div>

                    <div className="col-md-3 col-12 col-sm-12 float-end">
                        <div className="row mt-4">
                            <div className="col-md- d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-2%)" }} >
                                <MobileFrame />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <h3 className="text-center">Smart Viewers Of The Month</h3>
                    <center style={{ marginTop: "-10px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                    <section style={{ backgroundImage: 'url("https://aakaarinfotech.com/download/companyBg.png")' }}>
                        <div className="container" >
                            <div className="mt-4">
                                <Carousel>
                                    <Carousel.Item >
                                        <CardGroup>
                                            <UserCardSlider1 CardImg={cardImg1} CardImg2={Prize1} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                            <UserCardSlider1 CardImg={cardImg2} CardImg2={Prize2} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                            <UserCardSlider1 CardImg={cardImg3} CardImg2={Prize3} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        </CardGroup>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <CardGroup>
                                            <UserCardSlider1 CardImg={cardImg1} CardImg2={Prize1} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                            <UserCardSlider1 CardImg={cardImg2} CardImg2={Prize3} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                            <UserCardSlider1 CardImg={cardImg3} CardImg2={Prize2} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        </CardGroup>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <CardGroup>
                                            <UserCardSlider1 CardImg={cardImg1} CardImg2={Prize2} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                            <UserCardSlider1 CardImg={cardImg2} CardImg2={Prize3} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                            <UserCardSlider1 CardImg={cardImg3} CardImg2={Prize1} AboutHeading="Viewers Name" CardBtn="@Username User_Id" CardContent="Content# In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                        </CardGroup>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </section>
                </div>

                <div className="container mt-5">
                    <div className="mt-4" >
                        <h2 class="text">Sponsored Brands</h2>
                    </div>
                    <div className="py-2" style={{ backgroundImage: 'url("https://aakaarinfotech.com/download/companyBg.png")' }}>
                        {/* <img src={carasoulBg} class="img-fluid" alt="..." /> */}
                        <Carousel >
                            <Carousel.Item >
                                <CardGroup className="p-2">
                                    <UserCardSlider2 CardImg={bharatPay} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={myntra} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={boat} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={oneplush} CardContent="Success story" />
                                </CardGroup>
                            </Carousel.Item>
                            <Carousel.Item>
                                <CardGroup>
                                    <UserCardSlider2 CardImg={bharatPay} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={myntra} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={boat} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={oneplush} CardContent="Success story" />
                                </CardGroup>
                            </Carousel.Item>
                            <Carousel.Item>
                                <CardGroup>
                                    <UserCardSlider2 CardImg={bharatPay} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={myntra} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={boat} CardContent="Success story" />
                                    <UserCardSlider2 CardImg={oneplush} CardContent="Success story" />
                                </CardGroup>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>

            <div className="container mt-5 py-1 d-none d-md-block">
                <h2 className="text-center">Top viewers & creators history</h2>
                <div className="mx-auto d-flex justify-content-between">
                    <UserViewer CardImg2={card1} UserId="@Username-id" />
                    <UserViewer CardImg2={card2} UserId="@Username-id" />
                    <UserViewer CardImg2={card3} UserId="@Username-id" />
                    <UserViewer CardImg2={card4} UserId="@Username-id" />
                    <UserViewer CardImg2={card5} UserId="@Username-id" />
                </div>
                <div className="mx-auto d-flex justify-content-between">
                    <UserViewer CardImg2={card3} UserId="@Username-id" />
                    <UserViewer CardImg2={card4} UserId="@Username-id" />
                    <UserViewer CardImg2={card5} UserId="@Username-id" />
                    <UserViewer CardImg2={card1} UserId="@Username-id" />
                    <UserViewer CardImg2={card2} UserId="@Username-id" />
                </div>

            </div>


            <div className="mb-2">
                <div className="container mt-4">
                    <div class="row mt-5">
                        <div class="col-md-8 mt-5 pt-5">
                            <div class="input-group mb-5">
                                <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                                <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button class="btn btn-primary px-4 rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                            </div>

                            <h1 style={{ fontFamily: "Poppins" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                            <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world <span className="text-danger">(CONTENT)</span></p>
                        </div>

                        <div class="col-md-4 ">

                            <img src={LoginScreen} width={350} className="float-md-end img-fluid" alt="loginScreen" />
                            <h6 className="text-center text-primary">USE THE APP NOW</h6>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="bg-primary mb-3" >
                <hr />
            </div>
            <SponseredSlider /> */}

            <Footer />
        </>
    )
}

export default User;