import React from "react";
import { FormSelect } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";


const ProfilePage = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>

                    </div>


                    <div className="col-md-3 col-12">
                        <div className="sidebar1">
                            <div className="sd-header1">
                                <h4 className="mb-0">Profile </h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link bg-primary" to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" IconClass="text-light" MenuName="Profile" MenuClass="text-light m-0" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link" to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link" to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/language" ><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/ads"><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/help"  ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile"><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-5 col-12 mx-auto col-lg-6 mb-3">
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>
                            {/* <InputForms /> */}


                            <div className="container-fluid mt-5">
                                <div className="row">

                                    <div className="col-md-12 col-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label">Your Username</label>
                                            <input type="email" class="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label">Your 10 digit Mobile number is verified </label>
                                            <input type="email" class="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label">Your Email ID</label>
                                            <input type="email" class="form-control" />
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label">Gender</label>
                                            <FormSelect type="email" class="form-control">
                                                <option value="">--Select--</option>
                                                <option value="">Male</option>
                                                <option value="">Female</option>
                                                <option value="">Other</option>
                                            </FormSelect>
                                        </div>
                                    </div>


                                    <div className="col-md-12 col-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label">Date Of Birth</label>
                                            <input class="form-control" type="date" />
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12">
                                        <div className="border p-2">

                                            <div className="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="notshow" />
                                                <label class="form-check-label" for="notshow">Not Show anyone</label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="followers" />
                                                <label class="form-check-label" for="followers">Show my email id followers</label>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="everyone" />
                                                <label class="form-check-label" for="everyone">Show my email id  everyone </label>
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <button type="submit" className="btn btn-primary mt-3">Save Changes</button>


                                {/* <div className="d-flex justify-content-between mt-3">

                            <button type="submit" class="btn btn-outline-primary">Back</button>
                            <button type="submit" class="btn btn-primary">Save Changes</button>
                        </div> */}

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );


}
export default ProfilePage;