import React from "react";
import Story from "../landingpage/components/Story";
import "./assets/css/style.css"
// import img1 from '../assets/img/Group 785.png'

import img1 from '../landingpage/assets/img/Group 785.png'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";


const ProfileDetails = () => {


    return (
        <>
            <header>

                <div class="container">

                    <div class="profile">

                        <div class="profile-image">

                            <img src="https://images.unsplash.com/photo-1513721032312-6a18a42c8763?w=152&h=152&fit=crop&crop=faces" alt="" />

                        </div>

                        <div class="profile-user-settings">

                            <h1 class="profile-user-name">aquibrahmani143</h1>

                            <button class="btn profile-edit-btn">Edit Profile</button>

                            <button class="btn profile-settings-btn" aria-label="profile settings"><i class="fas fa-cog" aria-hidden="true"></i></button>

                        </div>

                        <div class="profile-stats">

                            <ul>
                                <li><span class="profile-stat-count">164</span> posts</li>
                                <li><span class="profile-stat-count">188</span> followers</li>
                                <li><span class="profile-stat-count">206</span> following</li>
                            </ul>

                        </div>

                        <div class="profile-bio">

                            <p><span class="profile-real-name">Jane Doe</span> Lorem ipsum dolor sit, amet consectetur adipisicing elit 📷✈️🏕️</p>

                        </div>

                    </div>
                    {/* <!-- End of profile section --> */}

                    {/* <Story /> */}

                    {/* Highlight Start */}

                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper" slidesPerView={9}
                        breakpoints={{
                            318: {
                                slidesPerView: 2,
                                spaceBetween: 0,
                            },
                            640: {
                                slidesPerView: 4,
                                spaceBetween: 0,
                            },

                            657: {
                                slidesPerView: 6,
                                spaceBetween: 0,
                            },

                            991: {
                                slidesPerView: 8,
                                spaceBetween: 0,
                            }


                        }}>


                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="position-relative m-2">
                                <div className="position-relative mx-auto story-img-section">
                                    <img src={img1} alt="..." className="img-fluid rounded-circle story-img" />
                                    <div className="p- 0 text-center story-name">Aquib</div>
                                </div>
                            </div>
                        </SwiperSlide>



                    </Swiper>



                    {/* Highlight End*/}


                </div>
                {/* <!-- End of container --> */}

            </header>

            <main>

                <div class="container">
                    <div className="row">

                        <div class="gallery-item mb-4" tabindex="0">
                            <img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 56</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 2</li>
                                </ul>

                            </div>
                        </div>

                        {/* <div className="col-md-4 mb-4">
                            <img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" />
                        </div> */}
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                        <div className="col-md-4 mb-4"><img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" /></div>
                    </div>

                    {/* <div class="gallery">

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1511765224389-37f0e77cf0eb?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 56</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 2</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1497445462247-4330a224fdb1?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 89</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 5</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1426604966848-d7adac402bff?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-type">

                                <span class="visually-hidden">Gallery</span><i class="fas fa-clone" aria-hidden="true"></i>

                            </div>

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 42</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 1</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1502630859934-b3b41d18206c?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-type">

                                <span class="visually-hidden">Video</span><i class="fas fa-video" aria-hidden="true"></i>

                            </div>

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 38</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 0</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1498471731312-b6d2b8280c61?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-type">

                                <span class="visually-hidden">Gallery</span><i class="fas fa-clone" aria-hidden="true"></i>

                            </div>

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 47</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 1</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1515023115689-589c33041d3c?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 94</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 3</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1504214208698-ea1916a2195a?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-type">

                                <span class="visually-hidden">Gallery</span><i class="fas fa-clone" aria-hidden="true"></i>

                            </div>

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 52</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 4</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1515814472071-4d632dbc5d4a?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 66</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 2</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1511407397940-d57f68e81203?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-type">

                                <span class="visually-hidden">Gallery</span><i class="fas fa-clone" aria-hidden="true"></i>

                            </div>

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 45</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 0</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1518481612222-68bbe828ecd1?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 34</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 1</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1505058707965-09a4469a87e4?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 41</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 0</li>
                                </ul>

                            </div>

                        </div>

                        <div class="gallery-item" tabindex="0">

                            <img src="https://images.unsplash.com/photo-1423012373122-fff0a5d28cc9?w=500&h=500&fit=crop" class="gallery-image" alt="" />

                            <div class="gallery-item-type">

                                <span class="visually-hidden">Video</span><i class="fas fa-video" aria-hidden="true"></i>

                            </div>

                            <div class="gallery-item-info">

                                <ul>
                                    <li class="gallery-item-likes"><span class="visually-hidden">Likes:</span><i class="fas fa-heart" aria-hidden="true"></i> 30</li>
                                    <li class="gallery-item-comments"><span class="visually-hidden">Comments:</span><i class="fas fa-comment" aria-hidden="true"></i> 2</li>
                                </ul>

                            </div>

                        </div>

                    </div> */}
                    {/* <!-- End of gallery --> */}

                    {/* <div class="loader"></div> */}

                </div>
                {/* <!-- End of container --> */}

            </main>
        </>

    );
}

export default ProfileDetails