import React, { Component } from 'react';

class GoogleTranslate extends Component {
    render() {
        return (
            <div id="google_translate_element"></div>
        );
    }
}

export default GoogleTranslate;