import { Icon } from "@iconify/react";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";

import ImgUser from "../../landingpage/assets/img/Group 785.png";


const Privacy = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="sidebar1">

                            <div className="sd-header1">
                                <h4 className="mb-0">Privacy</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link " to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/language"><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/ads" ><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/privacy" ><SideDrop IconClass="text-light" MenuClass="text-light m-0" ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-5 col-12 mx-auto col-lg-6 mb-3" style={{ marginTop: "80px" }}>
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>

                            <div class="form-check form-switch d-flex justify-content-between border mt-3 p-3" style={{ fontSize: "25px" }}>
                                <label class="form-check-label" for="PrivateProfile" >Private Profile</label>
                                <input class="form-check-input " type="checkbox" role="switch" id="PrivateProfile" />
                            </div>


                            {/* ====================Like, Comments & Share========================= */}
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Activity Status </p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-12">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Profile Active Seen & Online
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PrivateProfileEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ProfileActiveSeenOnline" id="PrivateProfileEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PrivateProfileFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ProfileActiveSeenOnline" id="PrivateProfileFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PrivateProfileFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ProfileActiveSeenOnline" id="PrivateProfileFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PrivateProfileOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ProfileActiveSeenOnline" id="PrivateProfileOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Show Reward Points </p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-12">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Show Points
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowPointsEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowPoints" id="ShowPointsEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowPointsFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowPoints" id="ShowPointsFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowPointsFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowPoints" id="ShowPointsFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowPointsOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowPoints" id="ShowPointsOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Groups </p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Public Groups Show
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsShowEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsShow" id="PublicGroupsShowEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsShowFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsShow" id="PublicGroupsShowFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsShowFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsShow" id="PublicGroupsShowFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsShowOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsShow" id="PublicGroupsShowOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Public Groups Added You
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsAddedYouEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsAddedYou" id="PublicGroupsAddedYouEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsAddedYouFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsAddedYou" id="PublicGroupsAddedYouFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsAddedYouFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsAddedYou" id="PublicGroupsAddedYouFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsAddedYouOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsAddedYou" id="PublicGroupsAddedYouOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>
                                <div className="row mx-auto p-3">
                                    <div className="col-md-12">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Public Groups Before Add You
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsBeforeAddYouRequestNO">
                                                                    Request NO
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsBeforeAddYou" id="PublicGroupsBeforeAddYouRequestNO" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="PublicGroupsBeforeAddYouRequestYES">
                                                                    Request  YES
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="PublicGroupsBeforeAddYou" id="PublicGroupsBeforeAddYouRequestYES" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>
                            </div>
                            {/* =================================================================================================== */}

                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Comments </p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Comments Allow From
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="CommentsAllowFromEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="CommentsAllowFrom" id="CommentsAllowFromEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="CommentsAllowFromFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="CommentsAllowFrom" id="CommentsAllowFromFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="CommentsAllowFromFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="CommentsAllowFrom" id="CommentsAllowFromFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="CommentsAllowFromOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="CommentsAllowFrom" id="CommentsAllowFromOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Block Comments From
                                                    </div>

                                                    <ul className="list-group" >
                                                        <NavLink className="pb-2" to="/Home/blockpeoplecomment"> <li className="list-group-item border-0">People <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                                        <NavLink className="pb-2" to="/Home/blockfollowerscomment"> <li className="list-group-item border-0">Followers <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                                        <NavLink className="pb-2" to="/Home/blockfollowingcomment"> <li className="list-group-item border-0">Following <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>
                                                    </ul>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>
                            </div>

                            {/* ============================================================================================== */}

                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Post-Photo & Video </p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-4">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Show Your Post & Video
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowYourPostVideoEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowYourPostVideo" id="ShowYourPostVideoEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowYourPostVideoFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowYourPostVideo" id="ShowYourPostVideoFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowYourPostVideoFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowYourPostVideo" id="ShowYourPostVideoFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowYourPostVideoOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowYourPostVideo" id="ShowYourPostVideoOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-4">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Like Your Post & Video
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeYourPostVideoEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeYourPostVideo" id="LikeYourPostVideoEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeYourPostVideoFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeYourPostVideo" id="LikeYourPostVideoFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeYourPostVideoFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeYourPostVideo" id="LikeYourPostVideoFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeYourPostVideoOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeYourPostVideo" id="LikeYourPostVideoOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-4">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Share Your Post & Video
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShareYourPostVideoEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShareYourPostVideo" id="ShareYourPostVideoEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShareYourPostVideoFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShareYourPostVideo" id="ShareYourPostVideoFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShareYourPostVideoFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShareYourPostVideo" id="ShareYourPostVideoFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShareYourPostVideoOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShareYourPostVideo" id="ShareYourPostVideoOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>


                            </div>
                            {/* ============================================================================================== */}

                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Story </p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Showig Your Story
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowigYourStoryFollowersFollowing">
                                                                    Followers & Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowigYourStory" id="ShowigYourStoryFollowersFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowigYourStoryFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowigYourStory" id="ShowigYourStoryFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ShowigYourStoryFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ShowigYourStory" id="ShowigYourStoryFollowing" />
                                                            </div>
                                                            {/* <div class="form-check  d-flex justify-content-between p-2">
                                                            <label class="form-check-label" for="ShowPointsOff">
                                                                Off
                                                            </label>
                                                            <input class="form-check-input" type="radio" name="ShowPoints" id="ShowPointsOff" />
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Like, Comments & Share Your Story
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeCommentsShareYourStoryFollowersFollowing">
                                                                    Followers & Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeCommentsShareYourStory" id="LikeCommentsShareYourStoryFollowersFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeCommentsShareYourStoryFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeCommentsShareYourStory" id="LikeCommentsShareYourStoryFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeCommentsShareYourStoryFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeCommentsShareYourStory" id="LikeCommentsShareYourStoryFollowing" />
                                                            </div>
                                                            {/* <div class="form-check  d-flex justify-content-between p-2">
                                                            <label class="form-check-label" for="ShowPointsOff">
                                                                Off
                                                            </label>
                                                            <input class="form-check-input" type="radio" name="ShowPoints" id="ShowPointsOff" />
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Live</p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Live Video-Join Request from
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoJoinRequestfromFollowersFollowing">
                                                                    Followers & Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoJoinRequestfrom" id="LiveVideoJoinRequestfromFollowersFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoJoinRequestfromFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoJoinRequestfrom" id="LiveVideoJoinRequestfromFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoJoinRequestfromFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoJoinRequestfrom" id="LiveVideoJoinRequestfromFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoJoinRequestfromOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoJoinRequestfrom" id="LiveVideoJoinRequestfromOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Live Video-Send Notification to
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoSendNotificationtoFollowersFollowing">
                                                                    Followers & Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoSendNotificationto" id="LiveVideoSendNotificationtoFollowersFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoSendNotificationtoFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoSendNotificationto" id="LiveVideoSendNotificationtoFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoSendNotificationtoFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoSendNotificationto" id="LiveVideoSendNotificationtoFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveVideoSendNotificationtoOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LiveVideoSendNotificationto" id="LiveVideoSendNotificationtoOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                </div>
                                <div className="row mx-auto p-3">

                                    <div className="col-md-12">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Block Comments From
                                                    </div>

                                                    <ul className="list-group" >
                                                        <NavLink className="pb-2" to="#"> <li className="list-group-item border-0">People <span className="float-end"><Icon fontSize="40px" icon="material-symbols:arrow-right-rounded" /></span> </li></NavLink>

                                                    </ul>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>
                            </div>
                            {/* ======================================================================================================================== */}

                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Chat-Box</p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Chat-Request from
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ChatRequestfromEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ChatRequestfrom" id="ChatRequestfromEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ChatRequestfromFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ChatRequestfrom" id="ChatRequestfromFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ChatRequestfromFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ChatRequestfrom" id="ChatRequestfromFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ChatRequestfromOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ChatRequestfrom" id="ChatRequestfromOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Add Group Chat
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="AddGroupChatEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="AddGroupChat" id="AddGroupChatEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="AddGroupChatFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="AddGroupChat" id="AddGroupChatFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="AddGroupChatFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="AddGroupChat" id="AddGroupChatFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="AddGroupChatOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="AddGroupChat" id="AddGroupChatOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                            {/* ====================================================================================================================== */}

                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>@Mention</p>
                            <div className="border">
                                {/* <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                            </div> */}

                                <div className="row mx-auto p-3">
                                    <div className="col-md-12">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        @mention you from
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@mentionyoufromEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@mentionyoufrom" id="@mentionyoufromEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@mentionyoufromFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@mentionyoufrom" id="@mentionyoufromFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@mentionyoufromFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@mentionyoufrom" id="@mentionyoufromFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@mentionyoufromOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@mentionyoufrom" id="@mentionyoufromOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>
                            </div>
                            {/* =========================================Chart BOx End================================================ */}



                            {/* ===================================@Mention End=========================================================== */}
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Tag</p>
                            <div className="border">
                                <div className="row mx-auto p-3">
                                    <div className="col-md-12">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        @Tag you from
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@TagyoufromEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@Tagyoufrom" id="@TagyoufromEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@TagyoufromFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@Tagyoufrom" id="@TagyoufromFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@TagyoufromFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@Tagyoufrom" id="@TagyoufromFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="@TagyoufromOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="@Tagyoufrom" id="@TagyoufromOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>
                            </div>
                            {/* ===================================Tag End=========================================================== */}
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Send me GIFT</p>
                            <div className="border" >


                                <div className="row mx-auto p-3">
                                    <div className="col-md-12">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Recieved Gift From
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="RecievedGiftFromEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="RecievedGiftFrom" id="RecievedGiftFromEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="RecievedGiftFromFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="RecievedGiftFrom" id="RecievedGiftFromFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="RecievedGiftFromFollowing">
                                                                    Following
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="RecievedGiftFrom" id="RecievedGiftFromFollowing" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="RecievedGiftFromOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="RecievedGiftFrom" id="RecievedGiftFromOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                </div>

                                <div className="row mx-auto p-3">

                                    <div className="col-md-12 border p-2">


                                        <ProgressBar className="float-start mt-2 " style={{ width: "95%" }}>
                                            {/* <ProgressBar label="9k" animated now={90} /> */}
                                            <ProgressBar style={{ backgroundColor: "#94CCFF", }} label="1k" striped now={12} key={1} />
                                            <ProgressBar style={{ backgroundColor: "#63B5FF", }} label="2k" striped now={12} key={2} />
                                            <ProgressBar style={{ backgroundColor: "#35A0FF", }} label="3k" striped now={12} key={3} />
                                            <ProgressBar style={{ backgroundColor: "#0087FF", }} label="4k" striped now={12} key={4} />
                                            <ProgressBar style={{ backgroundColor: "#036FCF", }} label="5k" striped now={12} key={5} />
                                            <ProgressBar style={{ backgroundColor: "#00DA3D", }} label="6k" striped now={12} key={6} />
                                            <ProgressBar style={{ backgroundColor: "#00BD35", }} label="7k" striped now={12} key={7} />
                                            <ProgressBar style={{ backgroundColor: "#019E2D", }} label="8k" striped now={12} key={8} />
                                            <ProgressBar style={{ backgroundColor: "#05892A", }} label="9k" striped now={12} key={9} />
                                            <ProgressBar style={{ backgroundColor: "#005F1B", }} label="10k" striped now={12} key={10} />
                                        </ProgressBar>
                                        <span className="float-end text-primary"> <Icon fontSize={35} icon="material-symbols:circle" /></span>


                                    </div>

                                    <div className="mt-3">

                                        <p className="text-danger" style={{ fontSize: "13px" }}>currently you are not eligible for accepting gift</p>
                                        <p style={{ fontSize: "15px" }}>I accepted <span className="text-primary"> terms of services</span>   | <span className="text-primary">privacy policy <br /> Licence agreement</span>  & <span className="text-primary">read more</span>  </p>
                                    </div>
                                </div>



                            </div>
                            {/* ===================================Send me GIFT End=========================================================== */}

                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Block Profile List</p>
                            <div className="border" style={{ marginBottom: "80px" }}>

                                <div class="input-group mt-4 px-3">
                                    <span class="input-group-text" id="basic-addon1"><Icon fontSize="25px" opacity=".4" icon="ic:baseline-search" /></span>
                                    <input type="text" class="form-control" placeholder="e.g. Profile Name" />

                                </div>

                                <ul className="list-group profilelist" >


                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @aquibrahmani143  </span>
                                            <button className="btn btn-outline-primary float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>aquib rahmani</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1 </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>

                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1  </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>
                                        </div>

                                    </li>

                                    <li className="rounded list-group-item border-0 ">

                                        <div class="p-2">
                                            <img src={ImgUser} alt="avatar" width="50" class="rounded-circle  border border-primary border-1" />
                                            <span className="text-primary" style={{ fontSize: "18px", fontWeight: "500" }}> @Profile.Username1 </span>
                                            <button className="btn btn-outline-primary  float-end mt-2 btn-lg" style={{ width: "150px" }}>Unblock</button>
                                            <p style={{ marginLeft: "60px", lineHeight: "0", marginTop: "-8px" }}>ajay singh-A</p>

                                        </div>

                                    </li>
                                </ul>

                            </div>







                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Privacy;

