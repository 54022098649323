import React from "react";


const CompanyHeading = (prop) => {
    return (

        <h1 class="display-2 text text-light fw-normal">{prop.Heading}</h1>
    );
}

const CompanySImg = (prop) => {
    return (
        <img src={prop.SmallImg}
            class="img-fluid w-100" style={{ borderRadius: "20px" }} alt="Sheep 1" />

    );
}

const CompanyPara = (prop) => {
    return (
        <h4 class=" mt-3 mx-5">
            <p>
                {prop.Para}
            </p>
        </h4>

    );
}

const RightHeading = (prop) => {

    return (
        <>


            <div class="grad2 py-3 shadow w-50 float-end mt-5 mb-5">
                <div class="container">
                    <h1 className="text-center" style={{ fontWeight: "450" }}>{prop.Right}</h1>
                </div>
            </div>

        </>

    );
}



const CompanyCardSlider1 = (prop) => {
    return (
        <>

            {/* <div class="mx-5 rounded-circle shadow ">
                <img src={prop.CardImg} class="mx-auto " style={{ width: "100px" }} alt="..." />
                <div class="">
                    <div class="mt-3" >
                        <p class="text-justify p-1" style={{ fontSize: "12px" }}>{prop.CardContent}</p>
                    </div>
                </div>
            </div> */}

            <div className="mx-auto justify-content-between" style={{ margin: "20px 0px" }}>
                <div className="col-md-12 mx-auto shadow bg-light rounded-circle h-100 " style={{ padding: "60px 50px", }}>
                    <div className=" " style={{ width: "130px", height: "50px" }}>
                        <img src={prop.CardImg} alt="..." />
                    </div>
                    <div className="mt-5">
                        <span className="text-center mx-auto fw-bold mt-4" style={{ textAlign: "center" }}>{prop.CardContent}</span>
                    </div>
                </div>
            </div>

        </>
    );
}

const CompanyCardSlider2 = (prop) => {
    return (
        <>
            <div className="mx-auto justify-content-between" style={{ margin: "20px 0px" }}>
                <div className="col-md-12 mx-auto shadow bg-light rounded-circle" style={{ padding: "40px 70px" }}>
                    <div className="mx-auto justify-content-center" style={{ width: "70px", height: "20px" }}>
                        <img src={prop.CardImg} alt="..." width="70px" className="  " />
                    </div>
                    <div className="mt-5 mb-4">
                        <h4 className="text-center text p-2 align-middle">{prop.influencerName}</h4>
                        <h4 className="text-center h6 text-primary ">{prop.influencerId}</h4>
                    </div>
                </div>
            </div>

        </>
    );
}


const LeftHeading = (prop) => {

    return (
        <>


            <div class="grad py-3 shadow w-50 mt-5">
                <div class="container">
                    <h1 className="text-center" style={{ fontWeight: "450" }}>{prop.Left}</h1>
                </div>
            </div>

        </>

    );
}




export default CompanyHeading;
export { CompanySImg };
export { CompanyPara };
export { RightHeading };
export { LeftHeading };
export { CompanyCardSlider1 };
export { CompanyCardSlider2 };