import React from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";
import "../../settingpage/components/assets/css/style.css"

const Notification = () => {




    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>

                    <div className="col-md-3 col-12">
                        <div className="sidebar1">
                            <div className="sd-header1">
                                <h4 className="mb-0">Notification</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link bg-primary" to="/Home/notification" ><SideDrop ItemIcon="ci:notification" IconClass="text-light" MenuName="Notifications" MenuClass="text-light m-0" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link" to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/language" ><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/ads"><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/help"  ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile" ><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>



                    <div className="col-md-5 col-12 col-lg-6 mx-auto mb-3" >
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>

                            <div class="form-check form-switch d-flex justify-content-between border mt-3 p-3" style={{ fontSize: "25px" }}>
                                <label class="form-check-label" for="AllNotifications" >All Notifications</label>
                                <input class="form-check-input " type="checkbox" role="switch" id="AllNotifications" />
                            </div>


                            {/* ====================Like, Comments & Share========================= */}
                            <p className="mt-5 fw-bold " style={{ lineHeight: "0px" }}>Like, Comments & Share </p>
                            <div className="border">
                                <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                    <label class="form-check-label" for="LikeCommentsShare" >All Off</label>
                                    <input class="form-check-input" type="checkbox" role="switch" id="LikeCommentsShare" />
                                </div>

                                <div className="row mx-auto p-3">
                                    <div className="col-md-4">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Like Your Post
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="LikeYourPost">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeYourPostEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeYourPost" id="LikeYourPostEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeYourPostFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeYourPost" id="LikeYourPostFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LikeYourPostOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="LikeYourPost" id="LikeYourPostOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>


                                    <div className="col-md-4 ">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Comments Your Post
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="CommentsYourPostEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="CommentsYourPost" id="CommentsYourPostEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="CommentsYourPostFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="CommentsYourPost" id="CommentsYourPostFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="CommentsYourPostOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="CommentsYourPost" id="CommentsYourPostOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-4 ">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Share/Repost Your Post
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="Share/RepostYourPostEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Share/RepostYourPost" id="Share/RepostYourPostEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="Share/RepostYourPostFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Share/RepostYourPost" id="Share/RepostYourPostFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="Share/RepostYourPostOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Share/RepostYourPost" id="Share/RepostYourPostOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>

                                    </div>
                                </div>
                            </div>
                            {/* ====================Like, Comments & Share End========================= */}


                            {/* ====================Live========================= */}
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Live </p>
                            <div className="border">
                                <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                    <label class="form-check-label" for="Live" >All Off</label>
                                    <input class="form-check-input" type="checkbox" role="switch" id="Live" />
                                </div>

                                <div className="row mx-auto p-3">
                                    <div className="col-md-6">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Followers Live
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">

                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Live" id="LiveFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="LiveOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Live" id="LiveOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>


                                    <div className="col-md-6 ">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Following Live
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">

                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="FollowingLiveFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="FollowingLive" id="FollowingLiveFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="FollowingLiveOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="FollowingLive" id="FollowingLiveOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>
                            </div>
                            {/* ====================Live End========================= */}
                            {/* ====================Like, Comments & Share========================= */}
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Chat-Box & Group  </p>
                            <div className="border">
                                <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                    <label class="form-check-label" for="Chat-Box&Group" >All Off</label>
                                    <input class="form-check-input" type="checkbox" role="switch" id="Chat-Box&Group" />
                                </div>

                                <div className="row mx-auto p-3">
                                    <div className="col-md-4">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Chat Request
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ChatRequestEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ChatRequest" id="ChatRequestEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ChatRequestFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ChatRequest" id="ChatRequestFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ChatRequestOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ChatRequest" id="ChatRequestOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>


                                    <div className="col-md-4 ">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Group Request
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="GroupRequestEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="GroupRequest" id="GroupRequestEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="GroupRequestFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="GroupRequest" id="GroupRequestFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="GroupRequestOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="GroupRequest" id="GroupRequestOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-4 ">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Join video call request
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="JoinvideocallrequestEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Joinvideocallrequest" id="JoinvideocallrequestEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="JoinvideocallrequestFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Joinvideocallrequest" id="JoinvideocallrequestFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="JoinvideocallrequestOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="Joinvideocallrequest" id="JoinvideocallrequestOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>

                                    </div>
                                </div>
                            </div>
                            {/* ====================Like, Comments & Share End========================= */}

                            {/* ============================Gift Send ME Start==================== */}

                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Gift Send Me  </p>
                            <div className="col-md-12 mt-4 mb-2 ">
                                <aside id="info-block">
                                    <section class="file-marker">
                                        <div>
                                            <div class="box-title">
                                                Send me Gift
                                            </div>
                                            <div class="box-contents">
                                                <div id="audit-trail">
                                                    <div class="form-check  d-flex justify-content-between p-2">
                                                        <label class="form-check-label" for="SendmeGiftEveryone">
                                                            Everyone
                                                        </label>
                                                        <input class="form-check-input" type="radio" name="SendmeGift" id="SendmeGiftEveryone" />
                                                    </div>
                                                    <div class="form-check  d-flex justify-content-between p-2">
                                                        <label class="form-check-label" for="SendmeGiftFollowers">
                                                            Followers
                                                        </label>
                                                        <input class="form-check-input" type="radio" name="SendmeGift" id="SendmeGiftFollowers" />
                                                    </div>
                                                    <div class="form-check  d-flex justify-content-between p-2">
                                                        <label class="form-check-label" for="SendmeGiftOff">
                                                            Off
                                                        </label>
                                                        <input class="form-check-input" type="radio" name="SendmeGift" id="SendmeGiftOff" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </aside>

                            </div>
                            {/* ===========================Gift Send Me End============================ */}
                            {/* ===========================Connect via email Start============================ */}
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>Connect for you (via email)</p>
                            <div className="col-md-12 mt-4 mb-2 ">
                                <aside id="info-block">
                                    <section class="file-marker">
                                        <div>
                                            <div class="box-title">
                                                Connect for via email
                                            </div>
                                            <div class="box-contents">
                                                <div id="audit-trail">
                                                    <div class="form-check  d-flex justify-content-between p-2">
                                                        <label class="form-check-label" for="ConnectforviaemailEveryone">
                                                            Everyone
                                                        </label>
                                                        <input class="form-check-input" type="radio" name="Connectforviaemail" id="ConnectforviaemailEveryone" />
                                                    </div>
                                                    <div class="form-check  d-flex justify-content-between p-2">
                                                        <label class="form-check-label" for="ConnectforviaemailFollowers">
                                                            Followers
                                                        </label>
                                                        <input class="form-check-input" type="radio" name="Connectforviaemail" id="ConnectforviaemailFollowers" />
                                                    </div>
                                                    <div class="form-check  d-flex justify-content-between p-2">
                                                        <label class="form-check-label" for="ConnectforviaemailOff">
                                                            Off
                                                        </label>
                                                        <input class="form-check-input" type="radio" name="Connectforviaemail" id="ConnectforviaemailOff" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </aside>

                            </div>
                            {/* ===========================Connect via email End============================ */}
                            {/* ===========================New Followers and Following You Start============================ */}
                            <p className="mt-5 fw-bold" style={{ lineHeight: "0px" }}>New Followers and Following You </p>
                            <div className="border" style={{ marginBottom: "80px" }}>

                                <div class="form-check form-switch d-flex justify-content-between p-3" style={{ fontSize: "20px" }}>
                                    <label class="form-check-label" for="FollowingYouStart" >All Off</label>
                                    <input class="form-check-input" type="checkbox" role="switch" id="FollowingYouStart" />
                                </div>

                                <div className="row mx-auto p-3">
                                    <div className="col-md-12">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        @mentions you
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="NewFollowersandFollowingYou">
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="NewFollowersandFollowingYouEveryone">
                                                                    Everyone
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="NewFollowersandFollowingYou" id="NewFollowersandFollowingYouEveryone" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="NewFollowersandFollowingYouFollowers">
                                                                    Followers
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="NewFollowersandFollowingYou" id="NewFollowersandFollowingYouFollowers" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="NewFollowersandFollowingYouOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="NewFollowersandFollowingYou" id="NewFollowersandFollowingYouOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>

                                <div className="row mx-auto p-3">
                                    <div className="col-md-4">

                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        New Followers: Follow You
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">

                                                            <div class="form-check d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="FollowYouOn">
                                                                    On
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="FollowYou" id="FollowYouOn" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="FollowYouOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="FollowYou" id="FollowYouOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>


                                    <div className="col-md-4 ">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Accepted: Your Follow  Request
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">

                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="YourFollowRequestOn">
                                                                    On
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="YourFollowRequest" id="YourFollowRequestOn" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="YourFollowRequestOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="YourFollowRequest" id="YourFollowRequestOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>
                                    <div className="col-md-4 ">
                                        <aside id="info-block">
                                            <section class="file-marker">
                                                <div>
                                                    <div class="box-title">
                                                        Profile: Suggestions
                                                    </div>
                                                    <div class="box-contents">
                                                        <div id="audit-trail">

                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ProfileSuggestionsOn">
                                                                    On
                                                                </label>
                                                                <input class="form-check-input" type="radio" name="ProfileSuggestions" id="ProfileSuggestionsOn" />
                                                            </div>
                                                            <div class="form-check  d-flex justify-content-between p-2">
                                                                <label class="form-check-label" for="ProfileSuggestionsOff">
                                                                    Off
                                                                </label>
                                                                <input class="form-check-input" type="radio" name=" ProfileSuggestions" id="ProfileSuggestionsOff" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </aside>
                                    </div>

                                </div>


                            </div>
                            {/* ===========================New Followers and Following You End============================ */}



                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Notification;
