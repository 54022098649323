import { Icon } from "@iconify/react";
import React from "react";
import { FormSelect } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";

const Verification = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="sidebar1">
                            <div className="sd-header1">
                                <h4 className="mb-0">Verification</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link " to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link " to="/Home/language"><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/ads" ><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/verification" ><SideDrop IconClass="text-light" MenuClass="text-light m-0" ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/help" ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile" ><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-5 col-12 col-lg-6 mx-auto mb-3" style={{ marginTop: "80px" }}>
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>
                            <p className="mt-3 fw-bold " style={{ lineHeight: "0px" }}>Verification </p>
                            <div className="border p-3" style={{ marginBottom: "80px" }}>
                                <center>
                                    <p className="mb-2">#Content-Request for verification your private profile</p>

                                    <span><Icon className="text-success" fontSize={25} icon="iconoir:twitter-verified-badge" /></span>
                                    <p className="mt-2">For Your Private Profile Caiteria # Content</p>
                                </center>



                                <div className="form-group">
                                    <label for="exampleInputEmail1" class="form-label">Your Profile Username</label>
                                    <input type="email" class="form-control" placeholder="Username" />
                                </div>


                                <div className="form-group">
                                    <label for="exampleInputEmail1" class="form-label  ">Enter Your Full Name</label>
                                    <input type="email" class="form-control" placeholder="e.g. Profile Full Name (last name)" />
                                </div>


                                <div className="form-group">
                                    <label for="exampleInputEmail1" class="form-label">Type Of Documents</label>
                                    <FormSelect type="email" class="form-control">
                                        <option value="">e.g. Passport</option>
                                        <option value="">1</option>
                                        <option value="">2</option>
                                        <option value="">3</option>
                                    </FormSelect>
                                </div>


                                <div className="form-group">
                                    <label for="formFileMultiple" class="form-label">Attach Document</label>
                                    <input class="form-control" type="file" id="formFileMultiple" multiple />
                                </div>.

                                <div className="form-group">
                                    <div class="form-switch d-flex justify-content-between" style={{ fontSize: "20px", paddingLeft: "0" }}>
                                        <label class="form-check-label" for="egUseCurrentLoaction" style={{ fontSize: "15px" }}>e.g. Use Current Loaction</label>
                                        <input class="form-check-input" type="checkbox" role="switch" id="egUseCurrentLoaction" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label for="exampleInputEmail1" class="form-label ms-3 ">Enter Street/Village</label>
                                    <input type="email" class="form-control" placeholder="e.g. bandra west " />
                                </div>


                                <div className="form-group">
                                    <label for="exampleInputEmail1" class="form-label ms-3 ">Enter Road/Landmark</label>
                                    <input type="email" class="form-control" placeholder="e.g. linking road, near KFC " />
                                </div>



                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label for="exampleInputEmail1" class="form-label">City</label>
                                            <FormSelect type="email" class="form-control">
                                                <option value="">e.g. Mumbai</option>
                                                <option value="">Delhi</option>
                                                <option value="">Hyderabad</option>
                                                <option value="">Pune</option>
                                            </FormSelect>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label for="exampleInputEmail1" class="form-label">State</label>
                                            <FormSelect type="email" class="form-control">
                                                <option value="">e.g. Maharashtra</option>
                                                <option value="">Goa</option>
                                                <option value="">Tamil Nadu</option>
                                                <option value="">Telangana</option>
                                            </FormSelect>
                                        </div>
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label for="exampleInputEmail1" class="form-label">Counrty</label>
                                            <FormSelect type="email" class="form-control">
                                                <option value="">e.g. India</option>
                                                <option value="">Sri Lanka</option>
                                                <option value="">Bangladesh</option>
                                                <option value="">Other</option>
                                            </FormSelect>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label ms-3 ">PIN Code</label>
                                            <input type="email" class="form-control" placeholder="e.g. 400050" />
                                        </div>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label for="exampleInputEmail1" class="form-label ">website link any</label>
                                    <input type="email" class="form-control" placeholder="e.g. https://www.myttube.com (Optinal) " />
                                </div>

                                <button className="btn btn-primary w-100">Submit</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Verification;