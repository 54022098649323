import { Icon } from "@iconify/react";
import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MainMenu from "../MainMenu";
import ProfilePostImg from "../components/assets/img/posts.svg"
import ProfilePostImg1 from "../components/assets/img/postlive.svg"
import ProfilePostImg2 from "../components/assets/img/poststory.svg"
import ProfilePostImg3 from "../components/assets/img/postvideo.svg"


const AllPosts = () => {
    return (
        <div className="container-fluid">
             <div className="row">
                <div className="col-md-3 col-12">
                    <div class="h-100 fixed-top overflow-hidden scrollbar"
                        style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                        <MainMenu />
                    </div>

                </div>

                <div className="col-md-9 col-12">
                    <h2 className="text-center" style={{ marginTop: "80px" }} >Post</h2>
                    <div class="border">

                        {/* <h5 className="mt-3 p-3 " style={{ lineHeight: "0px" }}> </h5> */}
                        <div className="border m-3">
                            <Tabs defaultIndex={0} className="mt-2">
                                <TabList className="d-flex justify-content-between w-75 mx-auto">
                                    <Tab className="btn border" ><Icon className="bg-primary p-2 text-light rounded-circle" fontSize={70} icon="eva:image-outline" /> <br /> Post <br /> <label>150</label></Tab>
                                    <Tab className="btn border" ><Icon className="bg-primary p-2 text-light rounded-circle" fontSize={70} icon="fluent:history-24-filled" /> <br />Story <br /> <label>150</label></Tab>
                                    <Tab className="btn border" ><Icon className="bg-primary p-2 text-light rounded-circle" fontSize={70} icon="ph:play" /> <br />video <br /> <label>150</label></Tab>
                                    <Tab className="btn border" ><Icon className="bg-primary p-2 text-light rounded-circle" fontSize={70} icon="gg:edit-highlight" rotate={1} /> <br />Highlights <br /> <label>150</label></Tab>
                                    <Tab className="btn border" ><Icon className="bg-primary p-2 text-light rounded-circle" fontSize={70} icon="heroicons:user-group" /> <br />Live <br /> <label>150</label></Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="container">
                                        <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>


                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <div className="container">
                                        <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg1} alt="" />
                                            </div>
                                        </div>


                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <div className="container">
                                        <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg2} alt="" />
                                            </div>
                                        </div>


                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <div className="container">
                                        <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                        </div>


                                    </div>
                                </TabPanel>
                                <TabPanel>
                                <div className="container">
                                        <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg3} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>
                                         <div className="row p-2">
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                            <div className="col-md-3 col-6">
                                                <img width={200} src={ProfilePostImg} alt="" />
                                            </div>
                                        </div>


                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllPosts;