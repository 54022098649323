import React from "react";
import QRCode from '../assets/img/updatedQR.png'
import landing from '../assets/img/landing_image.png'
import apple from '../assets/img/apple.png'
import android from '../assets/img/android.png'
import { TypeAnimation } from 'react-type-animation';
import TextTransition, { presets } from 'react-text-transition';
import LandingFrame from "./slider/LandingFrame";
// import Frame from '../../src/assets/img/frame.png';
import Frame from '../../src/assets/img/frame.png';
import Carousel from '../../src/page/slider/img/image 540.jpg';
import Carousel2 from '../../src/page/slider/img/image 541.jpg';
import Carousel1 from '../../src/page/slider/img/image 552.png';
import { NavLink } from "react-router-dom";
import IndexNav from "../IndexNav";
import homebanner from '../../src/assets/img/bannerhome.jpg'
import MobileFrame from "../../src/page/slider/MobileFrame";

const TEXTS = ['Entertainment', 'Developer', 'Designer'];
const Landing = () => {

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);


    const myStyle = {
        backgroundImage:
            //  "url('https://media.geeksforgeeks.org/wp-content/uploads/rk.png')",
            "url('" + Frame + "')",
        height: '520px',
        backgroundRepeat: 'no-repeat',
        width: '481px',
        position: 'absolute',
        zIndex: 1
    };



    return (
        <>
            <IndexNav />
            <div>

                <div class="landing_container container">
                    <section id="landing">
                        <div className="row">
                            <div className="col-md-6 col-12 col-sm-12" style={{ marginTop: "30px" }}>
                              <div className="position-relative">
                                <div className="d-flex">
                                <div className="slider-1">
                                            <LandingFrame sliderImg1={Carousel} sliderImg2={Carousel1} sliderImg3={Carousel2} />
                                        </div>
                                        <div className="slider-2">
                                            <LandingFrame sliderImg1={Carousel} sliderImg2={Carousel1} sliderImg3={Carousel2} />
                                        </div>
                                    <div className="position-absolute" style={myStyle
                                    }>
                                     
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="scanner_section">

                                    <div className="see_container"> See&nbsp;

                                        <div class="animation">
                                            <ul>
                                                <li className="text-primary">Entertainment</li>
                                                <li className="text-dark">World</li>
                                                <li className="text-info">Career</li>
                                                <li className="text-success">Developer</li>
                                                <li className="text-danger">Discover</li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div>
                                        <div>

                                        </div>
                                        <div className="scanner_div">
                                            <div className="text-center top_p">
                                                <p className="mt-4">Scan the code to <br />download now app</p>
                                            </div>
                                            <div >
                                                <div>
                                                    <img className="img-fluid" src={QRCode} alt="" width="260px" />
                                                </div>
                                                <div className="d-flex justify-content-between landing_btn">
                                                    <div data-toggle="modal" data-target="#myModal"><img className="" src={apple} alt="" /></div>
                                                    <div data-toggle="modal" data-target="#myModal"><img className="" src={android} alt="" /></div>
                                                </div>
                                            </div>
                                            <div className="text-center bottom_p">
                                                <p>USE THE APP NOW</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>


                <nav class="text-center fixed-bottom navbar-light bg-light">
                    <div class="container-fluid">
                        <div className="d-flex bottom_navbar justify-content-evenly">
                            <a href="#">About</a>
                            <a href="#">Terms & Services</a>
                            <a href="#">Terms & Services</a>
                            <a href="#">Privacy Policies</a>
                            <a href="#">Cookies</a>
                            <a href="#">Company</a>
                            <a href="#">Creators</a>
                            <a href="#">For Business</a>
                            <a href="#">Investors</a>
                            <a href="#">Careers</a>
                            <a href="#">Contact</a>
                            <a href="#">English</a>
                            <a href="#">@myttube-2023</a>
                            <a href="#">India <i className="fa fa-caret-down"></i></a>
                        </div>
                    </div>
                </nav>
            </div>

        </>
    );
}

export default Landing;