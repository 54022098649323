import React from "react";
import { Outlet, NavLink } from "react-router-dom";
// import Logo from './assets/img/logo.png';
import Logo from '../landingpage/assets/img/logoblue.png'


import Icon1 from '../landingpage/assets/img/Icon1.png'
import Icon2 from '../landingpage/assets/img/Icon2.png'
import Icon3 from '../landingpage/assets/img/Icon3.png'
import Icon4 from '../landingpage/assets/img/Icon4.png'
import Icon5 from '../landingpage/assets/img/Icon5.png'
import Icon6 from '../landingpage/assets/img/Icon6.png'
import Icon7 from '../landingpage/assets/img/Icon7.png'





const Appbar = () => {
    return (

        <>
            {/* <!-- ================= Appbar ================= --> */}
            <div class="bg-white d-flex align-items-center fixed-top shadow" style={{ minHeight: "56px", zIndex: "5" }} >
                <div class="container-fluid mx-lg-5">
                    <div class="row align-items-center">
                        {/* <!-- search --> */}
                        <div class="col d-flex align-items-center">
                            <NavLink className="navbar-brand" to="/Home">
                                <img src={Logo} alt="logo" style={{ width: "120px" }} />
                            </NavLink>
                        </div>

                        {/* <!-- nav --> */}
                        <div class="col d-none d-xl-flex justify-content-center">
                            {/* <%--desktop--%> */}
                            <div class="form-group has-search pt-3" style={{ minHeight: "40px", minWidth: "230px" }}>
                                <span style={{ lineHeight: "2.30rem" }} class="fa fa-search form-control-feedback"></span>
                                <input type="text" class="form-control border-primary rounded-pill" placeholder="Search" />
                            </div>

                            <div class="form-group has-search pt-3 ms-2" style={{ minHeight: "40px", minWidth: "230px" }}>
                                <span style={{ lineHeight: "2.30rem" }} class="fa fa-plus-circle form-control-feedback bg-primary text-white rounded-pill"></span>
                                <input style={{ paddingLeft: "2.575rem" }} type="text" class="form-control border-primary rounded-pill" placeholder="what's on your mind ?" />
                            </div>
                        </div>


                        {/* <!-- menus --> */}
                        <div class="col d-flex align-items-center justify-content-between" style={{ minHeight: "56px", zIndex: "5" }}>
                            {/* <!-- Menu --> */}
                            <div class="nav__btn nav__btn-active">
                                <NavLink to="/">
                                    <img class="me-2 " src={Icon1} width="20" alt="Icon1" />
                                </NavLink>
                            </div>
                            <div class="nav__btn">
                                <NavLink to="/Home/profileactivitygroup">

                                    <img class="mx-2" src={Icon2} width="20" alt="" />
                                </NavLink>
                            </div>
                            <div class="nav__btn">
                                <NavLink to="/creator">

                                    <img class="mx-2" src={Icon3} width="20" alt="" />
                                </NavLink>
                            </div>

                            <div class="nav__btn">
                                <NavLink to="/message">
                                    <img class="mx-2" src={Icon4} width="20" alt="" />
                                </NavLink>
                            </div>

                            <div class="nav__btn">
                                <NavLink to="/notification">

                                    <img class="mx-2" src={Icon5} width="20" alt="" />
                                </NavLink>
                            </div>
                            <div class="nav__btn">
                                <NavLink to="/refferal">

                                    <img class="mx-2 mr-4" src={Icon6} width="20" alt="" />
                                </NavLink>
                            </div>
                            <div class="nav__btn">

                                <NavLink to="/Home/profile">

                                    <img class="ms-2" src={Icon7} width="40" alt="" />
                                </NavLink>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ================= Appbar End================= --> */}

            <Outlet />
        </>

    );
}
export default Appbar;