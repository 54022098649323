import React from "react";
import CreatorCard from "./CreatorSection1";

import Footer from "../../../Footer";
import { NavLink } from "react-router-dom";
import MobileFrame from "../../slider/MobileFrame";
import Rectangle from "../creator/img/Rectangle.png";
import profile from "../creator/img/profile.png";
import card1 from "../creator/img/card1.png";
import card2 from "../creator/img/card2.png";
import card3 from "../creator/img/card3.png";
import LoginScreen from "../about/img/loginScreen.png";
import flag from "../about/img/flag.png";
import Video from '../creator/img/video.png';
import Video1 from '../creator/img/video1.png';
import Video2 from '../creator/img/video2.png';
import Video3 from '../creator/img/video3.png';

import faq from '../creator/img/faq.png';
import tajmahal from '../creator/img/tajmahal.png';

import influencer1 from '../company/img/influencer1.png';
import influencer2 from '../company/img/influencer2.png';
import influencer3 from '../company/img/influencer3.png';
import vLine from "../../../assets/img/verticalLine.svg";
import { CardGroup, Carousel } from "react-bootstrap";
import { AbooutCardSlider2 } from "../about/AboutSection1";



const Creator = () => {
    return (
        <>

            <div className="container mt-5" style={{ marginBottom: "15%" }}>
            <div class="row mt_container" >

                    <div className="col-md-6 col-12">
                        <div class="content__container d-none d-md-block" >
                            <p class="content__container__text Billions">
                                {/* See <span className="text-primary">Entertainment</span> */}
                                <span className="text-primary fw-bold" style={{ fontSize: "58px", }}>Billions</span>
                            </p>

                            <ul class="content__container__list mt-1" style={{ paddingLeft: "220px", fontSize: "30px" }}>
                                <li class="content__container__list__item text">Viewers </li>
                                <li class="content__container__list__item text">Creators</li>
                                <li class="content__container__list__item text">Comments</li>
                                <li class="content__container__list__item text">Shares</li>
                                {/* <li class="content__container__list__item text-warning">Earn And Fun</li> */}
                            </ul>
                        </div>
                        {/* <div class="content ">
                        </div> */}

                        <h4 className="mb-5 mt-5" style={{ fontFamily: "Josefin Sans" }}>Your <span className="text-primary">talent</span> is <span className="text-primary">unique</span> <br />give it the start it <span className="text-primary">deserves</span> </h4>
                        <h4 className="mt-5" style={{ fontFamily: "Josefin Sans" }}> <span className="text-primary">Create</span> your own story <br />and share it with <span className="text-primary">the world</span> </h4>

                        <div className="d-flex justify-content-between" style={{ marginTop: "150px" }}>
                            <button style={{ fontFamily: "Josefin Sans" }} className="btn btn-default  text-primary fw-bold">Learn more</button>
                            <NavLink className="btn btn-primary px-md-5" to="/registration">Create</NavLink>
                            <NavLink className="btn btn-primary px-md-5" to="/login">Login</NavLink>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 col-sm-12">
                        <div className="row mt-4">
                            <div className="col-md-6 " style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                <MobileFrame />
                            </div>
                            <div className="col-md-6 d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-25%)" }} >
                                <MobileFrame />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5  d-none d-md-block" >
                <div className="row">

                    <div className="col-md-3  col-12">
                        <div style={{ position: "absolute", top: "14%", left: "19%", zIndex: "1" }}>

                            <CreatorCard cardImg={card1} hashtag="#myttube @followmyteam #dailypost #post #mypost #socialmedia" />
                        </div>
                    </div>
                    <div className="col-md-6 col-12 border border-primary border-5 rounded-5 " style={{ paddingBottom: "7%" }}>
                        <div class="card" >
                            {/* <img src={Creators9} class="card-img-top" alt="" /> */}
                            <p className="fs-4 mt-2 text-center">all your past and all your future <br />
                                content , <strong>all in one place</strong>   </p>
                            <div class="card-body rounded-4 shadow">
                                <center> <img className="img-fluid" style={{ width: "80%" }} src={Rectangle} alt="" /></center>
                                <center style={{ position: "absolute", top: "20%", left: "42%" }}><img src={profile} width={40} alt="profile img" />
                                    <p className="text-white fs-7">@Profile_username <br />Profile_Name</p>
                                </center>
                                <div className="text-center bg-white rounded-2 pt-3" style={{ position: "absolute", top: "36%", left: "19%" }}>
                                    <ul class="nav  justify-content-center ">
                                        <li class="nav-item fs-7">
                                            <a class="nav-link active" aria-current="page" href="#">All Content</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link text-dark" href="#">Posts</a>
                                        </li>
                                        <li class="nav-item fs-7">
                                            <a class="nav-link text-dark" href="#">Videos</a>
                                        </li>
                                        <li class="nav-item fs-7">
                                            <a class="nav-link text-dark" href="#" >Promote </a>
                                        </li>
                                        <li class="nav-item fs-7">
                                            <a class="nav-link text-dark" href="#" >Poll</a>
                                        </li>
                                        <li class="nav-item fs-7">
                                            <a class="nav-link text-dark" href="#" >Podcast</a>
                                        </li>

                                    </ul>
                                </div>

                                <div className="container">
                                    <div className="row w-75 mx-auto mt-2 mb-2">
                                        <div className="col-md-4 col-12">
                                            <input class="form-control" placeholder="Discover " />
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>All Podcaster</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4 col-12">
                                            <input type="date" class="form-control" placeholder="Discover " />
                                        </div>
                                        <p className="mt-3 mb-4">836 From All <span className="text-primary">Formats</span></p>
                                    </div>

                                    <center >
                                        <p className="w-75 mx-auto text-danger">Myttube brings together everything you’ve ever created,and we create no
                                            matter it’s articals, videos, podcasts, and no matter where they ‘ve been published
                                            -fully automated so you don’t have to lift a finger.</p>
                                    </center>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-3 col-12" style={{ position: "absolute", top: "22%", right: "5%", zIndex: "1" }}>
                        <CreatorCard cardImg={card2} hashtag="#myttube @followmyteam #dailypost #post #mypost #socialmedia" />
                    </div>
                </div>
                <center style={{ marginTop: "-8%" }} /* style={{ position: "absolute", top: "80%", right: "40%", zIndex: "1" }} */ >
                    <CreatorCard cardImg={card3} hashtag="#myttube @followmyteam #dailypost #post #mypost #socialmedia" />
                </center>

            </div>

            <div className="container">

                <div class="row mt-5">

                    <div class="col-md-8 mt-5 pt-5">
                        <div class="input-group mb-5">
                            <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                            <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <button class="btn btn-primary px-4 rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                        </div>

                        <h1 style={{ fontFamily: "Poppins" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                        <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world <span className="text-danger">(CONTENT)</span></p>
                    </div>

                    <div class="col-md-4 ">

                        <img src={LoginScreen} width={350} className="float-md-end img-fluid" alt="loginScreen" />
                        <h6 className="text-center text-primary">USE THE APP NOW</h6>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
            <div className="container">

                <div class="row mt-5">

                    <div class="col-md-6 col-12 ">
                        <h1 className="text">Create responsibly</h1>
                        <h5 className="text">How to create page?</h5>
                        {/* <video src={grouprVideo} style={{ height: "200%" }} autoPlay loop muted /> */}
                        <img src={Video} className="img-fluid" width={500} alt="video" />

                    </div>
                    <div class="col-md-6 col-12 mt-5 pt-5">
                        <div class="card mb-4 mt-3">
                            <div class="row g-0">
                                <div class="col-md-3">
                                    <img src={Video1} width={150} class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-9">
                                    <div class=" ms-2">
                                        <p class="card-text fs-7">How to add multiple videos in website or Lorem Ipsum is simply to make a video gallery</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="row g-0">
                                <div class="col-md-3">
                                    <img src={Video2} width={150} class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-9">
                                    <div class=" ms-2">
                                        <p class="card-text fs-7">How to add multiple videos in website or Lorem Ipsum is simply to make a video gallery</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-3">
                            <div class="row g-0">
                                <div class="col-md-3">
                                    <img src={Video3} width={150} class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-9">
                                    <div class=" ms-2">
                                        <p class="card-text fs-7">How to add multiple videos in website or Lorem Ipsum is simply to make a video gallery</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h5 className="float-end text-primary">See More</h5>
                    </div>

                    <div>

                    </div>
                </div>
            </div>

            <div className="container">
                <div class="row mt-5">
                    <div className="col-md-6 col-12">
                        <h3> <i class="fa fa-bookmark-o text-primary" aria-hidden="true"></i> FAQ’s</h3>
                        <h1 className="text mt-4">Some of the most frequently asked questions</h1>
                        <p className="mt-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of ty</p>
                    </div>
                    <div className="col-md-6 col-12">
                        <img src={faq} className="w-100" alt="" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item border-0 border-bottom">
                        <h2 class="accordion-header mb-2" id="headingOne">
                            <button class="accordion-button collapsed border-2 shadow-sm rounded-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <h6 className="text" >How do i create myttybe profile? </h6>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0 border-bottom">
                        <h2 class="accordion-header mb-2" id="headingTwo">
                            <button class="accordion-button collapsed shadow-sm rounded-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <h6 className="text" >How do i start  creating group profile?</h6>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0 border-bottom">
                        <h2 class="accordion-header mb-2" id="headingThree">
                            <button class="accordion-button collapsed shadow-sm rounded-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <h6 className="text" >How do trending videos/photos happen?</h6>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0 border-bottom">
                        <h2 class="accordion-header mb-2" id="headingFour">
                            <button class="accordion-button collapsed shadow-sm rounded-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <h6 className="text" >How do make money from <spam>myttube</spam></h6>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                            </div>
                        </div>
                    </div>

                    <div class="accordion-item border-0 border-bottom">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed shadow-sm rounded-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <h6 className="text mb-2" >Grow your local business</h6>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's s..
                            </div>
                        </div>
                    </div>

                </div>
                <center><img src={tajmahal} className="w-75 mt-2" alt="tajmahal" /></center>
            </div>

            <div className="container">
                <div className="mt-2">
                    <p className="fs-5 fw-bold text-center text mt-5">India's top influencer </p>
                    <center style={{ marginTop: "-20px" }}><img src={vLine} alt="" width="100px" height="5px" /></center>
                    <Carousel>
                        <Carousel.Item >
                            <CardGroup>
                                <AbooutCardSlider2 CardImg={influencer1} CardBtn="more" AboutHeading="Gourav youtuber " CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer2} CardBtn="more" AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer3} CardBtn="more" AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <AbooutCardSlider2 CardImg={influencer1} CardBtn="more" AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer2} CardBtn="more" AboutHeading="content builder" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer3} CardBtn="more" AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                            </CardGroup>
                        </Carousel.Item>
                        <Carousel.Item>
                            <CardGroup>
                                <AbooutCardSlider2 CardImg={influencer1} CardBtn="more" AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer2} CardBtn="more" AboutHeading="Gourav" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                                <AbooutCardSlider2 CardImg={influencer3} CardBtn="more" AboutHeading="buisness influencer" CardContent="In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content." />
                            </CardGroup>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>



            <Footer />

        </>
    );
}


export default Creator;