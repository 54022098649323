import React from "react";

const BusinessHeading = (prop) => {
    return (
        <>
            <h1 class="text mt-3 pb-2" style={{ fontWeight: "400" }} >{prop.Heading} <br /> {prop.SecondHeading}</h1>
        </>
    );
}

const BusinessPara = (prop) => {
    return (
        <>
            <p class="text-justify">{prop.Para}</p>
        </>
    );
}

const BusinessBtn = (prop) => {
    return (
        <>
            <button type="button" class="btn btn-outline-primary btn-lg mb-5"
                style={{ borderRadius: "35px" }}>{prop.Btn}</button>
        </>
    );
}

const BusinessTextbox = () => {

    return (
        <>
            <div class="form-group">
                <label for="exampleFormControlTextarea1"></label>
                <textarea class="form-control rounded-0" rows="10"
                    style={{ width: "100%", height: "350px", borderRadius: "60px" }}></textarea>
            </div>
        </>
    );

}

const BrandSlider = (prop) => {
    return (
        <>

            <img src={prop.CardImg} class="card-img-top mx-auto d-block mb-2" style={{ width: "220px", height: "70px" }} alt="..." />



        </>
    );
}

export default BusinessHeading;
export { BusinessPara };
export { BusinessBtn };
export { BusinessTextbox };
export { BrandSlider };
