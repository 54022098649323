import React from "react";
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui";
import { useEffect, useState } from "react";
import img1 from "../../../assets/img/1.svg"
import img2 from "../../../assets/img/2.svg"
import myttube from "../../../assets/img/myttube.svg"
import placeholder from "../../../landingpage/assets/img/Group 784.png"

import "../../form/registration/reg.css"
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input";
import { border, borderRadius } from "@mui/system";
import { NavLink } from "react-router-dom";


const Reg = () => {
    useEffect(() => {
        // =========================
        $(document).ready(function () {

            var current_fs, next_fs, previous_fs; //fieldsets
            var opacity;
            var current = 1;
            var steps = $("fieldset").length;

            setProgressBar(current);

            $(".next").click(function () {

                current_fs = $(this).parent();
                next_fs = $(this).parent().next();

                //Add Class Active
                $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

                //show the next fieldset
                next_fs.show();
                //hide the current fieldset with style
                current_fs.animate({ opacity: 0 }, {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;

                        current_fs.css({
                            'display': 'none',
                            'position': 'relative'
                        });
                        next_fs.css({ 'opacity': opacity });
                    },
                    duration: 500
                });
                setProgressBar(++current);
            });

            $(".previous").click(function () {

                current_fs = $(this).parent();
                previous_fs = $(this).parent().prev();

                //Remove class active
                $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

                //show the previous fieldset
                previous_fs.show();

                //hide the current fieldset with style
                current_fs.animate({ opacity: 0 }, {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;

                        current_fs.css({
                            'display': 'none',
                            'position': 'relative'
                        });
                        previous_fs.css({ 'opacity': opacity });
                    },
                    duration: 500
                });
                setProgressBar(--current);
            });

            function setProgressBar(curStep) {
                var percent = parseFloat(100 / steps) * curStep;
                percent = percent.toFixed();
                $(".progress-bar")
                    .css("width", percent + "%")
            }
            // =========================================




        });
    })

    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);

    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            reader.onload = e => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }


    const [value = "+91", setValue] = useState()




    return (
        <>

            {/* <div className='container'>
              
            </div> */}



            <div class="container mt-4 pt-lg-5" >
                <div className='row'>


                    <div class="d-none d-sm-block col-5 col-md-5 col-lg-5 ">

                        <div style={{ marginTop: "10px", float: "right" }}>
                            <img className='img-fluid mr-2 mt-5' src={img1} width="300" alt="" />

                        </div>

                    </div>

                    <div style={{ borderLeft: "1px solid #007bff", width: "1px" }}></div>

                    <div class="col-12 col-md-5 col-lg-5">

                        <form id="msform" className=' p-0 mt-lg-3 mb-2 ml-lg-5'>
                            <fieldset>

                         
                                <center>
                                    <h4 className='text-center text' style={{ fontFamily: "BreeSerif" }}>welcome to <span style={{ fontFamily: "satisfy" }}>myttube,</span> <br /> entertainment world  </h4>
                                    <img className='img-fluid' width="120" src={img2} alt="" />
                                    <p>Enter Your Phone Number to Proceed Create Profile </p>
                                    <p>English 🌐 <span className='text-primary'>Change ?</span></p>
                                </center>

                                <label className='form-lable'>Enter your 10 digit Mobile number*</label>
                                <PhoneInput
                                    defaultCountry='IN'
                                    placeholder="Enter phone number"
                                    value={value}
                                    onChange={setValue}
                                />


                                <input type="button" class="next action-button btn btn-primary mt-4 w-100" value="Continue" />

                            </fieldset>


                            <fieldset>

                                <center>
                                    <span className="text" style={{ fontFamily: "satisfy", fontSize: "25px" }}>myttube</span> </center>

                                <p className='text-center mt-4' style={{ fontSize: "13px" }}>Create New Profile</p>
                                <p className='text-center mb-4' style={{ fontSize: "13px", lineHeight: "0" }}>OTP has been sent to +91 XXXXXX0080</p>

                                <label className='form-lable'>Enter OTP </label>
                                <input type="text" className='form-control' />
                                <label style={{ fontSize: "12px", float: "right" }}>Resend OTP in 30 Sec.</label>


                                <input type="button" class="next action-button btn btn-primary w-100 mt-5" value="Verify" />

                            </fieldset>



                            <fieldset>
                                <div className='d-flex justify-content-between'>
                                    <p className='text-primary'>Select type of account</p>
                                    <p style={{ fontSize: "13px" }}>You can change this anytime </p>
                                </div>


                                <div class="form-check p-2 pb-0 d-flex justify-content-between border border-2 rounded border-primary">
                                    <label class="form-check-label text-primary ms-2" for="Viewer">
                                        <span className="fw-bold">Viewer or Private</span>
                                        <p className="text-dark mt-1" style={{ fontSize: "12px" }}>#content-excellent for Entertainment, <br />  collect points, surprise gift and more</p>
                                    </label>
                                    <input checked class="form-check-input" type="radio" name="Connectforviaemail" id="Viewer" />
                                </div>

                                <div class="form-check mt-2 p-2 pb-0 d-flex justify-content-between border border-2 rounded ">
                                    <label class="form-check-label ms-2" for="Business">
                                        <span className="fw-bold">Business Or Brand</span>
                                        <p className="text-dark mt-1" style={{ fontSize: "12px" }}>#content-excellent for grow your <br /> brand, business in the world and more</p>
                                    </label>
                                    <input class="form-check-input" type="radio" name="Connectforviaemail" id="Business" />
                                </div>

                                <div class="form-check mt-2 p-2 pb-0 d-flex justify-content-between border border-2 rounded ">
                                    <label class="form-check-label ms-2" for="Creator">
                                        <span className="fw-bold">Creator Or Public Figure</span>
                                        <p className="text-dark mt-1" style={{ fontSize: "12px" }}>#content-excellent for collaboration, <br /> earn money  and many more</p>
                                    </label>
                                    <input class="form-check-input" type="radio" name="Connectforviaemail" id="Creator" />
                                </div>



                                <input type="button" name="previous" class="previous action-button-previous btn btn-outline-primary w-25 mt-4" value="Back" />
                                <input type="button" name="next" class="next action-button btn btn-primary w-50 float-end mt-4" value="Confirm  & Continue" />
                            </fieldset>


                            <fieldset>

                                <div className='d-flex justify-content-between'>
                                    <p style={{ fontSize: "13px" }}>Change your number anytime</p>
                                    <p className='text-primary'>Change?</p>
                                </div>
                                <div>
                                    <span className='text-primary' style={{ fontSize: "18px" }} >Fill the details</span>
                                    <p className="text-dark" style={{ fontSize: "13px" }}>Enjoy the entertainment world   <br />  Get the bonus points </p>
                                </div>

                                <div class="mb-2">
                                    {/* <label className="form-label">Enter Your Full Name</label> */}
                                    <input type="text" className='form-control' placeholder="Enter Your Full Name" />
                                </div>
                                <div class="mb-2">
                                    {/* <label className="form-label">Enter Your Username*</label> */}
                                    <input type="text" className='form-control' placeholder="Enter Your Username" />
                                </div>
                                <div class="mb-2">
                                    {/* <label className="form-label">Password*</label> */}
                                    <input type="password" className='form-control' placeholder="Password" />
                                </div>
                                <div class="mb-2">
                                    {/* <label className="form-label">Enter Your Email</label> */}
                                    <input type="email" className='form-control' placeholder="Enter Your Email" />
                                </div>
                                <div class="mb-3 d-flex justify-content-between">
                                    <label class="form-check-label" for="exampleCheck1">Show your email contact info everyone</label>
                                    <input type="radio" class="form-check-input" id="exampleCheck1" />
                                </div>

                                <input type="button" name="previous" class="previous action-button-previous btn btn-outline-primary w-25 mt-4" value="Back" />
                                <input type="button" name="next" class="next action-button btn btn-primary w-50 float-end mt-4" value="Confirm  & Continue" />

                            </fieldset>

                            <fieldset>

                                {/* ================================= */}

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }} >

                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        ref={imageUploader}
                                        style={{
                                            display: "none"
                                        }}
                                    />
                                    <div
                                        style={{
                                            height: "100px",
                                            width: "100px",
                                            marginBottom: "10px"
                                            // border: "1px solid black",

                                        }}

                                    >
                                        <img
                                            src={placeholder}
                                            ref={uploadedImage}
                                            style={{
                                                height: "100px",
                                                width: "100px",
                                                position: "absolute",
                                                border: "1px solid black",
                                                borderRadius: "50px"
                                            }}
                                        />
                                    </div>
                                    <a className="pointer" onClick={() => imageUploader.current.click()}>Change Profile Image</a>

                                </div>


                                {/* ================================= */}
                                <div class="mb-3">
                                    <label className="form-label">Choose Gender</label>
                                    <select className="form-select">
                                        <option value="">--Gender--</option>
                                        <option value="">Male</option>
                                        <option value="">Female</option>
                                        <option value="">Other</option>
                                    </select>
                                </div>

                                <div class="mb-3">
                                    <label className="form-label">Your Birthday</label>
                                    <input type="date" className="form-control" />
                                    {/* <select className="form-select">
                                    <option value="">--Your Birthday--</option>
                                    <option value="">13 To 18</option>
                                    <option value="">18 To 30</option>
                                    <option value="">30 To 45</option>
                                    <option value="">45 To 60</option>
                                    <option value="">60 above</option>
                                </select> */}
                                </div>

                                <div class="form-check mb-3 d-flex justify-content-between">
                                    <label class="form-check-label" for="sharedanywhere">this won't be shared any or anywhere</label>
                                    <input class="form-check-input" type="checkbox" value="" id="sharedanywhere" />
                                </div>

                                <div class="form-check form-switch mb-3 d-flex justify-content-between pl-0" >
                                    <label class="form-check-label" for="birthdayeveryone" >Show your birthday info everyone</label>
                                    <input class="form-check-input" type="checkbox" role="switch" id="birthdayeveryone" />
                                </div>

                                <div class="form-check form-switch mb-3 d-flex justify-content-between pl-0" >
                                    <label class="form-check-label" for="birthdayfollowers" >Show your birthday info followers</label>
                                    <input class="form-check-input" type="checkbox" role="switch" id="birthdayfollowers" />
                                </div>

                                <input type="button" name="previous" class="previous action-button-previous btn btn-outline-primary w-25 mt-4" value="Back" />

                                <NavLink to="/regmsg" ><input type="button" name="next" class="btn btn-primary w-50 float-end mt-4" value="Submit" /></NavLink>
                            </fieldset>

                        </form>
                    </div>

                </div>




            </div>

        </>
    );

}

export default Reg;