import React from "react";
import Login from "./form/Login";
import MobileFrame from "./slider/MobileFrame";
import Sponsered from "./slider/Sponsered";
import SponseredSlider from "./slider/SponseredSlider";
import Footer from "../Footer";
import SponseredMyttubeSlider from "./slider/SponseredMyttube";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import HomeBG from '../../src/assets/img/HomeImage.png'
import HomeCardImg from '../../src/assets/img/homeCardImg.png'
import grayLine from '../../src/assets/img/grayLine.png'
import myttube from '../../src/assets/img/myttube.png'
import bookQR from '../../src/assets/img/bookQR.png'
import EveryoneLogo from '../../src/assets/img/everyone_logo.png'
import homebanner from '../../src/assets/img/bannerhome.jpg'
import newFrame from '../../src/assets/img/newFrame.png'
import flag from "../../src/assets/img/flag.png";
import LoginScreenNew from '../assets/img/section_2_img.png'
import googlePlayNew from '../assets/img/googlePlayNew.png'
import appStoreNew from '../assets/img/appStoreNew.png'
import bgGradiant from '../assets/img/bgGradiant.png'
import dynamicFrame from '../assets/img/dynamicFrame.png'
import myttubeLogo from '../assets/img/myttubeLogoBlue.png'
import Line from '../assets/img/Line.png'
import QRCode from '../assets/img/updatedQR.png'
import Makeevery from '../assets/img/Makeevery.png'
import Billions from '../assets/img/billions.png'


import pen from '../../src/assets/img/pen.png'
import myttubeIcon from '../../src/assets/img/Icon/image 474.png'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { dark } from "@mui/material/styles/createPalette";
import { CardGroup, Carousel } from "react-bootstrap";
import { AbooutCardSlider2 } from "./form/about/AboutSection1";



function Home() {

    //Working Post API
    // useEffect(() => {


    //     axios.post(`https://api.myttube.com/api/HasTag/add-has-tag`, {
    //         api_key: 'myttube123456',
    //         signupId: 1,
    //         name: 'Saif'

    //     })

    //         .then(response => {
    //             console.log(response);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // }, []);

    return (
        <>
            <div className="HomeBag position-relative" style={{ height: "100vh", backgroundImage: `url(${HomeBG})` }}>
                <div className="book_container container display_book mt-2 img-bg p-4 position-absolute " style={{ right: "0%", left: "0%", transform: "translate(-50%, -50%);" }} >
                    <div className="row" >
                        <div className="row bg-light mx-auto shadow-lg rounded justify-content-between">
                            <div className="col-md-5 col-12 col-sm-12 pt-3 d-none d-md-block">
                                <div className=" d-flex justify-content-between">
                                    <div>
                                        <img src={EveryoneLogo} className="every_one_logo" alt="EveryOne" />
                                        {/* <h5 className="p-0 m-0 text" style={{ fontFamily: "Satisfy", fontSize: "36px" }}>myttube</h5>
                                        <h4 className=" fw-bold text-justify everyone" ><span className="text-primary">F<span className="text_o">O</span>r</span><span>&nbsp;Everyone</span> </h4> */}
                                    </div>
                                    <div style={{ marginRight: "-34px" }}>
                                        <img className="billions" src={Billions} alt="Billions" />
                                      
                                    </div>
                                </div>
                                <div className="row mt-3">

                                    <div className="bk_right_section">
                                        <div>
                                        <p className=""><span className="txt_primary">You </span> are the <span className="txt_primary">creator</span> of Your<br /><span className="txt_primary">story </span><img src={pen} alt="" className="img-fluid" /> </p>

                                        <Carousel className="aaabb pb-4">
                                            <Carousel.Item >
                                                <CardGroup>
                                                    <img src={HomeCardImg} alt="" className="rounded img-fluid" />
                                                </CardGroup>
                                            </Carousel.Item>
                                            <Carousel.Item >
                                                <CardGroup>
                                                    <img src={HomeCardImg} alt="" className="rounded img-fluid" />
                                                </CardGroup>
                                            </Carousel.Item>
                                            <Carousel.Item >
                                                <CardGroup>
                                                    <img src={HomeCardImg} alt="" className="rounded img-fluid" />
                                                </CardGroup>
                                            </Carousel.Item>
                                        </Carousel>
                                        </div>
                                    </div>
                                    <div>

                                        <h2 style={{ }} className="discovery float-end text-yellow">DISCOVER <span className="text" style={{ fontWeight: "400" }}>Everything...</span></h2>
                                    </div>


                                    {/* <div className="col-md-6" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                        <MobileFrame />
                                    </div>
                                    <div className="col-md-6 d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-25%)" }} >
                                        <MobileFrame />
                                    </div> */}
                                </div>
                            </div>


                            <div className="book_bind border col-md-1 d-md-block" style={{ background: "#efebeb" }}>
                                <center className="mt-4"><img src={myttubeIcon} alt="" width="20px" /></center>
                                {/* <h4 style={{ transform: "rotate(270deg)", marginTop: "100px" }}>Lifestyle___</h4> */}
                                <center><img width={20} style={{ marginTop: "100px" }} src={Makeevery} alt="" /></center>
                                <center><a href="#" data-toggle="modal" data-target="#scanNowModal"><img width={60} style={{ marginTop: "120px" }} src={bookQR} alt="" /></a></center>
                                {/* <h4 className="text " style={{ transform: "rotate(270deg)", marginTop: "150px", fontFamily: "Satisfy" }}>myttube</h4> */}
                            </div>

                            <div className="col-md-5 col-12 col-sm-12 ">

                                <div class="lg_form">
                                    {/* <p style={{ transform: "rotate(270deg)", marginLeft: "-350px", fontSize: "14px" }}>login your <span style={{ fontFamily: "Satisfy" }}>myttube</span> profile</p> */}
                                    <p className="see_ent"><span className="txt_seconary py-4">See</span> Happening </p>

                                    {/* <h5 style={{ fontFamily: "imprima" }} class="mt-5 mb-4 pt-4 fw-normal">login your<span className="text " style={{ fontFamily: "satisfy" }}> myttube</span>  profile</h5> */}


                                    <Login />
                                </div>
                            </div>
                        </div>
                        <i class="fa fa-angle-down fs-4 text-light text-center pt-2" ></i>

                    </div>
                </div>
            </div>

            {/* <SponseredSlider /> */}
            {/* <div className="bg-primary">
                <hr />
            </div> */}
            <section id="join_us">
                <div className="container join_us_banner layout_container mt-4 position-relative" style={{ backgroundImage: `url(${homebanner})` }}>
                    <div className="position-absolute w-100 mt-5">



                        <div className="m-5 d-flex justify-content-between join_us_context">
                            <div className="join_us_text">
                                <h1 className="text-white">
                                    <span style={{ marginLeft: "140px" }}>Join us</span> <br /> <span className="dash" style={{ marginLeft: "20px" }}>―――</span>
                                    <span className="text-primary">build</span> more <span className="dash">―――――</span></h1>
                            </div>
                            <div className="download_btn_outer_div">
                                <button className="join_us_btn mb-0 btn text-light bannerBtn border-0 w-100" style={{}} >DOWNLOAD THE APP NOW </button>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="layout_container container">
                    <div class=" mt-md-5  ms-sm-5 mx-auto">
                        <h2 style={{ fontWeight: "500", fontFamily: "Josefin Sans" }} class="text-primary"><NavLink to="/user">User</NavLink><NavLink to="/creator" className="text-dark"> | Creators |</NavLink> <NavLink to="/blog">Blog/News </NavLink> <NavLink className="text-dark" to="/group"> | Group</NavLink></h2>
                    </div>
                </div>
            </section>
            {/* <div className="container d-none d-md-block d-lg-block" > */}
            <section id="get_no" >
                <div class="container layout_container position-relative parent_section">
                    <div className="rounded-4 one_section" style={{ backgroundColor: "#E6F3FF" }}>
                            <div className="context_container">
                                {/* <div class="input-group mb-4 w-75">
                                    <span class="input-group-text shadow"> <span><img src={flag} width={30} alt="indianFlag" /></span> +91 </span>
                                    <input type="text" class="form-control form-control-lg shadow" placeholder="Enter Mobile Number" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                    <button class="btn btn-primary px-4 btn-lg rounded-3 shadow" type="button" id="button-addon2">Get App</button>
                                </div> */}
                                <div class="d-flex mt-5">
                                    <div>
                                        <div id="scan_now_form">
                                            <div class="form_bg"></div><span class="country_code">+91</span>
                                            <div class="pipeline"></div><input class="input_form" placeholder="Enter Mobile Number" />
                                            <div class="country_logo"></div>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="btn_bg"><span class="input_btn">Get App</span></button>
                                    </div>
                                </div>

                                <div className=" d-flex justify-content-between pb-2">
                                    <div className="">
                                        <h1 style={{ fontFamily: "Poppins", fontWeight: "bold" }} className="text" >Get the <span className="text" style={{ fontFamily: "satisfy" }}>myttube</span> App</h1>
                                        <p>We will send you a link via SMS. To download the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app, click to link <br /> simply open it. and enjoy the world </p>
                                    </div>

                                </div>
                            </div>
                            <div className="float-end store_btn_section" style={{ marginTop: "0px" }}>
                                <h5 className="text-center text-primary" style={{ lineHeight: "10px" }}>USE THE APP NOW <br /> <span>  <i class="fa fa-angle-down fs-4 text-dark" ></i></span></h5>

                                <div className="d-flex justify-content-between store_btn">
                                    <button className="btn">
                                        <img className="" src={googlePlayNew} alt="google" />
                                    </button>
                                    <button className="btn">
                                        <img className="" src={appStoreNew} alt="google" />
                                    </button>
                                </div>
                            </div>
                    </div>

                    <div className="position-absolute get_the_app">
                        <img src={LoginScreenNew} className="img-fluid newFrame" style={{ width: "250px" }} alt="loginScreen" />
                    </div>
                </div>
            </section>
            {/* 
            </div> */}



            <section id="memories">
                <div className="container layout_container mt-5" >


                    <img src={dynamicFrame} className="img-fluid " alt="loginScreen" style={{ marginTop: "-36px", zIndex: "1", position: "absolute" }} />
                </div>
                <div className="container layout_container bg-Gradiant rounded-4">

                    <div className="text_context">

                        <h2 className="" ><span className="text-primary">Create</span> your <span className="text-primary">beautiful</span>  <br />
                            memorable experiences with us <br />
                            and <span className="text-primary">share</span> them with the <span className="text-primary">world</span> <br />
                        </h2>
                        <p className="">Open the <span style={{ fontFamily: "satisfy" }} className="text-primary">myttube</span> app with just a click, connect and share with your friends and communities around the world, and inspire others with your creativity. </p>
                    </div>
                </div >
            </section>



            <section id="discover">
                <div className=" align-items-center py-4" style={{ padding: "60px 0;", backgroundColor: "#1d305d", margin: "100px 0 90px 0", backgroundImage: `url(${myttubeLogo})`, backgroundSize: "200px", backgroundRepeat: "no-repeat", backgroundPosition: "center", }}>
                    <div className=" py-4 img-fluid" style={{}}>
                        <div className="py-5 mb-2 d-flex align-items-center justify-content-center" >
                            <h1 class="or-2"><img src={Line} alt="" /><span style={{ fontFamily: "Josefin Sans"}}>Discover New Possibilities</span><img src={Line} alt="" width="100px" /></h1>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container layout_container" >
                    <img src={dynamicFrame} className="stories_img" alt="loginScreen" />
                </div>
            </section>
            <div className="container layout_container bg-Gradiant-2 rounded-4  mb-5 py-5" id="stories">

                <div className="" style={{ marginRight: "200px" }}>

                    <h1 className=""><span >Explore</span> <span className="text-primary">billions</span> <span>of stories and</span>  <br />

                        <span className="text-primary">enjoy</span> unlimited <span className="text-primary">entertainment</span> <br />
                    </h1>
                    <p className=" mt-4 w-50">Create your unique story and share amplify your brand's reach across our diverse global community. Experience the joy of winning with our exciting giveaways. </p>
                </div>
            </div >

            <section id="scan_section">
                <div className="container layout_container my-5">
                    <div className="row d-flex justify-content-center py-4">
                        <div className="col-md-4 d-flex justify-content-center p-0" style={{ fontFamily: "Imprima" }}>
                            <div className="col-md-10 p-2">
                                <h2 className="text-center" style={{ fontFamily: "Josefin Sans" }}>Scan the code to<br />
                                    download now app
                                </h2>
                                <br />
                                <center><img className="img-fluid" src={QRCode} alt="" width="240px" /></center>
                                <h3 style={{ fontFamily: "Josefin Sans" }} className="text-center py-2">
                                    android only
                                </h3>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="scanner_header">
                                <h3 className="text-primary">DOWNLOAD THE APP NOW</h3>
                            </div>


                            {/* <div class="input-group mb-3">
                                <button class="btn dropdown-toggle" 
                                        type="button" data-bs-toggle="dropdown">
                                        + 91
                                </button>
                                <ul class="dropdown-menu">
                                    <li>
                                        <a class="dropdown-item" href="#">
                                            +1
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#">
                                            +655
                                        </a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#">
                                            +31
                                        </a>
                                    </li>
                                </ul>
                                <div className="position-relative">
                                    <input type="text" class="form-control" 
                                        placeholder="Enter Mobile Number" />
                                        <div className="position-absolute btn_get_app_container">
                                        <button class="btn btn-primary btn_get_app" 
                                        type="button" >
                                        Get App
                                        </button>
                                        </div>
                                </div>
                            </div> */}

                            <div id="scan_now_form">
                                <div class="form_bg"></div><span class="country_code">+91</span>
                                <div class="pipeline"></div><input class="input_form" placeholder="Enter Mobile Number" />
                                <div class="btn_bg"></div><span class="input_btn">Get App</span>
                                <div class="country_logo"></div>
                            </div>


                            <div className="store_btn d-flex">
                                <img className="btn" src={googlePlayNew} alt="google" />

                                <img className="btn" src={appStoreNew} alt="google" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>

    );
}
export default Home;




