import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import Logo from './assets/img/logo.png';
import flag from "./assets/img/flag.png";

const Footer = () => {

    return (

        <>
            <footer class="footer-20192">
                <div class="site-section">
                    <div class="container layout_container inner_footer">
                        <div class="row">

                            <div class="col-sm">

                                <img src={Logo} style={{ width: "150px", fontFamily: "Satisfy" }} alt="Logo" />
                                <p class="copyright pb-2 pt-4" style={{ fontFamily: "Poppins" }}>
                                    Connect With people
                                    <br />
                                    in the world
                                    <p> <span style={{fontSize:"16px"}}>&copy;</span> 2023 Copyrights</p>
                                </p>
                                <div class="dropdown">
                                    <button class="" type="button" data-toggle="dropdown"><img src={flag} width={30} alt="indianFlag" />  &nbsp; India
                                        <span class="fa fa-chevron-down"></span></button>
                                    <ul class="dropdown-menu">
                                        {/* <li class="dropdown-header">Australia</li> */}
                                        <li><a href="#">Pakistan</a></li>
                                        {/* <li><a href="#">CSS</a></li>
                                        <li><a href="#">JavaScript</a></li>
                                        <li><a href="#">About Us</a></li> */}
                                    </ul>
                                </div>
                            </div>

                            <div class="col-sm">
                                <h3 className="fw-bold mb-4" style={{ color: "#03194B", fontFamily: "Poppins" }}>Quick Link</h3>
                                <ul class="list-unstyled links">
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/creator">Creator</NavLink>
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/user">Users</NavLink>
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/group">Groups</NavLink>
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/contact">Contact Us</NavLink>
                                </ul>
                            </div>
                            <div class="col-sm">
                                <h3 class="fw-bold mb-4" style={{ color: "#03194B", fontFamily: "Poppins" }}>Further Information</h3>
                                <ul class="list-unstyled links">
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/termsconditions">Terms &amp; Conditions</NavLink>
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/policiesguidelines">Privacy Policy</NavLink>
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/cookies">Cookies</NavLink>
                                    <NavLink className="nav-link" style={{ fontFamily: "Poppins" }} to="/discloure">Discloure</NavLink>

                                </ul>
                            </div>
                            <div class="col-md-3">
                                <h3 class="fw-bold mb-4" style={{ color: "#03194B", fontFamily: "Poppins" }}>Follow us</h3>
                                <ul class="list-unstyled social">
                                    <li style={{ fontSize: "30px" }}><a href="https://twitter.com/myttube" target="_blank"><span class="fa fa-twitter"></span></a></li>
                                    <li style={{ fontSize: "30px" }} class="mx-2"><a href="https://www.linkedin.com/company/myttube/?viewAsMember=true" target="_blank"><span class="fa fa-linkedin"></span></a></li>
                                    <li style={{ fontSize: "30px" }} class="mx-2"><a href="https://www.youtube.com/channel/UC0hS8VnvwSdGw8byJKZyiYw" target="_blank" ><span class="fa fa-youtube-play"></span></a></li>
                                    <li style={{ fontSize: "30px" }} class="mx-2"><a href="https://www.instagram.com/myttubeofficial" target="_blank"><span class="fa fa-instagram"></span></a></li>
                                    <li style={{ fontSize: "30px" }}><a href="https://www.facebook.com/myttubeofficial" target="_blank"><span class="fa fa-facebook"></span></a></li>
                                </ul>
                                <h6 className="mb-0 mt-4" style={{ color: "#03194B", fontFamily: "Poppins" }}>Connect with Us</h6>
                                <span style={{ color: "#03194B", fontFamily: "Poppins" }}>connect@myttube.com</span>
                            </div>

                        </div>
                    </div>
                    <div className="mt-2">
                        <hr />
                    </div>
                    <div className="">
                        <p className="container layout_container m-0" style={{ fontSize: '14px', }}>By continuing past this page, you agree to our Terms of Service, Cookie Policy, Privacy Policy and Content Policies.  All trademarks and copyrights are properties of their respective owners. ©2023  myttube™  All rights reserved.</p>
                    </div>
                </div>


            </footer >
        </>
    )


}

export default Footer;