import React from "react";
import ContactForm from "./ContactForm";
import ContactSectionText from "./ContactSection";
import pen from "../business/img/pen.png";
import handShake from "../contact/img/handShake.svg";
import contactFrame from "../contact/img/contactframe.svg";
import Footer from "../../../Footer";
import congratulationPage from "../business/CongratulationPage";
import { NavLink } from "react-router-dom";


const Contact = () => {
    return (

        <div>
            <div className="container row  mt_container">
                <div className="col-md-12">
                    <div className="col-md-4 mx-auto">
                        <img src={handShake} alt="" width="400px" className="img-fluid" />
                    </div>
                </div>
                {/* -------left content----- */}
                <div className="col-md-6 d-none d-md-block">
                    <img src={contactFrame} alt="contact Frame svg" width="500px" className="img-fluid" />
                </div>

                {/* -------- right form------- */}
                <div className="col-md-6 col-sm-12" style={{ marginTop: "-40px" }}>
                    <div className="col-md-12 col-sm-12 ">
                        <div >
                            <h4 className="text-center">connect<span className="text" style={{ fontFamily: "Satisfy", }}> myttube </span><img width={70} src={pen} style={{ marginTop: "-34px", marginLeft: "-20px" }} alt="pen png" /> </h4>
                            <div class="card  mx-auto mb-4"
                                style={{ borderRadius: "4px" }}>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                           <button className="col-md-12 btn btn-outline-primary">For Connect with us</button>
                                            {/* <input class="form-control primary active text-center col-12" placeholder="For Connect with us" style={{ borderRadius: "4px" }} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <input class="form-control col-12" placeholder="*Enter Your Business Name" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <input class="form-control col-12" placeholder="*Enter Your Name" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <input class="form-control col-12" placeholder="*Enter Your E-mail" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <input class="form-control col-12" placeholder="*Enter Your Mobile Number" style={{ borderRadius: "4px" }} />
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="form-group">
                                            <select class="form-select" aria-label="Default select example">
                                                <option selected>Business</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 col-sm-12 ">
                                        <div class="form-group">
                                            <textarea class="form-control rounded-2 col-12" rows="3" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 col-lg-12 col-sm-12 col mt-2">
                                    <button type="button" class="close btn btn-primary text-light w-100" data-toggle="modal" data-target="#myModal" >Submit</button>
                                    {/* ---model popup--- */}
                                    <div class="modal fade" id="myModal">
                                        <div class="modal-dialog">
                                            <div class="modal-content">


                                                {/* <!-- Modal body --> */}
                                                <div class="modal-body">
                                                    <div class="centre">
                                                        <div className="row d-flex justify-content-center" style={{ marginTop: "70px" }}>
                                                            <div class="text-center mb-4">
                                                                <div class="col-md-12">
                                                                    <div className="tect">
                                                                        {/* <h4 class="">Enter your 6 digit OTP</h4> */}
                                                                        <h5>OTP has been sent to XXXX12345XXX</h5>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row col-md-8">
                                                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                    <div class="form-group">
                                                                        <input class="form-control"
                                                                            style={{ borderRadius: "4px" }} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                    <div class="form-group">
                                                                        <input class="form-control"
                                                                            style={{ borderRadius: "4px" }} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                    <div class="form-group">
                                                                        <input class="form-control"
                                                                            style={{ borderRadius: "4px" }} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                    <div class="form-group">
                                                                        <input class="form-control"
                                                                            style={{ borderRadius: "4px" }} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                    <div class="form-group">
                                                                        <input class="form-control"
                                                                            style={{ borderRadius: "4px" }} />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2 col-lg-2 col-sm-2 col-2">
                                                                    <div class="form-group">
                                                                        <input class="form-control"
                                                                            style={{ borderRadius: "4px" }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-8 col-lg-6 col-sm-4 col mt-4">
                                                                <button class="close btn btn-primary text-light w-100">


                                                                    <NavLink className="text-light" to="/congratulationPage">Submit</NavLink></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Modal footer --> */}
                                                <div class="text-center p-5">
                                                    {/* <p><span class="text-primary">Need Help ?</span> How To Scan, See This Video Links <a className="d-block" href="https://www.youtube.com/aquibrahmani" target="_blank" class="text-decoration-none">https://www.youtube.com/aquibrahmani</a></p> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/* ---model popup--- */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>


    );
}

export default Contact;