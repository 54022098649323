import React from "react";
import { NavLink } from "react-router-dom";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";
import monetize from "../components/assets/img/monetize.svg"
import { ProgressBar } from "react-bootstrap";
import { Icon } from "@iconify/react";
const Monetize = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>
                    <div className="col-md-3 col-12">
                        <div className="sidebar1">

                            <div className="sd-header1">
                                <h4 className="mb-0">Monetize</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" IconClass="text-light" MenuName="Monetize" MenuClass="text-light m-0" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/language" ><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/ads" ><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/verification" ><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about"><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/help" ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile" ><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-5 col-12 col-lg-6 mx-auto mb-3" style={{ marginTop: "80px" }}>
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>
                            <center><img src={monetize} width="550" alt="" /></center>


                            <p className="mt-2 fw-bold" style={{ lineHeight: "0px" }}>Required</p>
                            <div className="border" style={{marginBottom:"80px"}}>
                                <div className="row mx-auto">

                                    <div className="col-md-12 border p-2">


                                        <ProgressBar className="float-start mt-2 " style={{ width: "92%" }}>
                                            {/* <ProgressBar label="9k" animated now={90} /> */}
                                            <ProgressBar style={{ backgroundColor: "#94CCFF", }} label="1k" striped now={12} key={1} />
                                            <ProgressBar style={{ backgroundColor: "#63B5FF", }} label="2k" striped now={12} key={2} />
                                            <ProgressBar style={{ backgroundColor: "#35A0FF", }} label="3k" striped now={12} key={3} />
                                            <ProgressBar style={{ backgroundColor: "#0087FF", }} label="4k" striped now={12} key={4} />
                                            <ProgressBar style={{ backgroundColor: "#036FCF", }} label="5k" striped now={12} key={5} />
                                            <ProgressBar style={{ backgroundColor: "#00DA3D", }} label="6k" striped now={12} key={6} />
                                            <ProgressBar style={{ backgroundColor: "#00BD35", }} label="7k" striped now={12} key={7} />
                                            <ProgressBar style={{ backgroundColor: "#019E2D", }} label="8k" striped now={12} key={8} />
                                            <ProgressBar style={{ backgroundColor: "#05892A", }} label="9k" striped now={12} key={9} />
                                            <ProgressBar style={{ backgroundColor: "#005F1B", }} label="10k" striped now={12} key={10} />
                                        </ProgressBar>
                                        <span className="float-end text-primary"> <Icon fontSize={35} icon="material-symbols:circle" /></span>


                                    </div>



                                    <div className="mt-3">
                                        <p style={{ fontSize: "15px" }}>after successful 10k followers complete, your gift button automatically ON <br />show your every post/content below </p>
                                    </div>

                                    <div className="col-md-12 p-2">

                                        <p style={{ fontSize: "15px" }}>if you are not interested from accepting gift</p>
                                        <div class="form-check form-switch d-flex justify-content-between border p-2 mb-4">
                                            <label class="form-check-label" for="PrivateProfile" >Please this mode Off </label>
                                            <input class="form-check-input " type="checkbox" role="switch" id="PrivateProfile" />
                                        </div>

                                        <center>
                                            <p>Your profile is not Verify <Icon color="#ED1B24" fontSize={35} icon="iconoir:twitter-verified-badge" /> <span style={{ fontSize: "10px" }}>send requiest to</span> <span className="text-primary fw-bold">Verify</span></p>
                                            <p style={{ fontSize: "13px" }}>after successful verified your profile your private profile caiteria your verify tag color is <Icon className="text-success" fontSize={25} icon="iconoir:twitter-verified-badge" /></p>
                                            <p className="text-danger">#content..........currently you are not eligible for withdrowal gift</p>
                                            <p>Review <span className="text-primary">terms of services</span>  | <span className="text-primary">monetize policy</span>  & <span className="text-primary fw-bold">Read More</span> </p>
                                        </center>


                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Monetize;
