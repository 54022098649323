import Reacts from "react";
import '../group/Group.css';
import SponseredSlider from "../../slider/SponseredSlider";
import Footer from "../../../Footer";
import grouprVideo from '../group/video/SocialMedia.mp4';

const Group = () => {
    return (
        <>
            <div className="groupVideo">
                <div className="overlay"></div>
                <video src={grouprVideo} autoPlay loop muted />
                <div className="content container">
                    <div >
                        <p>Join The Group</p><br />
                        <p><span>Shaping The Your Goal</span></p>
                    </div>

                    <div className="d-flex justify-content-between w-75 mt-5">
                        <button className="btn btn-primary col mx-4 py-2">Join group</button>
                        <button className="btn btn-primary col mx-4 py-2">Learn More</button>
                        <button className="btn btn-light shadow col mx-4 py-2">Login</button>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    )
}
export default Group;