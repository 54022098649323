import React from "react";
import MobileFrame from "../../slider/MobileFrame";
import ViewerHeading, { ViewerBtn, ViewerBtnSecond, ViewerCard, ViewerHeadingsecond, ViewerImg, ViewerPara, ViewerParaSecond } from "./ViewersSection";
import Viwer1 from "../viewers/img/Viwerimg1.png";
import Viwer2 from "../viewers/img/Viwerimg2.png";
import Viwer3 from "../viewers/img/Viwerimg3.png";
import CardGroup from 'react-bootstrap/CardGroup';
import Viwer4 from "../viewers/img/Viwerimg4.png";
import Viwer5 from "../viewers/img/Viwerimg5.png";
import Viwer6 from "../viewers/img/Viwerimg6.png";
import Viwer7 from "../viewers/img/Viwerimg7.png";


import Carousel from 'react-bootstrap/Carousel';
import Footer from "../../../Footer";

const Viewers = () => {
    return (
        <>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <ViewerHeading Heading="viewers can earn?" HeadingSecond=" on myttube" />
                        <div className="text-justify">

                            <ViewerPara Para="  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui fugit, nulla
                        atque esse
                        voluptatibus sequi, corporis quae enim ex quibusdam laboriosam. Officia labore facilis
                        veniam excepturi dicta debitis, enim ipsum voluptatem ratione! Omnis, ad labore
                        accusantium exercitationem distinctio sapiente fugit!"/>
                        </div>

                        <ViewerBtn More="See More" />
                    </div>
                    <div className="col-md-6 col-12 col-sm-12">
                        <div class="row  mt-4">
                            <div className="col-md-6" style={{ marginTop: "50px", transform: "translateY(-10%)" }} >
                                <MobileFrame />
                            </div>



                            <div className="col-md-6 d-none d-md-block" style={{ marginTop: "50px", transform: "translateX(-25%)" }} >
                                <MobileFrame />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="container">
                <ViewerHeadingsecond HeadindThird="top viwers of the months" HeadingForth="fame your social space -#content" />





                {/* ====================Test==================== */}


                <Carousel>
                    <Carousel.Item>
                        <CardGroup>
                            <ViewerCard CardImg={Viwer1} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <ViewerCard CardImg={Viwer2} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <ViewerCard CardImg={Viwer3} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <ViewerCard CardImg={Viwer1} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <ViewerCard CardImg={Viwer2} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <ViewerCard CardImg={Viwer3} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup>
                            <ViewerCard CardImg={Viwer1} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <ViewerCard CardImg={Viwer2} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                            <ViewerCard CardImg={Viwer3} CardBtn="See More" CardContent="Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography," />
                        </CardGroup>
                    </Carousel.Item>
                </Carousel>
                <div class="row mt-5 mb-5">
                    <div class="col-md-4 text-center">
                        <ViewerImg Img={Viwer4} />
                    </div>
                    <div class="col-md-4">
                        <div className="mx-2">

                            <ViewerHeadingsecond HeadindThird="fame your social #content" />
                        </div>
                        <div style={{ textAlign: "justify" }} >

                            <ViewerParaSecond ParaSecond=" Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography
                  Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography,"/>
                            <ViewerBtnSecond MoreSecond="See More" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <ViewerImg Img={Viwer5} />
                    </div>
                </div>
                <div class="row mt-5 pt-5">
                    <div class="col-md-3"></div>
                    <div class="col-md-4 text-center">

                        <ViewerImg Img={Viwer6} />
                    </div>
                    <div class="col-md-5">

                        <ViewerHeadingsecond HeadindThird="fame your social #content" />

                        <div style={{ textAlign: "justify" }} >

                            <ViewerParaSecond ParaSecond=" Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography
Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography,"/>
                            <ViewerBtnSecond MoreSecond="See More" />
                        </div>
                    </div>
                    <div class="row mt-5 pt-5 mb-5">

                        <div class="col-md-4 text-center">

                            <ViewerImg Img={Viwer7} />
                        </div>
                        <div class="col-md-6">
                            <ViewerHeadingsecond HeadindThird="fame your social #content" />
                            <div style={{ textAlign: "justify" }} >

                                <ViewerParaSecond ParaSecond=" Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography
Google Maps is a web mapping platform and consumer application offered by Google. It offers satellite imagery, aerial photography,"/>
                                <ViewerBtnSecond MoreSecond="See More" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );

}
export default Viewers;