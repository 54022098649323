import React from "react";


import QRCode from '../../assets/img/updatedQR.png'
import apple from '../../assets/img/apple.png'
import android from '../../assets/img/android.png'
import GoogleLogo from "../../assets/img/Google.png"
import QrCodeImg from "../../assets/img/qr-code.png"
import GooglePlay from "../../assets/img/GooglePlaydark.png"
import appStoreNew from "../../assets/img/appStoreNew.png"

import googlePlayNew from '../../assets/img/googlePlayNew.png'

import { NavLink } from "react-router-dom";
import Lang from "../../settingpage/lang";

const Login = () => {
    return (<>

        <form className="" style={{ marginTop: "", width: "" }}>

            <div className="d-flex">

                {/* <p className="" style={{ transform: "rotate(270deg)", marginLeft: "-350px", fontSize: "16px", fontFamily: "Imprima", marginTop: "-200px" }}>login your <span style={{ fontFamily: "Satisfy" }} className="text">myttube</span> profile</p> */}
                <p className="position-absolute" style={{ transform: "rotate(270deg)", marginLeft: "-150px", fontSize: "18px", fontFamily: "Imprima", marginTop: "60px" }}>login your <span style={{ fontFamily: "Satisfy" }} className="text">myttube</span> profile</p>

                <div>
                    <div className="mb-2 form-group opacity-75">
                        <input type="email" className="form-control login_form_input rounded border-primary"
                            placeholder="Enter Your  Username" />
                    </div>
                    <div className="form-group opacity-75">
                        <input type="password" className="form-control login_form_input rounded border-primary"
                            placeholder="Enter Your  Password" />
                        <a href="#" data-toggle="modal" data-target="#scanNowModal" className="btn btn-primary login_btn btn-lg d-grid border-bottom-0 mt-4" style={{ marginBottom: "0!important" }}>Login</a>
                    </div>
                </div>
            </div>

            <div className="login_bottom">
                <h6 class="text-right"><a href="" className="float-end txt_seconary">Forget password?</a></h6>


                <div className="py-1">
                    <p class="or "><span>Or</span></p>
                </div>

                <div class="form-group mt-3 ">
                    <button type="submit" class=" w-100 btn btn_outline_grey text-dark rounded text-start google-login_btn  btn-sm">
                        <img src={GoogleLogo} style={{ width: "30px" }} alt="google" /><span> Continue with google</span></button>
                </div>

                <div class="form-group" style={{ marginTop: "60px" }}>
                    <center>
                        {/* <img src={QrCodeImg} width="120" alt="qrcode" /> */}
                        {/* <h6><a href="" class="text-decoration-none" data-toggle="modal" data-target="#myModal">Login By QR Code</a></h6> */}

                        {/* <!-- The Modal --> */}
                        <div class="modal fade " id="myModal">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">

                                    {/* <!-- Modal Header --> */}
                                    <div class="modal-header">
                                        <div class="w-100">
                                            <img src={QrCodeImg} width="300" alt="qrcode" />
                                            <a href="" class="text-decoration-none"><h6>To use<span style={{ fontFamily: "satisfy" }}> myttube </span> on your computer</h6></a>
                                        </div>
                                    </div>

                                    {/* <!-- Modal body --> */}
                                    <div class="modal-body">
                                        <div class="w-75">
                                            <ul>
                                                <li class="text-left">OPEN<span style={{ fontFamily: "satisfys" }}> myttube</span> ON YOUR PHONE</li>
                                                <li class="text-left">TAP TO SCAN ON YOUR DEVICE</li>
                                                <li class="text-left">POINT YOUR PHONE TO THIS SCREEN TO CAPTURE THE QR CODE</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <!-- Modal footer --> */}
                                    <div class="text-center p-5">
                                        <p><span class="text-primary">Need Help ?</span> How To Scan, See This Video Links <a href="https://www.youtube.com/aquibrahmani" target="_blank" class="text-decoration-none">https://www.youtube.com/aquibrahmani</a></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* <!-- Modal End --> */}
                        <Lang />
                        {/* <p><a href="" class="fw-bolder">Create Account</a></p> */}
                        <p className="py-2" style={{ fontSize: "16px", padding: "40px 0px 20px 0px" }}>Don’t have an profile? <NavLink to="/registration"><b><span className="txt_seconary">Create Profile</span></b></NavLink></p>
                    </center>
                    <div className=" mt-4">
                        <center>
                            <h6 className="mt-3"><a class="txt_seconary font-weight-bold" href="">USE THE APP NOW</a></h6>
                        </center>
                        <div className="d-flex form_btn_playstore justify-content-around">
                            <div class="">
                                <a href="#" className="btn btn-lg p-0">
                                    <img className=""
                                        src={googlePlayNew} width="200"
                                        alt="google" />
                                </a>

                            </div>
                            <div class="">
                                <a href="" className="btn btn-lg p-0">
                                    <img className=""
                                        src={appStoreNew} width="200"
                                        alt="google" />
                                </a>
                            </div>
                        </div>
                        {/* <div class="row">
                            <div class="col-md-6 col-6 ">
                                <a href="#" className="btn btn-lg p-0">
                                    <img className="img-fluid float-end"
                                        src={googlePlayNew} width="200"
                                        alt="google" />
                                </a>

                            </div>
                            <div class="col-md-6 col-6 mx-auto">
                                <a href="" className="btn btn-lg p-0">
                                    <img className="img-fluid"
                                        src={appStoreNew} width="200"
                                        alt="google" />
                                </a>
                            </div>
                        </div> */}


                    </div>

                </div>
            </div>
        </form >



        <div class="modal fade .landing_modal" id="scanNowModal">
            <div class="modal-dialog modal-lg modal-dialog-centered ">

                <div class="modal-content" style={{}}>
                    <div className="cancel_btn">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body" style={{ width: '280px' }}>
                        <div class="">
                            <div className="scanner_div">
                                <div>
                                    <div className="text-center top_p">
                                        <p className="mt-4">Scan the code to <br />download now app</p>
                                    </div>
                                    <div >
                                        <div>
                                            <img src={QRCode} alt="" width="100%" />

                                            <div className="d-flex justify-content-between mt-4">
                                                <div><img className="img-fluid" src={apple} alt="" /></div>
                                                <div><img className="img-fluid" src={android} alt="" /></div>
                                            </div>
                                        </div>
                                        <div className="d-flex landing_btn">
                                        </div>



                                    </div>
                                    <div className="text-center bottom_p">
                                        <p>USE THE APP NOW</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </>
    );
}

export default Login;