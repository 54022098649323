import React from "react";
import { NavLink } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { SideDrop } from "../../landingpage/components/SideListSection";
import MainMenu from "../MainMenu";

import PostSection from "../../landingpage/components/PostSection"
import ImgUser from "../../landingpage/assets/img/Group 785.png";
import ImgIcon from "../../landingpage/assets/img/Group 759.png";
import ImgPost1 from "../../landingpage/assets/img/image 444.png";

import Advertise from "../../landingpage/components/AdvertiseSection";
import ImgLogo2 from "../../landingpage/assets/img/flipkart.jpg";
import ImgBrand2 from "../../landingpage/assets/img/FlipkartAds.png";

const Ads = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div class="h-100 fixed-top overflow-hidden scrollbar"
                            style={{ maxWidth: "360px", width: "100%", zIndex: "4" }}>
                            <MainMenu />
                        </div>
                    </div>
                    <div className="col-md-3 col-12">

                        <div className="sidebar1">

                            <div className="sd-header1">
                                <h4 className="mb-0">Ads</h4>
                                {/* <div className="btn btn-primary toggles-close" onClick={SettingPage}><i className="fa fa-times"></i></div> */}
                            </div>
                            <div className="sd-body">
                                <ul>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/profilepage" ><SideDrop ItemIcon="gg:profile" MenuName="Profile" /></NavLink>
                                    </li>
                                    <li>
                                        <NavLink className="sd-link " to="/Home/notification" ><SideDrop ItemIcon="ci:notification" MenuName="Notifications" /></NavLink>
                                    </li>

                                    <li>

                                        <NavLink className="sd-link " to="/Home/monetize" ><SideDrop ItemIcon="bi:graph-up-arrow" MenuName="Monetize" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/permission" ><SideDrop ItemIcon="bi:shield-lock" MenuName="Permission set" /> </NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/language"><SideDrop ItemIcon="uil:language" MenuName="language" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link " to="/Home/chatbox" ><SideDrop ItemIcon="heroicons:chat-bubble-bottom-center-text" MenuName="Chat-Box (message)" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link bg-primary" to="/Home/ads" ><SideDrop ItemIcon="mingcute:horn-fill" MenuName="Ads" IconClass="text-light" MenuClass="text-light m-0" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/verification"><SideDrop ItemIcon="octicon:verified-16" MenuName="Verification" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/privacy" ><SideDrop ItemIcon="carbon:security" MenuName="Privacy" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/security" ><SideDrop ItemIcon="mdi:security-lock-outline" MenuName="Security" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/about" ><SideDrop ItemIcon="mdi:about" MenuName="About" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/help" ><SideDrop ItemIcon="fluent:chat-help-24-regular" MenuName="Help" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/Home/switchprofile"><SideDrop ItemIcon="ep:switch" MenuName="SWITCH PROFILE" /></NavLink>
                                    </li>
                                    <li>

                                        <NavLink className="sd-link" to="/settings" ><SideDrop ItemIcon="ep:switch-button" MenuName="Logout" /></NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-5 col-12 col-lg-6 mx-auto mb-3" style={{ marginTop: "80px" }}>
                        <div class="h-100 fixed-top overflow-auto scrollbar border p-3"
                            style={{ maxWidth: "48%", width: "100%", left: "initial", marginTop: "80px", paddingRight: "10px" }}>

                            <Tabs defaultIndex={0} className="mt-2">
                                <TabList className="d-flex justify-content-between w-75 mx-auto">
                                    <Tab className="btn border-bottom-0" >Activity</Tab>
                                    <Tab className="btn border-bottom-0" >Change</Tab>
                                </TabList>

                                <TabPanel style={{ marginBottom: "80px" }}>
                                    {/* **************PostStart********* */}
                                    <PostSection UserImg={ImgUser} UserName="nyraa grover " IconImg={ImgIcon} UserId="@nyra_kameover" PostContent="Sponsered by  https://myttube.com/"
                                        PostImg={ImgPost1} LikeContent="Aquib, Phu, Tuan, and 3 others" Comment="#Timepass @followmyteam #Study#content#content#sample#more...."
                                        CommentUser1="John" CommentContent1="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg1={ImgUser}
                                        CommentUser2="Jerry" CommentContent2="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg2={ImgUser} CommentImg3={ImgUser} />
                                    {/* ******************PostEND*********** */}

                                    {/* **************PostStart********* */}
                                    <PostSection UserImg={ImgUser} UserName="Xiaomi" IconImg={ImgIcon} UserId="Sponsered" PostContent=""
                                        PostImg={ImgBrand2} LikeContent="Aquib, Phu, Tuan, and 3 others" Comment="#Timepass @followmyteam #Study#content#content#sample#more...."
                                        CommentUser1="John" CommentContent1="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg1={ImgUser}
                                        CommentUser2="Jerry" CommentContent2="Lorem ipsum dolor sit amet, consectetur adipiscing elit." CommentImg2={ImgUser} CommentImg3={ImgUser} />
                                    {/* ******************PostEND*********** */}

                                </TabPanel>
                                <TabPanel>
                                    <p>Choose Your Interest <span style={{ fontSize: "13px" }}>(minimum 5 Options)</span></p>
                                    <center>
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Enternainment</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-outline-primary w-75">Technologies</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Education</button>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Finance</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Music</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Hollywood</button>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Foods</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Events</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Gov. & Law</button>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Health</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Fashion</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Business</button>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Beauty</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Lifestyle</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">Bollywood</button>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Sports</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Science</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">News</button>
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Tourism</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg btn-primary w-75">International</button>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-12">
                                                <button className="btn btn-lg  btn-outline-primary w-75">Enternainment</button>
                                            </div>
                                        </div>
                                    </center>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Ads;