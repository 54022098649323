import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PopupImg from "../../assets/img/event-popup.jpg";

const Popup = () => {

    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>


            <Modal size='xl'  show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div class="mx-auto">
                        <img class="img-fluid" src={PopupImg} alt="Alternate Text" />
                    </div>
                </Modal.Body>
             
            </Modal>
        </>
    );
}

export default Popup;